import React, { useCallback, useMemo, useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
// import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ListIcon from '@material-ui/icons/List';
import {
  Box,
  Card,
  Table,
  // TableBody,
  makeStyles,
  colors,
  Container,
  Button,
  Typography
} from '@material-ui/core';
// import { Sale } from 'src/types';
import { SalesColumnsFilterModal } from 'src/components/modal/sales';
// import { formatCurrency, formatDate } from 'src/utils';
import { usePermissions } from 'src/hooks';
import NotFoundView from 'src/views/errors/NotFoundView';
// import { ProductTypeEnum } from 'src/enums';
import SalesToolbar from './components/SalesToolbar';
import Toolbar from './components/Toolbar';
import { SalesTableHeader } from './components';
import { SalesTableBody } from './components/SalesTableBody';
import { Alert } from '@material-ui/lab';
import { GetSalesResponse, PaginationMeta } from 'src/types';
import {
  downloadCSV,
  salesAmountsToString,
  salesItemToString,
  salesPaymentToString
} from 'src/utils';
import { cloneDeep } from 'lodash';

const { actions: salesActions, selectors: salesSelectors } = slices.sales;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  salesCard: {
    height: '100%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  },
  columnBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    display: 'flex'
  },
  toCsvText: {
    color: theme.palette.primary.main
  },
  toCsvBtn: {
    marginLeft: theme.spacing(2)
  }
}));

const SalesList = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const sales = useAppSelector(salesSelectors.selectAllSales);
  const salesColumns = useAppSelector(salesSelectors.selectSalesColumns);

  const salesDataForCsv = useMemo(() => {
    const clonedSales = cloneDeep(sales);
    const salesData = clonedSales?.map((sale) => ({
      ...sale,
      items: salesItemToString(sale?.items)?.toString(),
      payments: salesPaymentToString(sale?.payments)
    }));

    const salesResult = salesData
      .map((sales) => {
        return {
          date_sold: salesColumns?.date_sold ? sales?.date_sold : undefined,
          date_purchased: salesColumns?.date_purchased
            ? sales?.date_purchased
            : undefined,
          branch: salesColumns?.branch ? sales?.branch : undefined,
          customer_name: salesColumns?.customer_name
            ? sales?.customer_name
            : undefined,
          order_no: salesColumns?.order_no ? sales?.order_no : undefined,
          transaction_no: salesColumns?.transaction_no
            ? sales?.transaction_no
            : undefined,
          warranty_slip_no: salesColumns?.warranty_slip_no
            ? sales?.warranty_slip_no
            : undefined,
          acknowledgement_receipt_no: salesColumns?.acknowledgement_receipt_no
            ? sales?.acknowledgement_receipt_no
            : undefined,
          items: salesColumns?.products ? sales?.items : undefined,
          payments: salesColumns?.payments ? sales?.payments : undefined,
          amount: salesColumns?.amounts
            ? salesAmountsToString({
                total_retail_price: sales?.total_retail_price,
                total_dealer_price: sales?.total_dealers_price,
                amount_paid: sales?.amount_paid,
                balance: sales?.balance,
                gross: sales?.total_gross
              })
            : undefined
        };
      })
      .map((item) => {
        const filteredItem = Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(item).filter(([_, value]) => value !== undefined)
        );

        return filteredItem;
      });

    return salesResult;
  }, [
    sales,
    salesColumns.acknowledgement_receipt_no,
    salesColumns.amounts,
    salesColumns.branch,
    salesColumns.customer_name,
    salesColumns.date_purchased,
    salesColumns.date_sold,
    salesColumns.order_no,
    salesColumns.payments,
    salesColumns.products,
    salesColumns.transaction_no,
    salesColumns.warranty_slip_no
  ]);

  const isNextPageLoading = useAppSelector(
    salesSelectors.selectSalesLoadingNextPage
  );
  const permissions = usePermissions();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isComputeLoading, setIsComputeLoading] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);

  const getSalesNextPages = useCallback(
    async (response: GetSalesResponse) => {
      dispatch(salesActions.getSalesNextPageThunk()).then(
        async (resNextPage: any) => {
          const resNxtPgeMeta: PaginationMeta =
            resNextPage?.payload?.originalData?.meta;
          const currentPage = resNxtPgeMeta?.current_page || 1;
          const lastPage = resNxtPgeMeta?.last_page || 1;

          if (!resNxtPgeMeta) {
            return;
          }
          if (currentPage >= lastPage) {
            return;
          }
          getSalesNextPages(response);
        }
      );
    },
    [dispatch]
  );

  const getSales = async () => {
    setIsComputeLoading(true);

    dispatch(salesActions.getSalesComputationThunk()).finally(() =>
      setIsComputeLoading(false)
    );
    //if therre is new filter request empty sales list
    dispatch(salesActions.resetSalesDataOnApplyFilter());
    setShowList(true);
  };

  const getSalesList = useCallback(async () => {
    setIsLoading(true);
    await dispatch(salesActions.getSalesThunk())
      .then((res: any) => getSalesNextPages(res?.payload?.originalData))
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, getSalesNextPages]);

  const onShowList = useCallback(() => {
    getSalesList();
  }, [getSalesList]);

  return permissions?.canViewSales ? (
    <Page className={classes.root} title="Sales">
      <Container maxWidth={false}>
        <Toolbar onApplyFilterPress={getSales} />
        {sales ? <SalesToolbar isLoading={isComputeLoading} /> : null}
        <div className={classes.columnBtn}>
          {showList && (
            <Button
              variant="outlined"
              endIcon={<ListIcon />}
              onClick={onShowList}
              color="primary"
            >
              Show List
            </Button>
          )}
          <Box>
            <Button
              variant="outlined"
              endIcon={<ViewWeekIcon />}
              onClick={() => setFilterVisible(!filterVisible)}
              color="primary"
            >
              Columns
            </Button>
            {sales?.length ? (
              <Button
                className={classes.toCsvBtn}
                variant="outlined"
                endIcon={<GetAppIcon />}
                color="primary"
                onClick={() => downloadCSV(salesDataForCsv, 'sales')}
              >
                Export to CSV
              </Button>
            ) : null}
          </Box>
        </div>
        <Alert severity="info">
          <Typography variant="subtitle2">
            DP - Dealers Price | TDP - Total Dealers Price | RP - Retail Price |
            TRP - Total Retail Price
          </Typography>
          <Typography variant="subtitle2">
            Amount Paid - Total of payments | Balance - Need payments
          </Typography>
          <Typography variant="subtitle2">Gross = TRP - TDP</Typography>
        </Alert>
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Card>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table size="small">
                  <SalesTableHeader onColumnFilterClick={() => {}} />
                  <SalesTableBody />
                </Table>
                <LoaderBar isLoading={isNextPageLoading} />
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
      <SalesColumnsFilterModal
        visible={filterVisible}
        onHandleClose={() => setFilterVisible(false)}
      />
    </Page>
  ) : (
    <NotFoundView />
  );
};

export default SalesList;
