import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { DecisionDialog, Page } from 'src/components';
import {
  CommonAxiosResponse,
  Customer,
  CustomInputEvent,
  EcomOrderDetails,
  EcomOrderProduct,
  EcomOrderStatusType,
  UpdateEcomOrderResponse
} from 'src/types';
import { useAlertGlobal, usePermissions, useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import {
  CustomerDetails,
  DeliveryInfo,
  EcomOrderConcerns,
  EcomOrderDetailsToolbar,
  EcomTotalAmount,
  ProductsOrderTable,
  RemarksAndStatusSection
} from './components';
import usePrompt from 'src/utils/navigation-prompt';
import { isEqual } from 'lodash';
import {
  ecomProductsToQuotationProducts,
  orderDetailsToUpdateTransformer
} from 'src/utils';
import { Alert } from '@material-ui/lab';
import { PaymentSuccessLinkDialog } from './components/PaymentSuccessLinkDialog';

const { actions: ecomOrderActions } = slices.ecomOrder;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  products: {
    marginTop: theme.spacing(2)
  },
  updateBtn: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end'
  }
}));

const component = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const alertGlobal = useAlertGlobal();
  const { orderRefNo } = useParams();
  const { permissionChecker } = usePermissions();

  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);

  const [orderDetails, setOrderDetails] = useState<EcomOrderDetails>();
  const [origOrderDetails, setOrigOrderDetails] = useState<EcomOrderDetails>();

  const [updateResponse, setUpdateResponse] = useState<
    CommonAxiosResponse<UpdateEcomOrderResponse> | undefined
  >();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const canEditEcomOrder = permissionChecker('can_edit_ecom_order');

  const [isShippingFeePaid, setIsShippingFeePaid] = useState<boolean>(
    orderDetails?.shipping_fee_paid || false
  );

  const getOrderDetails = useCallback(async () => {
    if (orderRefNo) {
      const response = unwrapResult(
        await dispatch(ecomOrderActions.ecomOrderGetDetailsThunk(orderRefNo))
      );
      if (response?.success && response?.originalData?.data) {
        setOrderDetails(response?.originalData?.data);
        setOrigOrderDetails(response?.originalData?.data);
        setIsShippingFeePaid(
          response?.originalData?.data?.shipping_fee_paid || false
        );
      }
    }
  }, [dispatch, orderRefNo]);

  const onUpdateDetails = useCallback(
    async (newOrderDetails?: EcomOrderDetails) => {
      if (orderRefNo && orderDetails) {
        setIsOrderLoading(true);
        // if newOrderDetails in params exist it will be used.. else orderDetails in state will be used.
        let toBeUsedOrderDetails = newOrderDetails || orderDetails;
        if (!canEditEcomOrder) {
          // Even if there is no permission to edit ecom some fields can still be edited like status and Internal Remarks
          toBeUsedOrderDetails = {
            ...origOrderDetails,
            internal_remarks: toBeUsedOrderDetails?.internal_remarks,
            status: toBeUsedOrderDetails?.status
          };
        }

        const response = unwrapResult(
          await dispatch(
            ecomOrderActions.ecomOrderUpdateThunk({
              order_ref_no: orderRefNo,
              data: orderDetailsToUpdateTransformer(toBeUsedOrderDetails)
            })
          ).finally(() => setIsOrderLoading(false))
        );
        if (response?.success) {
          snackBar.show({
            severity: 'success',
            message: response?.message || 'Update success'
          });
          getOrderDetails();

          if (
            origOrderDetails?.status !== 'paid' &&
            toBeUsedOrderDetails?.status === 'paid' &&
            toBeUsedOrderDetails?.mode_of_payment !== 'COD'
          ) {
            // Only show payment success link dialog if status is changed to paid
            setUpdateResponse(response);
          }
        } else if (response?.errors) {
          snackBar.show({
            severity: 'error',
            message: `${JSON.stringify(response?.errors)}`
          });
        }
      }
    },
    [
      canEditEcomOrder,
      dispatch,
      getOrderDetails,
      orderDetails,
      orderRefNo,
      origOrderDetails,
      snackBar
    ]
  );

  const customerDetails: Customer = useMemo(() => {
    const customer: Customer = {
      id: orderDetails?.customer_id, // TODO:
      first_name: orderDetails?.first_name,
      middle_initial: orderDetails?.middle_initial,
      last_name: orderDetails?.last_name,
      email: orderDetails?.email,
      contact_no: orderDetails?.contact_no,
      is_ecomm_user: orderDetails?.is_ecomm_user
    };
    return customer;
  }, [orderDetails]);

  const onOrderDetailChange = (event: CustomInputEvent) => {
    const { value, name } = event.target;
    setOrderDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onShippingFeePadChhange = (is_sf_paid: boolean) => {
    snackBar.show({
      severity: 'info',
      message:
        'Shipping fee paid updated, please update order details to apply changes'
    });
    setIsShippingFeePaid(is_sf_paid);
    setOrderDetails((prev) => ({
      ...prev,
      shipping_fee_paid_at: is_sf_paid
    }));
  };

  const onHandleRemarksChange = (
    targetName: 'customer_notes' | 'internal_remarks',
    str: string
  ) => {
    setOrderDetails((prev) => ({ ...prev, [targetName]: str }));
  };

  const confirmCancellation = () => {
    setOrderDetails((prev) => ({ ...prev, status: 'cancelled' }));
  };

  const onHandleStatusChange = (newStatus: EcomOrderStatusType) => {
    if (newStatus === 'served' || newStatus === 'approved') {
      snackBar.show({
        severity: 'error',
        message: `'served' and 'approved' is deprecated status. Please choose another :(`
      });
      return;
    }
    if (newStatus === 'cancelled') {
      setIsConfirmOpen(true);
      return; // intended cut off return confirm action will setOrderDetails
    }
    setOrderDetails((prev) => ({ ...prev, status: newStatus }));
  };

  const onUpdateProduct = (newProducts: EcomOrderProduct[]) => {
    const newOrderDetails: EcomOrderDetails = {
      ...orderDetails,
      products: newProducts
    };
    setOrderDetails((prev) => ({ ...prev, products: newProducts }));
    onUpdateDetails(newOrderDetails);
  };

  const productsToQuotation = useMemo(
    () => ecomProductsToQuotationProducts(orderDetails?.products || []),
    [orderDetails]
  );

  const onCreateQuotation = () => {
    if (orderDetails?.total_amount_fluctuation !== 'none') {
      alertGlobal.show({
        title: 'Total Amount Fluctuation',
        subtitle:
          'There is a fluctuation in price. Please let the customer know. Are you sure you want to proceed to quotation?',
        buttons: [
          {
            text: 'Yes',
            onClick: () => {
              alertGlobal.hide();
              navigate(`/app/quotation`, { state: productsToQuotation });
            },
            color: 'secondary'
          },
          {
            text: 'Cancel',
            onClick: () => alertGlobal.hide()
          }
        ]
      });
    } else {
      navigate(`/app/quotation`, { state: productsToQuotation });
    }
  };

  const onCreatePaymentSuccessLink = () => {
    if (
      origOrderDetails?.status === 'paid' &&
      origOrderDetails?.successful_payment_url
    ) {
      // If originally on paid status. Just show dialog link
      // Just generate a fake payload that looks like we updated the order detail response to show dialog .
      setUpdateResponse({
        success: true,
        message: 'Payment success link generated',
        originalData: {
          success: true,
          message: 'Payment success link generated',
          data: {
            successful_payment_url: origOrderDetails?.successful_payment_url
          }
        }
      });
      return;
    }

    const updatedOrderDetailsPayload: EcomOrderDetails = {
      ...orderDetails,
      status: 'paid'
    };

    onUpdateDetails(updatedOrderDetailsPayload);
  };

  useEffect(() => {
    getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePrompt(
    'There are some unsaved changes. Are you sure you want to leave?',
    !isEqual(origOrderDetails, orderDetails)
  );

  return (
    <Page className={classes.root} title="Ecom Orders">
      <EcomOrderDetailsToolbar
        updateDisabled={isEqual(origOrderDetails, orderDetails)}
        orderRefNo={orderRefNo}
        onUpdateDetails={() => onUpdateDetails()}
      />
      {!canEditEcomOrder ? (
        <Alert className={classes.alert} severity="info">
          You are not allowed to edit e-commerce orders
        </Alert>
      ) : null}
      {orderDetails?.concerns && orderDetails?.concerns?.length > 0 ? (
        <EcomOrderConcerns concerns={orderDetails?.concerns} />
      ) : null}
      <CustomerDetails
        customer={customerDetails}
        transactionNo={orderDetails?.transaction_no}
        onCustomerDetailChange={onOrderDetailChange}
      />
      <DeliveryInfo
        isShippingPaid={isShippingFeePaid}
        orderDetails={orderDetails}
        onDeliveryInfoDetailChange={onOrderDetailChange}
        onHandleShippingFeePaid={onShippingFeePadChhange}
      />
      <RemarksAndStatusSection
        orderDetails={orderDetails}
        orderStatus={orderDetails?.status}
        customerRemarks={orderDetails?.customer_notes}
        internalRemarks={orderDetails?.internal_remarks}
        onHandleRemarksChange={onHandleRemarksChange}
        onHandleStatusChange={onHandleStatusChange}
        onGeneratePaymentSuccessLink={onCreatePaymentSuccessLink}
      />
      <ProductsOrderTable
        isOrderLoading={isOrderLoading}
        onUpdateProduct={onUpdateProduct}
        onCreateQuotation={onCreateQuotation}
        products={orderDetails?.products || []}
      />
      <EcomTotalAmount isLoading={isOrderLoading} orderDetails={orderDetails} />

      <div className={classes.updateBtn}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onUpdateDetails()}
          disabled={isEqual(origOrderDetails, orderDetails)}
        >
          Update Order Details
        </Button>
      </div>

      <PaymentSuccessLinkDialog
        isOpen={
          updateResponse?.originalData?.data?.successful_payment_url !==
          undefined
        }
        paymentLink={updateResponse?.originalData?.data?.successful_payment_url}
        handleClose={() => {
          setUpdateResponse(undefined);
        }}
      />
      {/* For confirming order cancellation */}
      <DecisionDialog
        isOpen={isConfirmOpen}
        title="Confirm Cancellation?"
        subTitle="Changing ecomm order status to CANCELLED will VOID all the items in this transaction upon saving"
        onHandleClose={() => setIsConfirmOpen(false)}
        onHandleConfirmAction={confirmCancellation}
      />
    </Page>
  );
};

export const EcomOrderDetailsView = React.memo(component);
