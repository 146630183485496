import { SupabaseClientService } from 'src/services';
import {
  GetTapTapCustomersPayload,
  TapTapColumn,
  TapTapCustomerData,
  TapTapCustomerPayment,
  TapTapFiltersV2,
  TapTapGetCustomersCondition,
  TapTapImageFieldJson
} from './types';
import { getRandomUUIDv4Short } from 'src/utils';
import {
  CreateTransactionPaymentRequest,
  GetTransactionsViaTransactionNoResponse,
  TransactionPayment
} from 'src/types';
import { quickFilterViewsChoices } from './constants';
import { cloneDeep, every, isEqual, some } from 'lodash';
import { taptapGetCustomerDetailsService } from './services';
import { taptapCustomerField } from './constants/taptap-fields';

export const taptapUploadMultipleImages = async (
  path: string,
  images: TapTapImageFieldJson[]
) => {
  let uploadedImgs: TapTapImageFieldJson[] = [];
  const userId = (await SupabaseClientService?.auth?.getUser()).data.user?.id;

  try {
    for (const image of images) {
      if (image.file) {
        const randomFileName = `${getRandomUUIDv4Short()}-${Date.now()}`;
        console.log(image.file);
        const { data, error } = await SupabaseClientService.storage
          .from('taptap-images')
          .upload(`${path}${randomFileName}`, image.file); // TODO: Change the path based on field key

        if (error) {
          console.error(`Error uploading image for field ${path}:`, error);
          throw new Error(`Failed to upload images for field ${path}.`);
        }

        // IMPORTANT
        image.file = undefined; // Remove the file object

        // Get the public URL
        const imgUrlResp = SupabaseClientService.storage
          .from('taptap-images')
          .getPublicUrl(`${path}${randomFileName}`);

        uploadedImgs.push({
          ...image,
          img_url: imgUrlResp.data.publicUrl,
          created_by: userId,
          created_at: new Date().toISOString()
        });

        console.log(`Image uploaded successfully for field ${path}:`, data);
      } else {
        uploadedImgs.push(image); // Already uploaded images remain unchanged
      }
    }

    console.log('All images uploaded successfully:', uploadedImgs);
    return uploadedImgs;
  } catch (err) {
    console.error('Upload process terminated:', err);
    return null; // Or handle the error as needed
  }
};

export const onTapTapCustomerTransactionInfoChangeViaInternal = async (
  transactionInfo: GetTransactionsViaTransactionNoResponse
) => {
  const { data: customerData, error } = await SupabaseClientService.from(
    'customers'
  )
    .update({
      total_amount: transactionInfo?.sub_total_price,
      // Syempre pag 0 na yung total price, dapat null na yung internal transaction no
      internal_transaction_no:
        transactionInfo?.sub_total_price === 0
          ? null
          : transactionInfo?.transaction_no
    })
    .filter('is_deleted', 'eq', false)
    .filter('internal_transaction_no', 'eq', transactionInfo?.transaction_no)
    .select('id');

  if (error) {
    console.error(`Error updating customer transaction info:`, error);
    return;
  }

  if (
    !transactionInfo?.sub_total_price ||
    transactionInfo?.sub_total_price === 0
  ) {
    const dataForDeletion = {
      deleted_at: new Date().toISOString(),
      deleted_by: 0,
      is_deleted: true
    };

    const { error: errCustomerPayments } = await SupabaseClientService.from(
      'customers_payments'
    )
      .update(dataForDeletion)
      .filter('is_deleted', 'eq', false)
      .filter('internal_transaction_no', 'eq', transactionInfo?.transaction_no);

    if (errCustomerPayments) {
      console.error(`Error removing customer payment:`, error);
    }
  }

  if (customerData?.[0]?.id) {
    const customerId = customerData?.[0]?.id;
    onUpdateTapTapBalanceAndPaidAmount(customerId);
  }
  console.log('customerData', customerData);
  // onUpdateTapTapBalanceAndPaidAmount();
};

export const onTapTapCustomerPaymentDeleteViaInternal = async ({
  paymentToBeDeleted,
  transactionNo,
  userDetailsId
}: {
  paymentToBeDeleted?: TransactionPayment;
  transactionNo?: string;
  userDetailsId?: number;
}) => {
  const dataForDeletion = {
    deleted_at: new Date().toISOString(),
    deleted_by: userDetailsId,
    is_deleted: true
  };

  const { data, error } = await SupabaseClientService.from('customers_payments')
    .update(dataForDeletion)
    .filter('is_deleted', 'eq', false)
    .filter('internal_transaction_no', 'eq', transactionNo)
    .filter('payment_type', 'eq', paymentToBeDeleted?.payment_type)
    .filter('amount', 'eq', paymentToBeDeleted?.amount)
    .select('customer_id');

  if (error) {
    console.error(`Error adding customer payment:`, error);
  } else {
    if (data?.[0]?.customer_id) {
      onUpdateTapTapBalanceAndPaidAmount(data?.[0]?.customer_id);
    }
  }
};

export const onTapTapCustomerPaymentAddedViaInternal = async (
  dataArg: CreateTransactionPaymentRequest
) => {
  const {
    data: customerData,
    error: customerError
  } = await SupabaseClientService.from('customers')
    .select('id')
    .eq('internal_transaction_no', dataArg?.transaction_no)
    .filter('is_deleted', 'eq', false)
    .single();

  if (customerError) {
    console.error(`customerError`, customerError);
  }

  const newCustomerPaymentData: TapTapCustomerPayment = {
    amount: dataArg?.amount,
    payment_type: dataArg?.payment_type,
    customer_id: customerData?.id,
    internal_transaction_no: dataArg?.transaction_no,
    acknowledgement_receipt_no: dataArg?.acknowledgement_receipt_no
  };

  const { error } = await SupabaseClientService.from(
    'customers_payments'
  ).insert({ ...newCustomerPaymentData });

  if (error) {
    console.error(`Error adding customer payment:`, error);
  } else {
    onUpdateTapTapBalanceAndPaidAmount(customerData?.id);
  }
};

export const onTapTapCustomerPaymentAddFromTransactionPayments = async (
  dataArg: TransactionPayment[],
  otherArgs: { transaction_no: string; taptap_customer_id: number }
) => {
  const transformedData = transactionPaymentsToTapTapCustomerPaymentPayloadTransformer(
    dataArg,
    otherArgs
  );
  const { data, error } = await SupabaseClientService.from('customers_payments')
    .insert(transformedData)
    .select('customer_id');

  if (error) {
    console.error(`Error adding customer payment:`, error);
  }
  if (data?.[0]?.customer_id) {
    onUpdateTapTapBalanceAndPaidAmount(data?.[0]?.customer_id);
  }
};

export const transactionPaymentsToTapTapCustomerPaymentPayloadTransformer = (
  dataArg: TransactionPayment[] = [],
  otherArgs?: { transaction_no: string; taptap_customer_id: number }
) => {
  const transformedData: TapTapCustomerPayment[] = dataArg
    ?.filter((x) => !x?.deleted_by)
    ?.map((payment) => ({
      amount: payment?.amount,
      payment_type: payment?.payment_type,
      customer_id: otherArgs?.taptap_customer_id,
      internal_transaction_no: otherArgs?.transaction_no,
      acknowledgement_receipt_no: payment?.acknowledgement_receipt_no
    }));

  return transformedData;
};

export const findSelectedQuickFilterViaParam = (
  filterParam?: TapTapFiltersV2
) => {
  const selectedQuickFilter = quickFilterViewsChoices?.find(
    (quickFilterViewParam) => {
      const allConditionsExist = every(
        quickFilterViewParam?.filters?.conditions,
        (firstCondition) =>
          some(filterParam?.conditions, (secondCondition) =>
            isEqual(firstCondition, secondCondition)
          )
      );

      return allConditionsExist;
    }
  );
  return selectedQuickFilter || null;
};

export const updateColumnVisibilityUtil = (
  columns: TapTapColumn[] = [],
  defaultColumns: string[]
) => {
  const clonedColumns = cloneDeep(columns);

  const transformedColumns = clonedColumns.map((column) => ({
    ...column,
    visible: defaultColumns.includes(column.field)
  }));

  transformedColumns.sort((a, b) => {
    const indexA = defaultColumns.indexOf(a.field);
    const indexB = defaultColumns.indexOf(b.field);
    return (
      (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
    );
  });

  return transformedColumns;
};

/**
 * A util that is called whenever we update the `balance` of a customer
 */
export const onUpdateTapTapBalanceAndPaidAmount = async (
  customer_id?: number
) => {
  if (!customer_id) {
    return;
  }
  const { data, error } = await taptapGetCustomerDetailsService(customer_id);

  if (error) {
    console.error('Error fetching payments:', error);
    return;
  }

  if (data && data?.[0]) {
    const customerData = data[0];
    const customerPayments: TapTapCustomerPayment[] =
      customerData?.customers_payments || [];

    // Calculate total payments
    const paidAmount = (customerPayments || []).reduce(
      (sum, payment) => sum + (payment.amount || 0),
      0
    );

    // Calculate balance
    const balance = (customerData?.total_amount || 0) - paidAmount;

    const { error: updateError } = await SupabaseClientService.from('customers')
      .update({
        [taptapCustomerField.PAID_AMOUNT]: paidAmount,
        [taptapCustomerField.BALANCE]: balance
      })
      .eq('id', customer_id);

    if (updateError) {
      console.error('Error updating balance and paid_amount', error);
      return;
    }
  }
};

// Helper function to evaluate individual conditions
export const evaluateConditionLocally = (
  data: TapTapCustomerData,
  condition: TapTapGetCustomersCondition
) => {
  const { field, operator, value } = condition;

  switch (operator) {
    case 'eq': // Equal
      return data[field] === value;
    case 'neq': // Not equal
      return data[field] !== value;
    case 'lt': // Less than
      return data[field] < value;
    case 'lte': // Less than or equal
      return data[field] <= value;
    case 'gt': // Greater than
      return data[field] > value;
    case 'gte': // Greater than or equal
      return data[field] >= value;
    case 'in': // In array
      return Array.isArray(value) && value.includes(data[field]);
    case 'is': // Is null or not null
      return (
        (value === null && data[field] === null) ||
        (value !== null && data[field] !== null)
      );
    case 'like': // Pattern matching
      return (
        typeof data[field] === 'string' &&
        new RegExp(value.replace('%', '.*')).test(data[field])
      );
    case 'ilike': // Case-insensitive pattern matching
      return (
        typeof data[field] === 'string' &&
        new RegExp(value.replace('%', '.*'), 'i').test(data[field])
      );
    default:
      console.error(`Unsupported operator: ${operator}`);
      return false;
  }
};

/**
 *
 * Checks if the new customer data is valid for insertion because sometimes the inserted data is not following local filter
 *
 * @param newCustomerData
 * @param localFilter
 * @returns boolean
 */
export const isInsertNewCustomerDataIsValid = (
  newCustomerData: TapTapCustomerData,
  localFilterArg: GetTapTapCustomersPayload
) => {
  const localFilter = cloneDeep(localFilterArg);

  // 1. Check keywords
  if (localFilter?.search) {
    const propertiesToCheck = [
      taptapCustomerField.FIRST_NAME,
      taptapCustomerField.LAST_NAME,
      taptapCustomerField.INTERNAL_TRANSACTION_NO
    ];
    const searchKeywordsLowerCase = localFilter?.search?.trim()?.toLowerCase();

    // If may search keyword na, tapos hindi naman siya nasa propertiesToCheck, then return false
    const isSearchKeywordFound = propertiesToCheck.some((property) =>
      newCustomerData[property]
        ?.toLowerCase()
        ?.includes(searchKeywordsLowerCase)
    );

    if (!isSearchKeywordFound) {
      return false;
    }
  }

  // 2. Check branch
  const localFilterSelectedBranch = localFilter?.filters?.conditions?.find(
    (x) => x.field === taptapCustomerField.BRANCH_RELEASED
  )?.value;

  if (
    localFilterSelectedBranch &&
    localFilterSelectedBranch?.length > 0 &&
    newCustomerData.branch_released
  ) {
    const isBranchReleasedValid = localFilterSelectedBranch?.includes(
      newCustomerData.branch_released
    );

    if (!isBranchReleasedValid) {
      return false;
    }

    // remove branch released from conditions kasi I think makakagulo siya sa conditions na "or" and "and"
    localFilter.filters.conditions = localFilter.filters.conditions.filter(
      (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
    );
  }

  // 3. Check if "or" or "and" logic
  const isValidViaLocalFilters =
    localFilter.filters.logic === 'and'
      ? localFilter.filters.conditions.every((condition) =>
          evaluateConditionLocally(newCustomerData, condition)
        )
      : localFilter.filters.conditions.some((condition) =>
          evaluateConditionLocally(newCustomerData, condition)
        );

  if (!isValidViaLocalFilters) {
    return false;
  }

  // Nice it passed the local test. Push through!
  return true;
};
