import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isArray } from 'lodash';
import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

interface Props {
  onHandleCategories: (categoryIds: number | number[]) => void; // Update to handle multiple categories
  isMultiple?: boolean; // Allow support for multiple selections
}

const Component: FC<Props> = ({ onHandleCategories, isMultiple = false }) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(categorySelectors.selectCategories);

  const [selectedCategories, setSelectedCategories] = useState<any[] | null>(
    isMultiple ? [] : null
  );

  const getCategories = useCallback(async () => {
    try {
      await dispatch(categoryActions.getCategoriesThunk({}));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const onHandleSelectedChangeCategory = (
    event: ChangeEvent<{}>,
    value: any[] | any | null
  ) => {
    setSelectedCategories(value);
    if (isArray(value)) {
      const categoryIds = value ? value.map((item: any) => item.id) : [];
      onHandleCategories(categoryIds); // Handle multiple or single category IDs
    }
    onHandleCategories(value?.id);
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <Autocomplete
      style={{ position: 'absolute', background: 'white' }}
      limitTags={1}
      id="category-list-dropdown"
      options={categories}
      fullWidth
      multiple={isMultiple} // Support for multiple selections
      value={selectedCategories}
      getOptionLabel={(option) => option.name || ''}
      onChange={onHandleSelectedChangeCategory}
      renderOption={(option, { selected }) =>
        isMultiple ? (
          <>
            <Checkbox checked={selected} style={{ marginRight: 8 }} />
            {option.name}
          </>
        ) : (
          <>{option.name}</>
        )
      }
      renderInput={(params) => (
        <TextField {...params} label="Categories" variant="outlined" />
      )}
    />
  );
};

export const CategoryListDropdown = memo(Component);
