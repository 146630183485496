//NOTE: you need to declare the parent component position: relative

import React, { FC, memo, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Checkbox } from '@material-ui/core';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';

const { actions: manufacturerActions } = slices.manufacturer;

interface Props {
  label?: string;
  isMultiple?: boolean;
  onSelectionChange?: (selected: OptionType[] | OptionType | null) => void;
}

type OptionType = {
  id?: number;
  name?: string;
};

const Component: FC<Props> = ({
  label = 'Manufacturers',
  isMultiple = false,
  onSelectionChange
}) => {
  const dispatch = useAppDispatch();

  const [selectedOptions, setSelectedOptions] = useState<
    OptionType[] | OptionType | null
  >(isMultiple ? [] : null);
  const [manufacturerOptions, setManufacturerOptions] = useState<OptionType[]>(
    []
  );

  const handleSelectionChange = (
    event: React.ChangeEvent<{}>,
    newValue: OptionType[] | OptionType | null
  ) => {
    setSelectedOptions(newValue);
    if (onSelectionChange) {
      onSelectionChange(newValue); // Pass the selected options to the parent component
    }
  };

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const getManufacturersResponse = unwrapResult(
          await dispatch(manufacturerActions.getManufacturersThunk({}))
        );

        const manufacturers =
          getManufacturersResponse?.originalData?.manufacturers;

        const options: OptionType[] = manufacturers?.map((item: any) => ({
          id: item?.id,
          name: item?.name
        }));

        setManufacturerOptions(options || []);
      } catch (error) {
        console.error('Failed to fetch manufacturers:', error);
      }
    };

    fetchManufacturers();
  }, [dispatch]);

  return (
    <Autocomplete
      fullWidth
      style={{
        position: 'absolute',
        background: 'white'
      }}
      limitTags={1}
      multiple={isMultiple}
      options={manufacturerOptions}
      getOptionLabel={(option: OptionType) => option?.name || ''}
      value={selectedOptions}
      onChange={handleSelectionChange}
      renderOption={(option, { selected }) =>
        isMultiple ? (
          <>
            <Checkbox checked={selected} style={{ marginRight: 8 }} />
            {option.name}
          </>
        ) : (
          <>{option.name}</>
        )
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
};

export const ManufacturerDropdownList = memo(Component);
