export const taptapCouriers = {
    PICK_UP: 'PICK UP',
    JNT: 'JNT',
    TECHOUT: 'TECHOUT',
    LALAMOVE: 'LALAMOVE',
    COD_TECHOUT: 'COD-TECHOUT'
  };
  
  export const taptapOptionsCouriers = [
    {
      key: 'pick_up',
      label: taptapCouriers.PICK_UP,
      value: taptapCouriers.PICK_UP
    },
    { key: 'jnt', label: taptapCouriers.JNT, value: taptapCouriers.JNT },
    {
      key: 'techout',
      label: taptapCouriers.TECHOUT,
      value: taptapCouriers.TECHOUT
    },
    {
      key: 'lalamove',
      label: taptapCouriers.LALAMOVE,
      value: taptapCouriers.LALAMOVE
    },
    {
      key: 'cod_techout',
      label: taptapCouriers.COD_TECHOUT,
      value: taptapCouriers.COD_TECHOUT
    }
  ];