export const taptapServiceType = {
  PARTS_OUT: 'PARTS OUT',
  FOR_BUILD: 'FOR BUILD',
  LAPTOP: 'LAPTOP',
  ADDITIONAL_PURCHASE: 'ADDITIONAL PURCHASE',
  SALES_TRANSFER: 'SALES TRANSFER'
};

export const taptapOptionsServiceType = [
  {
    key: 'parts_out',
    label: taptapServiceType.PARTS_OUT,
    value: taptapServiceType.PARTS_OUT
  },
  {
    key: 'for_build',
    label: taptapServiceType.FOR_BUILD,
    value: taptapServiceType.FOR_BUILD
  },
  {
    key: 'laptop',
    label: taptapServiceType.LAPTOP,
    value: taptapServiceType.LAPTOP
  },
  {
    key: 'additional_purchase',
    label: taptapServiceType.ADDITIONAL_PURCHASE,
    value: taptapServiceType.ADDITIONAL_PURCHASE
  },
  {
    key: 'sales_transfer',
    label: taptapServiceType.SALES_TRANSFER,
    value: taptapServiceType.SALES_TRANSFER
  }
];
