import { Box, Container, Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  useFormPasscodeDialog,
  usePermissions,
  useRoles,
  useSnackBar
} from 'src/hooks';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  GetTransactionsViaTransactionNoResponse,
  Passcode,
  Transaction
} from 'src/types';
import * as Yup from 'yup';
import { LoaderBar } from '../LoaderBar';
import { unwrapResult } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { onTapTapCustomerTransactionInfoChangeViaInternal } from 'src/redux/slices/taptap-customer';

const { selectors } = slices.user;

interface Props {
  isVisible: boolean;
  itemToVoid?: Transaction | undefined;
  transactionInfo?: GetTransactionsViaTransactionNoResponse;
  transaction_no?: string;
  setItemVoid?: () => void;
  onClose: () => void;
  getTransaction: () => void;
  isDeleteTransaction?: boolean;
  getTransactionPayments: () => void;
}

const { actions: transactionActions } = slices.transaction;

export const FormPassCodeTransaction = ({
  isVisible,
  onClose,
  itemToVoid,
  transactionInfo,
  transaction_no,
  setItemVoid,
  isDeleteTransaction,
  getTransaction,
  getTransactionPayments
}: Props) => {
  const {
    validatePasswordRequest,
    validatePasscodeSuccess
  } = useFormPasscodeDialog();
  const [dialogVisible, setIsVisible] = useState(isVisible);
  const [voidReason, setVoidReason] = useState<string>();
  const validatePasscodeLoading = useAppSelector(
    selectors.selectValidatePasscodeLoading
  );
  const { isOwner, isSupervisor } = useRoles();
  const { canVoidTransaction } = usePermissions();

  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsVisible(false);
  };

  const onDeleteWholeTransaction = async (void_reason: string | undefined) => {
    const response = unwrapResult(
      await dispatch(
        transactionActions.deleteTransactionViaTransactionNo({
          transaction_no,
          void_reason
        })
      )
    );
    if (response?.success && !isEmpty(response?.originalData)) {
      snackBar.show({
        message: 'Transaction Successfully Deleted',
        severity: 'info'
      });

      const transactionVoidedDataForTapTap = cloneDeep(transactionInfo);
      if (transactionVoidedDataForTapTap) {
        transactionVoidedDataForTapTap.total_price = 0; // 0 kasi wala eh deleted na :(
        transactionVoidedDataForTapTap.sub_total_price = 0; // 0 kasi wala eh deleted na :(
        onTapTapCustomerTransactionInfoChangeViaInternal(
          transactionVoidedDataForTapTap
        );
      }

      navigate('/app/transactions');
    } else {
      snackBar.show({
        message: 'Deleting of Transaction Failed',
        severity: 'error'
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDeleteItemTransaction = async (voidReason: string | undefined) => {
    if (itemToVoid?.serial_no) {
      const response = unwrapResult(
        await dispatch(
          transactionActions.voidItemTransaction({
            serial_no: itemToVoid?.serial_no,
            transaction_no,
            void_reason: voidReason
          })
        ).finally(() => {
          onClose();
          setItemVoid;
        })
      );

      if (response?.success && response?.originalData?.serial_no) {
        snackBar.show({
          message: `Item with SN: ${response?.originalData?.serial_no} successfully deleted`,
          severity: 'info'
        });
        const newTransactionItems = transactionInfo?.items?.filter(
          (x) => x.serial_no !== response?.originalData?.serial_no
        );
        if (isEmpty(newTransactionItems)) {
          snackBar.show({
            message: `Transaction deleted. Get out of here now`,
            severity: 'success',
            useSound: true
          });
          navigate(-1);
          getTransaction();
          return;
        }
        getTransaction();
        getTransactionPayments();
        return;
      }
      snackBar.show({
        message: `Item with SN: ${response?.originalData?.serial_no} successfully deleted`,
        severity: 'error'
      });
    }
  };

  const onSubmitPermission = (values: Passcode) => {
    validatePasswordRequest(values);
    setVoidReason(values.void_reason);
    setIsVisible(false);
  };

  const onSubmitVoidItem = (values: any) => {
    onDeleteItemTransaction(values.void_reason);
    setIsVisible(false);
  };

  const onSubmitDeleteTransaction = (values: any) => {
    onDeleteWholeTransaction(values.void_reason);
  };

  const onSubmitDeleteTransactionPermission = (values: Passcode) => {
    validatePasswordRequest(values);
    setVoidReason(values.void_reason);
    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(isVisible);
    if (validatePasscodeSuccess) {
      onDeleteItemTransaction(voidReason);
    }
    if (validatePasscodeSuccess && isDeleteTransaction) {
      onDeleteWholeTransaction(voidReason);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDeleteTransaction,
    isVisible,
    onDeleteItemTransaction,
    validatePasscodeSuccess,
    voidReason
  ]);

  return (
    <Dialog
      open={dialogVisible}
      onClose={onClose}
      BackdropProps={{
        onClick: handleClose
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Container maxWidth="sm">
          {isOwner || isSupervisor || canVoidTransaction ? (
            <Formik
              initialValues={{
                void_reason: ''
              }}
              validationSchema={Yup.object().shape({
                void_reason: Yup.string()
                  .max(500)
                  .required('void reason is required')
              })}
              onSubmit={
                isDeleteTransaction
                  ? onSubmitDeleteTransaction
                  : onSubmitVoidItem
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {isDeleteTransaction ? (
                    <div>
                      {' '}
                      <Typography
                        variant={'h5'}
                        style={{ marginBottom: 5, fontSize: 16 }}
                      >
                        {'Are you sure you want to delete this transaction?'}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {' '}
                      <Typography
                        variant={'h5'}
                        style={{ marginBottom: 5, fontSize: 16 }}
                      >
                        {'Are you sure you want to void this item?'}
                      </Typography>
                      <Typography>
                        {`Voiding this item (${itemToVoid?.product_name})  will make it "available" again or if consumable, it will revert the quantity `}
                      </Typography>
                    </div>
                  )}
                  <Typography variant={'h6'} style={{ marginTop: 5 }}>
                    {'Enter a reason in '}
                    {isDeleteTransaction
                      ? 'deleting this Transaction'
                      : 'voiding this item'}
                  </Typography>

                  <TextField
                    id="void_reason"
                    error={Boolean(touched.void_reason && errors.void_reason)}
                    fullWidth
                    helperText={touched.void_reason && errors.void_reason}
                    label="Void Reason"
                    margin="normal"
                    name="void_reason"
                    multiline
                    minRows={5}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.void_reason}
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={validatePasscodeLoading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isDeleteTransaction
                        ? 'Validate/Delete this item'
                        : 'Validate/Void this item'}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                username: '',
                passcode: '',
                void_reason: ''
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string()
                  .max(255)
                  .required('Username is required'),
                passcode: Yup.string()
                  .max(255)
                  .required('Passcode is required'),
                void_reason: Yup.string()
                  .max(500)
                  .required('void reason is required')
              })}
              onSubmit={
                isDeleteTransaction
                  ? onSubmitDeleteTransactionPermission
                  : onSubmitPermission
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {isDeleteTransaction ? (
                    <div>
                      {' '}
                      <Typography
                        variant={'h5'}
                        style={{ marginBottom: 5, fontSize: 16 }}
                      >
                        {'Are you sure you want to delete this transaction?'}
                      </Typography>
                    </div>
                  ) : (
                    <div>
                      {' '}
                      <Typography
                        variant={'h5'}
                        style={{ marginBottom: 5, fontSize: 16 }}
                      >
                        {'Are you sure you want to void this item?'}
                      </Typography>
                      <Typography>
                        {`Voiding this item (${itemToVoid?.product_name})  will make it "available" again or if consumable, it will revert the quantity `}
                      </Typography>
                    </div>
                  )}
                  <Divider />
                  <Typography variant={'h6'} style={{ marginTop: 5 }}>
                    {'Enter Passcode'}
                  </Typography>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    fullWidth
                    helperText={touched.username && errors.username}
                    label="Username"
                    id="username"
                    margin="normal"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    variant="outlined"
                  />
                  <TextField
                    id="passcode"
                    error={Boolean(touched.passcode && errors.passcode)}
                    fullWidth
                    helperText={touched.passcode && errors.passcode}
                    label="Passcode"
                    margin="normal"
                    name="passcode"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.passcode}
                    variant="outlined"
                  />
                  <TextField
                    id="void_reason"
                    error={Boolean(touched.void_reason && errors.void_reason)}
                    fullWidth
                    helperText={touched.void_reason && errors.void_reason}
                    label="Void Reason"
                    margin="normal"
                    name="void_reason"
                    multiline
                    minRows={5}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.void_reason}
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={validatePasscodeLoading}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isDeleteTransaction
                        ? 'Validate/Delete this item'
                        : 'Validate/Void this item'}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}

          <LoaderBar isLoading={validatePasscodeLoading} />
        </Container>
      </DialogContent>
    </Dialog>
  );
};
