import React, { useEffect, useMemo, useState } from 'react';
import {
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Product } from 'src/types/product';
import { Autocomplete } from '@material-ui/lab';
import { CheckBoxLabel } from 'src/components';
import { ramKitTypeOptions } from 'src/constants';
import { Category, CustomInputEvent, Manufacturer } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { CategoriesEnum } from 'src/enums';
import { ConsumableProductField } from '../../component';

const { actions: categoryActions } = slices.category;
const { actions: manufacturerActions } = slices.manufacturer;

interface Props {
  productInfo?: Product;
  onChangeValue?: (newInfo: CustomInputEvent) => void;
  hasDivider?: boolean;
}

type GeneralInfoFields =
  | 'category_id'
  | 'manufacturer_id'
  | 'is_hidden_pricelist'
  | 'srp_exempted'
  | 'consumable_unit'
  | 'product_type';

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  }
}));

const component = ({
  productInfo,
  onChangeValue,
  hasDivider = true
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [manufacturerInputValue, setManufacturerInputValue] = useState('');

  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');

  const onChangeValueInternal = (e: CustomInputEvent) => {
    if (onChangeValue) {
      onChangeValue(e);
    }
  };

  const getCategories = async () => {
    const getCategoriesResponse = unwrapResult(
      await dispatch(categoryActions.getCategoriesThunk({}))
    );
    if (getCategoriesResponse?.originalData?.data) {
      setCategories(getCategoriesResponse.originalData.data);
    }
  };

  const getManufacturers = async () => {
    const getManufacturersResponse = unwrapResult(
      await dispatch(manufacturerActions.getManufacturersThunk({}))
    );
    if (getManufacturersResponse?.originalData?.manufacturers) {
      setManufacturers(getManufacturersResponse.originalData.manufacturers);
    }
  };

  const onChangePropertyViaName = (name: GeneralInfoFields, value: any) => {
    const newData: any = { target: { name, value } };
    onChangeValueInternal(newData);
  };

  const selectedCategory = useMemo(() => {
    const category = find(categories, { id: productInfo?.category_id });
    if (category) {
      return category;
    }
    return null;
  }, [categories, productInfo]);

  const selectedManufacturer = useMemo(() => {
    const manufacturer = find(manufacturers, {
      id: productInfo?.manufacturer_id
    });
    if (manufacturer) {
      return manufacturer;
    }
    return null;
  }, [manufacturers, productInfo]);

  const isSelectedCategoryRAM = useMemo(() => {
    // RAM - 13 on stage, prod and dev
    // NOTE: RAM's ID on database should always be 13
    if (selectedCategory?.id === CategoriesEnum.RAM) {
      return true;
    }
    return false;
  }, [selectedCategory]);

  useEffect(() => {
    getCategories();
    getManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CardContent>
        <Typography className={classes.subHeader} variant="h6">
          General Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Ecomm product display name"
              name="ec_display_name"
              required
              variant="outlined"
              value={productInfo?.ec_display_name || ''}
              onChange={(e) => onChangeValueInternal(e)}
              inputProps={{ maxLength: 75 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              required
              variant="outlined"
              value={productInfo?.name || ''}
              onChange={(e) => onChangeValueInternal(e)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="SKU"
              name="sku"
              variant="outlined"
              value={productInfo?.sku || ''}
              onChange={(e) => onChangeValueInternal(e)}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Autocomplete
              value={selectedCategory}
              onChange={(_, newValue) => {
                onChangePropertyViaName('category_id', newValue?.id);
              }}
              inputValue={categoryInputValue}
              onInputChange={(_, newInputValue) => {
                setCategoryInputValue(newInputValue);
              }}
              noOptionsText={`"${categoryInputValue}" has not been found in categories. Please add it first`}
              id="category"
              options={categories}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Category"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {isSelectedCategoryRAM ? (
            <Grid item md={6} xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Ram Kit Type</InputLabel>
                <Select
                  name="ram_kit"
                  value={productInfo?.ram_kit ?? null}
                  onChange={(e: any) => onChangeValueInternal(e)}
                  label="RAM kit type"
                >
                  {ramKitTypeOptions?.map((item) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          <Grid item md={6} xs={12}>
            <Autocomplete
              value={selectedManufacturer}
              onChange={(_, newValue) => {
                onChangePropertyViaName('manufacturer_id', newValue?.id);
              }}
              inputValue={manufacturerInputValue}
              onInputChange={(_, newInputValue) => {
                setManufacturerInputValue(newInputValue);
              }}
              noOptionsText={`"${manufacturerInputValue}" has not been found in manufacturers. Please add it first`}
              id="manufacturer"
              options={manufacturers}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Manufacturer"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <ConsumableProductField
              productType={productInfo?.product_type}
              selectedConsumable={productInfo?.consumable_unit}
              onChangeProductType={(v) =>
                onChangePropertyViaName('product_type', v)
              }
              setConsumableUnit={(u) =>
                onChangePropertyViaName('consumable_unit', u)
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CheckBoxLabel
              label="Hide on website's price list"
              onChange={(value) =>
                onChangePropertyViaName('is_hidden_pricelist', value)
              }
              checked={productInfo?.is_hidden_pricelist ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CheckBoxLabel
              label="SRP exempted"
              onChange={(value) =>
                onChangePropertyViaName('srp_exempted', value)
              }
              checked={productInfo?.srp_exempted ? true : false}
            />
          </Grid>
        </Grid>
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div>
  );
};

export const ProductGeneralInfo = React.memo(component);
