import React, { useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import {
  EcomOrdersListToolbar as Toolbar,
  EcomOrdersListTable
} from './components';
import { LoaderBar } from 'src/components';
import { debounce } from 'lodash';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Pagination } from '@material-ui/lab';
const {
  actions: ecomOrderActions,
  selectors: ecomOrderSelectors
} = slices.ecomOrder;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

export const component = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const paginationMeta = useAppSelector(
    ecomOrderSelectors.getEcomOrderPaginationMeta
  );
  const orderListRequest = useAppSelector(
    ecomOrderSelectors.getEcomOrderListRequest
  );

  const getEcomOrderList = debounce(async () => {
    setIsLoading(true);
    dispatch(ecomOrderActions.getEcomOrderListThunk({})).finally(() =>
      setIsLoading(false)
    );
  }, 500);

  const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
    dispatch(ecomOrderActions.updateGetEcomOrdersListRequest({ page: value }));
    getEcomOrderList();
  };

  return (
    <Page className={classes.root} title="E-commerce Orders">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <EcomOrdersListTable />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        {(paginationMeta?.last_page || 0) > 1 ? (
          <Pagination
            count={paginationMeta?.last_page || 1}
            page={orderListRequest?.page || 1}
            onChange={handlePageChange}
          />
        ) : null}
      </Box>
    </Page>
  );
};

export const EcomOrdersListView = React.memo(component);
