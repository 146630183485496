import React, { useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { TapTapCustomerData } from 'src/redux/slices/taptap-customer/types';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { formatCurrency, formatDate } from 'src/utils';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import { colors } from 'src/constants';
import TapTapCustomerPaymentRow from './TapTapCustomerPaymentRow';
import {
  taptapOptionsStatuses,
  taptapStatuses,
  taptapUpdateCustomerViaId
} from 'src/redux/slices/taptap-customer';

interface Props {
  customer: TapTapCustomerData;
}

const TapTapCashierCustomerItem = ({ customer }: Props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPaymentsCollapsibleOpen, setIsPaymentsCollapsibleOpen] = useState<
    boolean
  >(false);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };
  const customStatusColor = useMemo(() => {
    const statusBgColor = taptapOptionsStatuses?.find(
      (status) => status.value === customer?.status
    )?.bgColor;

    return statusBgColor || 'inherit';
  }, [customer.status]);

  const updatedCustomerDataOnSupabase = async (
    columnName: string,
    value: any
  ) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const updatedData = { [columnName]: value };

    if (customer?.id) {
      await taptapUpdateCustomerViaId(customer?.id, updatedData);
    }

    setIsLoading(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatedCustomerDataOnSupabase(
      event.currentTarget.name,
      event.currentTarget.checked
    );
  };

  const onDonePaymentClicked = () => {
    if (customer?.balance && customer?.balance > 0) {
      alert(
        'Please settle the balance first. Opening new tab for Adding Payment'
      );
      var win = window.open(
        `/app/transaction/${customer?.internal_transaction_no}`,
        '_blank'
      );
      win?.focus();
      return;
    }

    updatedCustomerDataOnSupabase(
      taptapCustomerField.STATUS,
      taptapStatuses.DONE_PAYMENT
    );
  };

  return (
    <Grid key={customer?.id} item xs={12} md={6}>
      <Card elevation={5}>
        <CardHeader
          style={{ backgroundColor: colors.grey[100] }}
          action={
            <Button
              style={{
                background: colors.lightBlue[400],
                color: colors.common.white
              }}
              variant="contained"
              size="large"
              onClick={onDonePaymentClicked}
            >
              DONE PAYMENT
            </Button>
          }
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <LinkComponent
                openInNewTab
                title={
                  <Typography variant="inherit" style={{ color: colors.link }}>
                    {customer?.first_name}
                  </Typography>
                }
                href={`/app/customer-taptap/${customer?.id}`}
                onClick={() => navigate(`/customer-app/taptap/${customer?.id}`)}
              />

              <LinkComponent
                openInNewTab
                title={
                  <Typography
                    variant="inherit"
                    style={{ marginLeft: 20, color: colors.link }}
                  >
                    {customer?.internal_transaction_no}
                  </Typography>
                }
                href={`/app/transaction/${customer?.internal_transaction_no}`}
                onClick={() =>
                  navigate(
                    `/app/transaction/${customer?.internal_transaction_no}`
                  )
                }
              />
            </div>
          }
          subheader={
            <Typography variant="caption">
              {customer?.branch_released} -{' '}
              {` ${formatDate(customer?.created_at, 'MMM d, yyyy h:mm a')}`}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Typography
                variant="h6"
                style={{
                  color: customStatusColor
                }}
              >
                {customer?.status}
              </Typography>
              <Typography variant="h4">
                TOTAL: {formatCurrency(customer?.total_amount)}
              </Typography>
              <Typography
                variant="h5"
                style={{
                  color:
                    customer?.balance && customer?.balance > 0
                      ? colors.error
                      : colors.success
                }}
              >
                BALANCE: {formatCurrency(customer?.balance)}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}
            >
              <Typography variant="h5">
                SF: {formatCurrency(customer?.shipping_fee)}
              </Typography>

              <FormControlLabel
                disabled={isLoading}
                style={{ margin: 0, padding: 0, opacity: isLoading ? 0.3 : 1 }}
                labelPlacement="start"
                control={
                  <Checkbox
                    style={{
                      padding: 0,
                      margin: 0,
                      marginBottom: 0,
                      marginLeft: 2
                    }}
                    disabled={isLoading}
                    color="primary"
                    size="small"
                    id={`${taptapCustomerField.IS_SHIPPING_FEE_PAID}-${customer?.id}`}
                    name={taptapCustomerField.IS_SHIPPING_FEE_PAID}
                    checked={customer?.is_shipping_fee_paid}
                    onChange={handleCheckboxChange}
                  />
                }
                label={'SF Paid:'}
              />
            </div>
          </div>
          <Typography
            display="inline"
            variant="body2"
            onClick={toggleShowMore}
            style={{
              display: '-webkit-box',
              WebkitLineClamp: showMore ? 'none' : 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            REMARKS: {customer?.remarks || 'N/A'}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={() =>
              setIsPaymentsCollapsibleOpen(!isPaymentsCollapsibleOpen)
            }
          >
            PAID AMOUNT: {formatCurrency(customer?.paid_amount)}
          </Button>

          <LinkComponent
            openInNewTab
            title={
              <Button color="primary" variant="outlined" size="small">
                Add Payment
              </Button>
            }
            href={`/app/transaction/${customer?.internal_transaction_no}`}
          />
        </CardActions>
      </Card>

      <TapTapCustomerPaymentRow
        transactionNo={customer?.internal_transaction_no}
        customerPayments={customer?.customers_payments}
        isPaymentsCollapsibleOpen={isPaymentsCollapsibleOpen}
        onClickEditPayment={() => {}}
      />
    </Grid>
  );
};

export default TapTapCashierCustomerItem;
