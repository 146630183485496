import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { formatCurrency, marginColorViaGross } from 'src/utils';
import { colors } from 'src/constants';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { LoaderBar } from 'src/components';
import { TransactionsWithBalanceModal } from 'src/components/modal/sales/TransactionsWithBalanceModal';

const { selectors: salesSelectors } = slices.sales;
const { actions: transactionsActions } = slices.transaction;

interface Props {
  className?: string;
  isLoading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {},
  cardContent: {
    flexDirection: 'row'
  },
  totalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  gross: {
    color: colors.success
  }
}));

const SalesToolbar = ({ className, isLoading = false, ...rest }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const salesComputation = useAppSelector(
    salesSelectors.selectSalesResponseComputation
  );

  const salesFilter = useAppSelector(salesSelectors.selectAllSalesFilter);

  const [balanceModalOpen, setBalanceModalOpen] = useState<boolean>(false);

  const handleGetSalesWithBal = () => {
    if (!salesComputation.total_balance) {
      return;
    }

    dispatch(transactionsActions.getTransactionsWithBalanceThunk(salesFilter));
    // delay to let api finish request
    setTimeout(() => {
      setBalanceModalOpen(true);
    }, 800);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <TransactionsWithBalanceModal
        open={balanceModalOpen}
        totalBalance={formatCurrency(salesComputation?.total_balance)}
        handleClose={() => setBalanceModalOpen(false)}
      />
      <Box mt={3}>
        <LoaderBar isLoading={isLoading} />
        <Card>
          <CardContent>
            <Grid container spacing={8} alignItems="center" justify="center">
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Retail Price</Typography>
                  <Typography variant="h4" style={{ color: colors.blue[600] }}>
                    {formatCurrency(salesComputation?.total_retail_price)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Gross Price</Typography>
                  <Typography variant="h4" style={{ color: colors.green[600] }}>
                    {formatCurrency(salesComputation?.total_gross)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Dealers Price</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.orange[600] }}
                  >
                    {formatCurrency(salesComputation?.total_dealers_price)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="center" justify="center">
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">No. of Transactions</Typography>
                  <Typography variant="h4">
                    {salesComputation?.transactions || 0}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Balance</Typography>
                  <Tooltip
                    title="View items with balance"
                    onClick={handleGetSalesWithBal}
                  >
                    <Typography
                      variant="h4"
                      style={{ cursor: 'pointer', color: colors.red[600] }}
                    >
                      {formatCurrency(salesComputation?.total_balance)}
                    </Typography>
                  </Tooltip>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Spend Kachi Points</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.yellow[700] }}
                  >
                    {formatCurrency(salesComputation?.total_spend_kachi_points)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Paid</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.purple[600] }}
                  >
                    {formatCurrency(salesComputation?.total_paid)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Margin Percentage</Typography>
                  <Typography
                    variant="h4"
                    style={{
                      color: marginColorViaGross(
                        salesComputation?.margin_percentage
                      )
                    }}
                  >
                    {salesComputation?.margin_percentage || '0%'}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default SalesToolbar;
