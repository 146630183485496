import React, { useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  AlternatingColorTableRow,
  LinkComponent,
  LoaderBar
} from 'src/components';
import {
  GetTransactionDetailResponse,
  GetTransactionsViaTransactionNoResponse
} from 'src/types';
import { formatCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from 'src/hooks';
import { colors } from 'src/constants';
import { cloneDeep } from 'lodash';
import { sortProductsByCategory } from 'src/utils/categories/sortByCategory';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  extraInfo: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

interface Props {
  isLoading?: boolean;
  purpose?: 'preview' | 'edit';
  transactionDetails?: GetTransactionDetailResponse;
  transactionInfo?: GetTransactionsViaTransactionNoResponse;
  onRefreshTransaction: () => void;
}

const component = ({
  purpose = 'edit',
  isLoading,
  transactionInfo,
  transactionDetails,
  onRefreshTransaction
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const permissions = usePermissions();

  //sorted values
  const sortedTransactionItems = useMemo(() => {
    if (transactionInfo?.items) {
      const sortedTransactionItems = cloneDeep(transactionInfo?.items) || [];
      return sortProductsByCategory(sortedTransactionItems);
    }
    return [];
  }, [transactionInfo]);

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader
        title={
          <LinkComponent
            openInNewTab
            href={`/app/transaction/${transactionInfo?.transaction_no}`}
            title={
              <Typography
                variant="h5"
                style={{ color: colors.link, textDecoration: 'underline' }}
              >
                {transactionDetails?.data?.transaction_no || ''}
              </Typography>
            }
          />
        }
        action={
          purpose === 'edit' && (
            <Button
              startIcon={<RefreshIcon />}
              variant="outlined"
              color="primary"
              onClick={onRefreshTransaction}
            >
              Sync Transaction Data
            </Button>
          )
        }
        subheader={
          <div>
            <Typography variant="h6">
              Transaction Total Price:{' '}
              {formatCurrency(transactionInfo?.total_price)}
            </Typography>
            {transactionDetails ? (
              <Typography variant="caption">{`Transaction No.: ${transactionDetails?.data?.transaction_no}. Created By: ${transactionDetails?.data?.created_by}. Posted on ${transactionDetails?.data?.date_posted}`}</Typography>
            ) : null}
          </div>
        }
      />
      <Divider />
      <LoaderBar isLoading={isLoading || false} />

      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Serial No.</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactionItems?.map((item, i) => (
                <AlternatingColorTableRow
                  style={
                    item?.isDeleted ? { backgroundColor: colors.error } : {}
                  }
                  hover
                  key={`${item?.serial_no}${i}`}
                >
                  <TableCell>{item?.branch_name ?? '--'}</TableCell>
                  <TableCell>{item?.category_name ?? '--'}</TableCell>
                  <TableCell>
                    <LinkComponent
                      openInNewTab
                      onClick={() =>
                        navigate(`/app/products/${item?.product_id}`)
                      }
                      href={`/app/products/${item?.product_id}`}
                      title={`${item?.product_name ?? '--'}`}
                    />
                  </TableCell>
                  <TableCell>
                    {item?.isDeleted ? (
                      item?.serial_no ?? '--'
                    ) : (
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products-listings/${item.listing_id}`)
                        }
                        href={`/app/products-listings/${item.listing_id}`}
                        title={item?.serial_no ?? '--'}
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    {formatCurrency(item?.amount || item?.retail_price)}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>

          <div className={classes.extraInfo}>
            {permissions?.canViewSales ? (
              <Typography color="textSecondary" variant="subtitle2">
                sub total:{' '}
                {`${formatCurrency(transactionInfo?.sub_total_price) ??
                  `0.00`}`}
              </Typography>
            ) : null}
            {permissions?.canViewSales ? (
              <Typography color="textSecondary" variant="subtitle2">
                deducted kachi points:{' '}
                {`-${formatCurrency(
                  Math.abs(transactionInfo?.kachi_points || 0)
                )}`}
              </Typography>
            ) : null}
            <Typography variant="h4">
              TOTAL PRICE:{' '}
              {`${formatCurrency(transactionInfo?.total_price) ?? `0.00`}`}
            </Typography>
            {permissions?.canViewSales ? (
              <Typography color="textSecondary" variant="subtitle2">
                total dealers price:{' '}
                {`${formatCurrency(transactionInfo?.total_dealers_price) ??
                  `0.00`}`}
              </Typography>
            ) : null}
            {permissions?.canViewSales ? (
              <Typography color="textSecondary" variant="subtitle2">
                total gross:{' '}
                {`${formatCurrency(transactionInfo?.total_gross) ?? `0.00`}`}
              </Typography>
            ) : null}

            {purpose === 'edit' && (
              <Button
                color="primary"
                variant="contained"
                startIcon={<EditIcon />}
                // onClick={() => {}}
              >
                <LinkComponent
                  openInNewTab
                  href={`/app/transaction/${transactionInfo?.transaction_no}`}
                  title={
                    <Typography
                      variant="inherit"
                      style={{ color: colors.common.white }}
                    >
                      Edit Transaction
                    </Typography>
                  }
                />
              </Button>
            )}
          </div>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export const TapTapTransactionsCard = React.memo(component);
