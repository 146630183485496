import React from 'react';

import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { colors } from 'src/constants';
import { LinkComponent } from '../link';

interface Props {
  index: number; // For identification on what to delete.. Could be Id could also be index
  height?: number;
  width?: number;
  imgSrc?: string;
  hideImageBorder?: boolean;
  onDelete: (id: number) => void;
  extraButtons?: JSX.Element;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '1px solid grey'
  },
  hiddenBorder: {
    border: '0px'
  },
  buttonsContainer: {
    marginTop: 5,
    marginLeft: 5,
    position: 'absolute',
    zIndex: 99
  },
  deleteIcon: {
    padding: 3,
    backgroundColor: colors.common.white,
    cursor: 'pointer'
  },
  img: {
    '&:hover': {
      opacity: 0.3
    }
  }
}));

const component = ({
  imgSrc,
  onDelete,
  index,
  height = 100,
  width = undefined,
  hideImageBorder = false,
  extraButtons
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        hideImageBorder ? classes.hiddenBorder : undefined
      )}
    >
      <Box className={classes.buttonsContainer}>
        <IconButton
          className={classes.deleteIcon}
          onClick={() => onDelete(index)}
        >
          <DeleteIcon fontSize="medium" color="secondary" />
        </IconButton>
        {extraButtons ? extraButtons : null}
      </Box>
      <LinkComponent
        openInNewTab
        href={imgSrc}
        title={
          <img
            className={classes.img}
            alt="Cover Image"
            height={height}
            width={width}
            src={imgSrc}
          />
        }
      />
    </div>
  );
};

export const DeletableImg = React.memo(component);
