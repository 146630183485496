export const taptapTrialOS = {
  WIN_11: 'WIN 11',
  WIN_10: 'WIN 10',
  NO_OS: 'NO OS'
};

export const taptapOptionsTrialOS = [
  { key: 'win_11', label: taptapTrialOS.WIN_11, value: taptapTrialOS.WIN_11 },
  { key: 'win_10', label: taptapTrialOS.WIN_10, value: taptapTrialOS.WIN_10 },
  { key: 'no_os', label: taptapTrialOS.NO_OS, value: taptapTrialOS.NO_OS }
];
