/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Popper,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { CustomInputEvent } from 'src/types';
import {
  findSelectedQuickFilterViaParam,
  quickFilterBranchesChoices,
  quickFilterViewsChoices,
  taptapCustomerInitialPayload,
  updateColumnVisibilityUtil
} from 'src/redux/slices/taptap-customer';
import {
  TapTapColumn,
  TapTapQuickFilterView,
  TapTapFiltersV2,
  TapTapGetCustomersCondition
} from 'src/redux/slices/taptap-customer/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { cloneDeep, every, isEqual, some } from 'lodash';
import { initialTaptapTableColumns } from 'src/redux/slices/taptap-customer/constants/taptap-table-columns';
import { CheckBoxLabel } from 'src/components';
import { snakeCaseToTitleCase } from 'src/utils';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';

interface Props {
  className?: string;
  input?: string;
  onSearchCustomer?: (text: string) => void;
  onToolbarInputChange?: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const {
  actions: taptapCustomerActions,
  selectors: taptapCustomerSelectors
} = slices.taptapCustomer;

const Toolbar = ({ className, ...rest }: Props) => {
  const navigate = useNavigate();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const selectedSearchValue = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerSearchValue
  );
  const selectedFilter = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerFilter
  );
  const selectedColumns = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerColumns
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElColumns, setAnchorElColumns] = useState(null);

  const [isFilterPopEverOpen, setIsFilterPopEverOpen] = useState<boolean>(
    false
  );
  const [tempPopOverFilter, setTempPopOverFilter] = useState<TapTapFiltersV2>(
    taptapCustomerInitialPayload.filters
  );

  const [isColumnsPopOverOpen, setIsColumnsPopOverOpen] = useState<boolean>(
    false
  );
  const [tempPopOverColumns, setTempPopOverColumns] = useState<TapTapColumn[]>(
    []
  );

  const onResetAllTapTapState = () => {
    dispatch(taptapCustomerActions.resetAll());
  };

  const onColumnsPopOverOpen = (event: any) => {
    const sortedColumns = cloneDeep(selectedColumns)
      .map((column, i) => ({ ...column, origIndex: i }))
      .sort((a, b) => {
        if (a.field < b.field) {
          return -1;
        }
        if (a.field > b.field) {
          return 1;
        }
        return 0;
      });

    setTempPopOverColumns(sortedColumns); // Set state filter from redux
    setAnchorElColumns(event.currentTarget);
    setIsColumnsPopOverOpen((prev) => !prev);
  };

  const onColumnsPopOverClose = () => {
    setIsColumnsPopOverOpen(false);
    setTempPopOverColumns([]); // reset state filter to initial
  };

  const onColumnsPopOverReset = () => {
    setTempPopOverColumns(initialTaptapTableColumns); // reset state filter to initial
  };

  const onApplyColumns = () => {
    const clonedColumns = cloneDeep(tempPopOverColumns).sort(
      (a, b) => (a.origIndex || 0) - (b.origIndex || 0)
    );
    dispatch(taptapCustomerActions.updateTaptapColumns(clonedColumns));
    onColumnsPopOverClose();
  };

  const onFilterPopOverOpen = (event: any) => {
    setTempPopOverFilter(selectedFilter); // Set state filter from redux
    setAnchorEl(event.currentTarget);
    setIsFilterPopEverOpen((prev) => !prev);
  };

  const onFilterPopOverClose = () => {
    setIsFilterPopEverOpen(false);
    setTempPopOverFilter(taptapCustomerInitialPayload.filters); // reset state filter to initial
  };

  const onFilterPopOverClear = () => {
    setTempPopOverFilter(taptapCustomerInitialPayload.filters); // reset state filter to initial
  };

  const onApplyFilter = () => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(
      tempPopOverFilter
    );

    // If quick filter is selected. Apply its default column sorting and visible
    if (
      selectedQuickFilter &&
      selectedQuickFilter.defaultColumns &&
      selectedQuickFilter.defaultColumns?.length > 0
    ) {
      const newColumnArrangement = updateColumnVisibilityUtil(
        selectedColumns,
        selectedQuickFilter.defaultColumns
      );

      dispatch(taptapCustomerActions.updateTaptapColumns(newColumnArrangement));
    } else {
      dispatch(taptapCustomerActions.resetTaptapColumns());
    }

    dispatch(
      taptapCustomerActions.updateTaptapCustomerFilter(tempPopOverFilter)
    );
    dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
    onFilterPopOverClose();
  };

  const selectedBranchIndicator = useMemo(() => {
    const selectedBranchCopy = selectedFilter?.conditions.find(
      (condition) => condition.field === 'branch_released'
    )?.value;

    return selectedBranchCopy || 'ALL';
  }, [selectedFilter]);

  const selectedFilterIndicator = useMemo(() => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(selectedFilter);
    return selectedQuickFilter?.label || 'ALL ?';
  }, [selectedFilter]);

  const isBranchSelected = useCallback(
    (params: TapTapGetCustomersCondition) => {
      if (tempPopOverFilter) {
        const fieldInConditions = tempPopOverFilter?.conditions.find(
          (condition) => condition.field === taptapCustomerField.BRANCH_RELEASED
        );
        if (fieldInConditions) {
          return isEqual(fieldInConditions.value, params.value);
        }
      }

      return false;
    },
    [tempPopOverFilter]
  );

  const onQuickFilterSelectBranch = (
    newCondition: TapTapGetCustomersCondition
  ) => {
    const clonedTempPopOverFilter = cloneDeep(tempPopOverFilter);
    const index = clonedTempPopOverFilter?.conditions?.findIndex(
      (condition) => condition.field === newCondition.field
    );

    if (index !== -1) {
      // If it exists, replace the existing condition or unselect
      if (isEqual(newCondition, clonedTempPopOverFilter.conditions[index])) {
        // Pag selected na yung same quick filter, unselect
        clonedTempPopOverFilter.conditions.splice(index, 1);
      } else {
        clonedTempPopOverFilter.conditions[index] = newCondition;
      }
    } else {
      // If it doesn't exist, push the new condition
      clonedTempPopOverFilter.conditions.push(newCondition);
    }

    setTempPopOverFilter(clonedTempPopOverFilter);
  };

  const isQuickViewFilterSelected = useCallback(
    (quickFilterViewParam: TapTapQuickFilterView) => {
      let clonedTempPopOverFilter = cloneDeep(tempPopOverFilter);
      clonedTempPopOverFilter.conditions = clonedTempPopOverFilter.conditions.filter(
        (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
      );

      if (isEqual(clonedTempPopOverFilter, quickFilterViewParam.filters)) {
        return true;
      }

      return false;
    },
    [tempPopOverFilter]
  );

  const onQuickFilterViewSelect = (quickFilterView: TapTapQuickFilterView) => {
    // Deep clone the tempPopOverFilter
    const clonedTempPopOverFilter = cloneDeep(tempPopOverFilter);

    // Find the selected branch condition
    const selectedBranchCondition = clonedTempPopOverFilter?.conditions?.find(
      (condition) => condition?.field === taptapCustomerField.BRANCH_RELEASED
    );
    if (selectedBranchCondition) {
      // Remove branch condition temporarily
      clonedTempPopOverFilter.conditions = clonedTempPopOverFilter.conditions.filter(
        (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
      );
    }
    // Deep clone quickFilterView.filters to avoid mutation
    const newFilter = cloneDeep(quickFilterView.filters);
    if (isQuickViewFilterSelected(quickFilterView)) {
      // If the quick filter is already selected, unselect it
      newFilter.conditions = [];
    }

    // Add back the branch condition if present
    if (selectedBranchCondition && selectedBranchCondition?.value?.length > 0) {
      newFilter.conditions.push(selectedBranchCondition);
    }
    // Update the state with the new filter
    setTempPopOverFilter(newFilter);
  };

  const onToolbarInputChange = (e: CustomInputEvent) => {
    dispatch(taptapCustomerActions.updateSearchValue(e.target.value));
  };

  const onSearchClick = () => {
    dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(taptapCustomerActions.updateSearchValue(e.target.value));
      dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
    }
  };

  const onAddCustomerClick = () => {
    navigate(`/app/customer-taptap/add`);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography color="textPrimary" variant="h3">
            Customers
          </Typography>
          <TextField
            style={{ marginLeft: 10 }}
            value={selectedSearchValue}
            onChange={onToolbarInputChange}
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Search First Name / Transaction No"
            variant="outlined"
            onKeyPress={onKeyPress}
          />
          <Button
            onClick={onSearchClick}
            color="primary"
            style={{ marginLeft: 10 }}
            variant="contained"
          >
            <SearchIcon />
          </Button>
        </div>

        <div>
          <Typography variant="h4">
            BRANCH: {selectedBranchIndicator}, QUICK VIEW:{' '}
            {selectedFilterIndicator}
          </Typography>
        </div>
        <div>
          <Button
            onClick={onResetAllTapTapState}
            color="secondary"
            variant="text"
            size="small"
            style={{ marginLeft: 20 }}
          >
            IF FREEZING CLICK ME
          </Button>
          <Button
            onClick={onFilterPopOverOpen}
            color="primary"
            variant="outlined"
            style={{ marginLeft: 20 }}
          >
            Select Filter
          </Button>
          <Button
            onClick={onColumnsPopOverOpen}
            color="primary"
            variant="outlined"
            style={{ marginLeft: 20 }}
          >
            Hide Columns
          </Button>
          <Button
            onClick={onAddCustomerClick}
            color="primary"
            variant="contained"
            style={{ marginLeft: 20 }}
          >
            Add customer
          </Button>
        </div>
      </Box>

      {/* TODO: Make a component */}
      <Popper
        open={isFilterPopEverOpen}
        anchorEl={anchorEl}
        placement={'bottom'}
        style={{ zIndex: 9999 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mr={1}
          >
            <Typography variant="h4">Quick Filters</Typography>
            <div>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="secondary"
                onClick={onFilterPopOverClear}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="primary"
                onClick={onFilterPopOverClose}
              >
                Close
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onApplyFilter}
                style={{ marginLeft: 10 }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Divider />

          <Typography style={{ marginTop: 20 }} variant="h5">
            Branch
          </Typography>
          <Box mt={1}>
            {quickFilterBranchesChoices?.map((choice) => (
              <Button
                key={choice?.field}
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() => onQuickFilterSelectBranch(choice)}
                variant={isBranchSelected(choice) ? 'contained' : 'outlined'}
              >
                {choice?.value}
              </Button>
            ))}
          </Box>

          <Typography style={{ marginTop: 20 }} variant="h5">
            Quick Views
          </Typography>
          <Box mt={1}>
            {quickFilterViewsChoices?.map((choice) => (
              <Button
                key={choice.id}
                style={{ marginRight: 10 }}
                color="primary"
                onClick={() => onQuickFilterViewSelect(choice)}
                variant={
                  isQuickViewFilterSelected(choice) ? 'contained' : 'outlined'
                }
              >
                {choice.label}
              </Button>
            ))}
          </Box>
        </Paper>
      </Popper>

      {/* TODO: Make a component */}
      <Popper
        open={isColumnsPopOverOpen}
        anchorEl={anchorElColumns}
        placement={'bottom'}
        style={{ zIndex: 9999, width: 500 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mr={1}
          >
            <Typography variant="h4">Hide / Unhide Columns</Typography>
            <div>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="secondary"
                onClick={onColumnsPopOverReset}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="primary"
                onClick={onColumnsPopOverClose}
              >
                Close
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onApplyColumns}
                style={{ marginLeft: 10 }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Divider />

          <Typography style={{ marginTop: 20 }} variant="h5">
            Quick Views
          </Typography>
          <Box mt={1}>
            {tempPopOverColumns?.map((column) => (
              <CheckBoxLabel
                key={column.field}
                label={snakeCaseToTitleCase(column.field)}
                checked={column.visible}
                onChange={(value) => {
                  setTempPopOverColumns((prev) => {
                    const index = prev.findIndex(
                      (item) => item.field === column.field
                    );

                    const newColumns = [...prev];
                    newColumns[index] = {
                      ...newColumns[index],
                      visible: value
                    };

                    return newColumns;
                  });
                }}
              />
            ))}
          </Box>
        </Paper>
      </Popper>
    </div>
  );
};

export default Toolbar;
