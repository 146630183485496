import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  DatePickerRangeComponent,
  DragAndDropImgUpload,
  Page,
  SelectImageButton
} from 'src/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  convertImageFileToBase64,
  dateToday,
  transformerDateField
} from 'src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PromoProductListings } from '../component/PromoProductListing';
import usePromotional from 'src/hooks/promotional/use-promotional';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import usePrompt from 'src/utils/navigation-prompt';
import { useIsMobile } from 'src/hooks/use-is-mobile';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const PromotionalCreatePage = () => {
  const classes = useStyles();
  const {
    initialPromoProductListings,
    errMsgArr,
    showErrMsg,
    shouldPrompt,
    resetPromotionalDetails,
    setShowErrMsg,
    getPromotionalProductCreate,
    createPromotional,
    setShouldPrompt
  } = usePromotional();
  const isMobile = useIsMobile();

  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [banner, setBanner] = useState<any>();
  const [logoUrl, setLogoUrl] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      title: '',
      banner_url: '',
      badge_url: '',
      start_date: '',
      end_date: '',
      is_publish: false
    },
    validationSchema,
    onSubmit: () => {}
  });

  const hasFormErrors = useMemo(() => Object.keys(formik.errors).length > 0, [
    formik.errors
  ]); //check if there is errors in any fields

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | undefined, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;

      if (field === 'to') {
        setToDate(transformedDate);
        formik.setFieldValue('end_date', transformedDate);
      }
      if (field === 'from') {
        setFromDate(transformedDate);
        formik.setFieldValue('start_date', transformedDate);
      }
    },
    [formik]
  );

  const onChangeBanner = useCallback(
    async (img: File[]) => {
      if (img && img?.length > 0) {
        const firstFile = img[0];
        const imgInTxt: any = await convertImageFileToBase64(firstFile);
        setBanner(imgInTxt);
        formik.setFieldValue('banner_url', imgInTxt ? imgInTxt : '');
      }
    },
    [formik]
  );

  const onChangeBadge = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setLogoUrl(imgInBase64);
      formik.setFieldValue('badge_url', imgInBase64);
    }
  };

  usePrompt(
    `There are some unsaved changes. Are you sure you want to leave?`,
    formik.values !== undefined && shouldPrompt
  );

  const onCreatePromotional = useCallback(async () => {
    if (hasFormErrors) {
      return;
    }
    if (
      formik.values &&
      initialPromoProductListings &&
      initialPromoProductListings?.data
    ) {
      const resultGetPromoProduct = getPromotionalProductCreate();
      resultGetPromoProduct.then((result) => {
        setShouldPrompt(false);
        createPromotional({ ...formik?.values, products: result });
      });

      return;
    }
  }, [
    hasFormErrors,
    formik.values,
    initialPromoProductListings,
    getPromotionalProductCreate,
    setShouldPrompt,
    createPromotional
  ]);

  useEffect(() => {
    resetPromotionalDetails();
  }, [resetPromotionalDetails]);

  return (
    <Page title="Promotional" className={classes.root}>
      <Container maxWidth={false}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', lg: 'row' }
            }}
          >
            <Typography color="textPrimary" gutterBottom variant="h3">
              Create Promo Information
            </Typography>
          </Box>

          <Box sx={{ mt: '1.5rem' }}>
            {banner && (
              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  p: '1rem'
                }}
              >
                <img
                  alt="Cover Image"
                  src={banner}
                  style={{
                    width: 900,
                    height: isMobile ? 100 : 300,
                    objectFit: 'contain'
                  }}
                />
              </Box>
            )}
            <DragAndDropImgUpload
              title="Drag or select photos for Banner"
              onImageSelected={onChangeBanner}
            />

            <Grid container spacing={3} style={{ marginTop: '1rem' }}>
              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="title"
                  name="title"
                  label="Promo Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={{ display: 'flex' }}>
                  {logoUrl && (
                    <img
                      alt="promo-badge"
                      style={{ width: 150, height: 50, marginRight: '1rem' }}
                      src={logoUrl}
                    />
                  )}
                  <SelectImageButton
                    containerStyle={{ width: '100%' }}
                    title="Select Promo Badge"
                    multiple={false}
                    onImageSelected={onChangeBadge}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <DatePickerRangeComponent
                  title="Promo Duration"
                  fromDateMin={dateToday()}
                  fromDateValue={fromDate || null}
                  toDateValue={toDate || null}
                  dateToLabel="End Date"
                  dateFromLabel="Start Date"
                  hasNoDateToday
                  toDateMin={fromDate}
                  onChangeToDate={(date) => onChangedDate(date, 'to')}
                  onChangeFromDate={(date) => onChangedDate(date, 'from')}
                />
              </Grid>
            </Grid>

            <PromoProductListings />
          </Box>
        </form>
        <Box sx={{ width: '100%', p: 3 }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            onClick={onCreatePromotional}
            disabled={
              !initialPromoProductListings?.data ||
              initialPromoProductListings?.data?.length <= 0 ||
              hasFormErrors
            }
          >
            Create Promotional
          </Button>
        </Box>
        <ErrorDialog
          errs={errMsgArr}
          open={showErrMsg}
          closeDialog={() => setShowErrMsg(false)}
        />
      </Container>
    </Page>
  );
};

const validationSchema = Yup.object({
  title: Yup.string()
    .required('Promo title is required')
    .min(2, 'Title is too short - should be 2 chars minimum.')
    .max(150, 'Title cannot exceed 150 characters')
    .test(
      'is-not-numeric',
      'This field must contain letters and cannot be only numbers',
      (value) => isNaN(Number(value))
    )
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      'Title must contain only letters, numbers, and spaces'
    )
});
