import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DateFieldsV2,
  DateRangeSpecific,
  GetSalesComputation,
  GetSalesRequest,
  GetSalesResponse,
  Sale,
  SalesColumn,
  SalesColumns
} from 'src/types';
import * as thunks from './thunks';
import { multiBranchFeat } from 'src/constants/feature-toggle';

type State = {
  sales: Sale[];
  isNextPageLoading: boolean;
  salesFilter: GetSalesRequest;
  salesResponse: GetSalesResponse | undefined;
  salesComputation: GetSalesComputation;
  salesColumns: SalesColumns;
};

const initialState: State = {
  isNextPageLoading: false,
  sales: [],
  salesComputation: {
    total_balance: 0,
    total_dealers_price: 0,
    total_gross: 0,
    total_paid: 0,
    total_retail_price: 0,
    total_spend_kachi_points: 0
  },
  salesFilter: {
    sold_date_from: null,
    sold_date_to: null,
    encoded_date_from: null,
    encoded_date_to: null,
    purchased_date_to: null,
    purchased_date_from: null,
    preset_dates: null,
    action: 'list',
    category_id: null,
    branch_ids: [],
    ecomm_transactions_only: 0
  },
  salesResponse: undefined,
  salesColumns: {
    id: false,
    customer_name: true,
    transaction_no: true,
    products: true,
    payments: true,
    amounts: true,
    date_sold: true,
    date_purchased: false,
    acknowledgement_receipt_no: true,
    warranty_slip_no: false,
    branch: multiBranchFeat,
    order_no: true
  }
};

const slice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    updateQuickFilter: (
      state,
      { payload }: PayloadAction<DateRangeSpecific | undefined>
    ) => {
      state.salesFilter = {
        ...state.salesFilter,
        preset_dates: payload || null
      };
    },
    removeQuickFilter: (state) => {
      state.salesFilter = {
        ...state.salesFilter,
        preset_dates: null
      };
    },
    toggleEcommOnlyFilter: (state, { payload }: PayloadAction<number>) => {
      state.salesFilter.ecomm_transactions_only = payload;
    },
    updateFilterDate: (
      state,
      { payload }: PayloadAction<{ date: string | null; field: DateFieldsV2 }>
    ) => {
      const { date, field } = payload;

      state.salesFilter = {
        ...state.salesFilter,
        [field]: date
      };
    },
    updateFilter: (
      state,
      { payload }: PayloadAction<GetSalesRequest | undefined>
    ) => {
      if (!payload) {
        state.salesFilter = initialState.salesFilter;
        return;
      }
      state.salesFilter = {
        ...state.salesFilter,
        ...payload
      };
    },
    resetFilter: (state) => {
      state.salesFilter = initialState.salesFilter;
    },
    setFilterToday: (state) => {
      state.salesFilter = {
        ...state.salesFilter,
        preset_dates: 'today'
      };
    },
    showAllColumns: (state) => {
      state.salesColumns = initialState.salesColumns;
    },
    toggleSalesColumn: (state, { payload }: PayloadAction<SalesColumn>) => {
      const key = payload;
      state.salesColumns = {
        ...state.salesColumns,
        [key]: !state.salesColumns[key]
      };
    },
    resetSalesDataOnApplyFilter: (state) => {
      state.sales = [];
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getSalesThunk.pending, (state) => {
      state.sales = initialState.sales;
      state.salesResponse = initialState.salesResponse;
    });
    builder.addCase(thunks.getSalesThunk.fulfilled, (state, { payload }) => {
      state.sales = payload.originalData.items || [];
      state.salesResponse = payload.originalData;
    });
    builder.addCase(thunks.getSalesNextPageThunk.pending, (state) => {
      state.isNextPageLoading = true;
    });
    builder.addCase(thunks.getSalesNextPageThunk.rejected, (state) => {
      state.isNextPageLoading = false;
    });
    builder.addCase(
      thunks.getSalesNextPageThunk.fulfilled,
      (state, { payload }) => {
        state.isNextPageLoading = false;
        state.sales = [...state.sales, ...(payload.originalData.items ?? [])];
        if (
          payload?.originalData?.meta?.current_page &&
          payload.originalData?.meta
        ) {
          state.salesResponse = {
            ...state.salesResponse,
            meta: payload.originalData?.meta
          };
        }
      }
    );
    builder.addCase(
      thunks.getSalesComputationThunk.fulfilled,
      (state, { payload }) => {
        state.salesComputation = payload.originalData;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
