import React, { FC, memo } from 'react';
import { Box, Button, IconButton, Tooltip } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ErrorListComponent } from 'src/redux/slices/pc-bundles/types';
import CachedIcon from '@material-ui/icons/Cached';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface Props {
  error_list?: ErrorListComponent[];
  onRetry: (serial_no?: string) => void;
  onRemove: (serial_no?: string) => void;
  onClearAll: () => void;
}

const Component: FC<Props> = ({
  error_list,
  onRetry,
  onRemove,
  onClearAll
}) => {
  return (
    <div>
      <Box>
        {error_list && error_list?.length > 0
          ? error_list?.map((errorSN, index) => (
              <Alert
                style={{ marginBottom: 1 }}
                key={`${errorSN?.serial_no}${index}`}
                severity="error"
                action={
                  <div>
                    <Tooltip title="Retry" placement="top">
                      <IconButton
                        aria-label="retry"
                        color="inherit"
                        size="medium"
                        onClick={() => {
                          onRetry(errorSN?.serial_no);
                        }}
                      >
                        <CachedIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Remove" placement="top">
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="medium"
                        onClick={() => {
                          onRemove(errorSN?.serial_no);
                        }}
                      >
                        <DeleteOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              >
                <AlertTitle>{errorSN?.serial_no}</AlertTitle>
                {errorSN?.status}
              </Alert>
            ))
          : null}
        {error_list && error_list.length > 0 ? (
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={onClearAll} color="secondary">
              Clear All Invalid Serial No(s)
            </Button>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export const ErrorComponentList = memo(Component);
