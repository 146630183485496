export const taptapCustomerField = {
  ID: 'id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  CONTACT_NO: 'contact_no',
  QUOTATION: 'quotation',
  INTERNAL_QUOTATION_ID: 'internal_quotation_id',
  INTERNAL_CUSTOMER_ID: 'internal_customer_id',
  INTERNAL_TRANSACTION_NO: 'internal_transaction_no',
  SOURCE: 'source',
  ONLINE_SALES_REPRESENTATIVE: 'online_sales_representative',
  SALES_REP: 'sales_rep',
  FB_TRANSACTION_TYPE: 'fb_transaction_type',
  VIBER_TRANSACTION_TYPE: 'viber_transaction_type',
  ECOMM_TRANSACTION_TYPE: 'ecomm_transaction_type',
  TECH: 'tech',
  ADDRESS: 'address',
  CREATED_AT: 'created_at',
  BRANCH_RELEASED: 'branch_released',
  COURIER: 'courier',
  SERVICE_TYPE: 'service_type',
  TOTAL_AMOUNT: 'total_amount',
  PAID_AMOUNT: 'paid_amount',
  BALANCE: 'balance',
  SHIPPING_FEE: 'shipping_fee',
  IS_SHIPPING_FEE_PAID: 'is_shipping_fee_paid',
  TRIAL_OS: 'trial_os',
  REMARKS: 'remarks',
  IS_AGREE_MARKETING: 'is_agree_marketing',
  IS_AGREE_TNC: 'is_agree_tnc',
  BUILD_TAG: 'build_tag',
  KIND_OF_SALES_TRANSFER: 'kind_of_sales_transfer',
  STATUS: 'status',
  TYPE_OF_BUILD: 'type_of_build',
  WARRANTY_SLIP_NO: 'warranty_slip_no',
  WITH_UPGRADE: 'with_upgrade',
  DELETED_AT: 'deleted_at',
  DELETED_BY: 'deleted_by',
  IS_DELETED: 'is_deleted',
  CUSTOMERS_PAYMENTS: 'customers_payments'
};

export const taptapCustomerFields = [
  { key: taptapCustomerField.ID, label: 'ID', value: taptapCustomerField.ID },
  {
    key: taptapCustomerField.FIRST_NAME,
    label: 'First Name',
    value: taptapCustomerField.FIRST_NAME
  },
  {
    key: taptapCustomerField.LAST_NAME,
    label: 'Last Name',
    value: taptapCustomerField.LAST_NAME
  },
  {
    key: taptapCustomerField.LAST_NAME,
    label: 'Last Name',
    value: taptapCustomerField.LAST_NAME
  },
  {
    key: taptapCustomerField.CONTACT_NO,
    label: 'Contact No',
    value: taptapCustomerField.CONTACT_NO
  },
  {
    key: taptapCustomerField.SOURCE,
    label: 'Source',
    value: taptapCustomerField.SOURCE
  },
  {
    key: taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
    label: 'Online Sales Representative',
    value: taptapCustomerField.ONLINE_SALES_REPRESENTATIVE
  },
  {
    key: taptapCustomerField.FB_TRANSACTION_TYPE,
    label: 'FB Transaction Type',
    value: taptapCustomerField.FB_TRANSACTION_TYPE
  },
  {
    key: taptapCustomerField.VIBER_TRANSACTION_TYPE,
    label: 'Viber Transaction Type',
    value: taptapCustomerField.VIBER_TRANSACTION_TYPE
  },
  {
    key: taptapCustomerField.ECOMM_TRANSACTION_TYPE,
    label: 'Ecomm Transaction Type',
    value: taptapCustomerField.ECOMM_TRANSACTION_TYPE
  },
  {
    key: taptapCustomerField.ADDRESS,
    label: 'Address',
    value: taptapCustomerField.ADDRESS
  },
  {
    key: taptapCustomerField.CREATED_AT,
    label: 'Created At',
    value: taptapCustomerField.CREATED_AT
  },
  {
    key: taptapCustomerField.BRANCH_RELEASED,
    label: 'Branch Released',
    value: taptapCustomerField.BRANCH_RELEASED
  },
  {
    key: taptapCustomerField.COURIER,
    label: 'Courier',
    value: taptapCustomerField.COURIER
  },
  {
    key: taptapCustomerField.SERVICE_TYPE,
    label: 'Service Type',
    value: taptapCustomerField.SERVICE_TYPE
  },
  {
    key: taptapCustomerField.TOTAL_AMOUNT,
    label: 'Total Amount',
    value: taptapCustomerField.TOTAL_AMOUNT
  },
  {
    key: taptapCustomerField.SHIPPING_FEE,
    label: 'Shipping Fee',
    value: taptapCustomerField.SHIPPING_FEE
  },
  {
    key: taptapCustomerField.IS_SHIPPING_FEE_PAID,
    label: 'Is Shipping Fee Paid?',
    value: taptapCustomerField.IS_SHIPPING_FEE_PAID
  },
  {
    key: taptapCustomerField.TRIAL_OS,
    label: 'Trial OS',
    value: taptapCustomerField.TRIAL_OS
  },
  {
    key: taptapCustomerField.REMARKS,
    label: 'Remarks',
    value: taptapCustomerField.REMARKS
  },
  {
    key: taptapCustomerField.IS_AGREE_MARKETING,
    label: 'Agree to Receive Marketing',
    value: taptapCustomerField.IS_AGREE_MARKETING
  },
  {
    key: taptapCustomerField.IS_AGREE_TNC,
    label: 'Agree to Terms and Conditions',
    value: taptapCustomerField.IS_AGREE_TNC
  },
  {
    key: taptapCustomerField.BUILD_TAG,
    label: 'Build Tag',
    value: taptapCustomerField.BUILD_TAG
  },
  {
    key: taptapCustomerField.KIND_OF_SALES_TRANSFER,
    label: 'Kind of Sales Transfer',
    value: taptapCustomerField.KIND_OF_SALES_TRANSFER
  },
  {
    key: taptapCustomerField.STATUS,
    label: 'Status',
    value: taptapCustomerField.STATUS
  },
  {
    key: taptapCustomerField.TYPE_OF_BUILD,
    label: 'Type of Build',
    value: taptapCustomerField.TYPE_OF_BUILD
  },
  {
    key: taptapCustomerField.WARRANTY_SLIP_NO,
    label: 'Warranty Slip No',
    value: taptapCustomerField.WARRANTY_SLIP_NO
  },
  {
    key: taptapCustomerField.WITH_UPGRADE,
    label: 'With Upgrade',
    value: taptapCustomerField.WITH_UPGRADE
  },
  {
    key: taptapCustomerField.DELETED_AT,
    label: 'Deleted At',
    value: taptapCustomerField.DELETED_AT
  },
  {
    key: taptapCustomerField.DELETED_BY,
    label: 'Deleted By',
    value: taptapCustomerField.DELETED_BY
  },
  {
    key: taptapCustomerField.IS_DELETED,
    label: 'Is Deleted',
    value: taptapCustomerField.IS_DELETED
  },
  {
    key: taptapCustomerField.CUSTOMERS_PAYMENTS,
    label: 'Customers Payments',
    value: taptapCustomerField.CUSTOMERS_PAYMENTS
  }
];
