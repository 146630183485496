import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { KachiPointToolBar } from './components/ToolBar';
import { KachiPointsList } from './components';
import { useKachiPoints } from 'src/hooks/kachi-points';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const KachiPointsPage = () => {
  const classes = useStyles();
  const {
    kachiPointsUserList,
    kachiPointsUserListLoading,
    kachiListMeta,
    getKachiPointsUserList
  } = useKachiPoints();

  const [page, setPage] = useState<number | undefined>(1);

  const handleChangePage = (page?: number) => {
    getKachiPointsUserList({ page: page });
    setPage(page);
  };

  const onHandleUserSearch = (keyword?: string) => {
    getKachiPointsUserList({ keyword });
  };

  useEffect(() => {
    getKachiPointsUserList();
  }, [getKachiPointsUserList]);

  return (
    <Page className={classes.root} title="Kachi Points">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <KachiPointToolBar
              onHandleSearchUser={(keyword?: string) =>
                onHandleUserSearch(keyword)
              }
            />
            {kachiPointsUserListLoading && (
              <Box>
                <LinearProgress />
              </Box>
            )}
          </Grid>

          <Grid item md={12}>
            <Card style={{ padding: '10px' }}>
              {kachiPointsUserList && kachiPointsUserList.length > 0 ? (
                kachiPointsUserList.map((kachiItem, index) => (
                  <div key={index}>
                    <KachiPointsList kachiList={kachiItem} />
                  </div>
                ))
              ) : (
                <Box style={{ display: 'grid', placeItems: 'center' }}>
                  <Typography>Nothing to show</Typography>
                </Box>
              )}
            </Card>
          </Grid>
          <Grid item md={12}>
            <Box
              style={{ display: 'grid', placeItems: 'center', padding: '1rem' }}
            >
              <Pagination
                count={kachiListMeta?.last_page}
                page={page}
                onChange={(e: any, p) => handleChangePage(p)}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default KachiPointsPage;
