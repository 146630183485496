export const currentTapTapVersion = 3; // Current version of the taptap customer module. This is for brute force refresh features.

export * from './taptap-courier';
export * from './taptap-source';
export * from './taptap-branch-released';
export * from './taptap-form-fields';
export * from './taptap-service-type';
export * from './taptap-trial-os';
export * from './taptap-transaction-types';
export * from './taptap-payment-methods';
export * from './taptap-filters';
