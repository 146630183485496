import React, { FC, memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';
import { PromotionalProductData } from 'src/redux/slices/promotional';
import DeleteIcon from '@material-ui/icons/Delete';
import usePromotional from 'src/hooks/promotional/use-promotional';

type Props = {
  promoProducts?: PromotionalProductData[];
  updateView?: boolean; //determine if the component is used for update
};

const Component: FC<Props> = ({ promoProducts, updateView = false }) => {
  const { removeProduct } = usePromotional();

  const handleDelete = (id?: number, updateView?: boolean) => {
    if (id) {
      removeProduct(id, updateView);
    }
  };

  return (
    <TableContainer style={{ maxHeight: 500 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell>Manufacturer</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promoProducts?.map((item, index) => (
            <TableRow
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? 'transparent' : '#f5f5f5' // Apply a background color to odd rows
              }}
            >
              <TableCell>{item?.name}</TableCell>
              <TableCell>{item?.manufacturer_name}</TableCell>
              <TableCell>{item?.category_name}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => handleDelete(item?.id, updateView)}
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PromoProductTable = memo(Component);
