import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  onTapTapCustomerPaymentAddFromTransactionPayments,
  taptapAddCustomerFields,
  taptapGetCustomerDetailsViaTransactionNo,
  taptapStatuses,
  transactionPaymentsToTapTapCustomerPaymentPayloadTransformer
} from 'src/redux/slices/taptap-customer';
import { TapTapCustomerData } from 'src/redux/slices/taptap-customer/types';
import { cloneDeep, isEmpty } from 'lodash';
import TapTapForm from './components/TapTapForm';
import { AlertDialog, LoaderBar, LoadingButton, Page } from 'src/components';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { SupabaseClientService } from 'src/services';
import { useSnackBar } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import {
  GetTransactionPaymentsResponse,
  GetTransactionsViaTransactionNoResponse,
  TemplateDetails
} from 'src/types';
import TapTapQuotationCard from './components/TapTapQuotationCard';
import SearchIcon from '@material-ui/icons/Search';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import { sleep } from 'src/utils';
import { TapTapPaymentsCard } from './components/TapTapPaymentsCard';
import { TapTapTransactionsCard } from './components/TapTapTransactionsCard';

interface FormValues {
  [key: string]: any; // could string, boolean, number, etc
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    paddingBottom: 20,
    paddingTop: 20
  },
  contactCard: {
    marginTop: 20
  }
}));

// TODO: Add to contants
const initialValues: FormValues = {
  [taptapCustomerField.STATUS]: taptapStatuses.FOR_PAYMENT,
  first_name: '',
  last_name: '',
  contact_no: '',
  address: '',
  source: '',
  online_sales_representative: '',
  fb_transaction_type: '',
  viber_transaction_type: '',
  ecomm_transaction_type: '',
  branch_released: '',
  courier: '',
  service_type: '',
  total_amount: 0,
  shipping_fee: 0,
  is_shipping_fee_paid: false,
  is_agree_tnc: true,
  trial_os: ''
};

const { actions: quotationActions } = slices.quotations;
const { actions: createCustomerServiceActions } = slices.customerService;
const { actions: transactionActions } = slices.transaction;
const { actions: transactionPaymentActions } = slices.transactionPayments;

const AddTapTapCustomer = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quotationItemsConcerns, setQuotationItemsConcerns] = useState<
    string[]
  >();
  const [quotationResponse, setQuotationResponse] = useState<TemplateDetails>();
  const [quotationProductsLoading, setQuotationProductsLoading] = useState<
    boolean
  >(false);

  const [transactionsInfoLoading, setTransactionsInfoLoading] = useState<
    boolean
  >(false);
  const [transactionNoInput, setTransactionNoInput] = useState<string>('');
  const [transactionInfoResponse, setTransactionInfoResponse] = useState<
    GetTransactionsViaTransactionNoResponse
  >();
  const [
    transactionPaymentsResponse,
    setTransactionPaymentsResponse
  ] = useState<GetTransactionPaymentsResponse>();

  const resetFormAlongWithOtherStates = () => {
    formik.resetForm();
    setQuotationItemsConcerns(undefined);
    setQuotationResponse(undefined);
    setTransactionNoInput('');
    setTransactionPaymentsResponse(undefined);
    setTransactionInfoResponse(undefined);
    setTransactionsInfoLoading(false);
  };

  const onInputOfTransactionNo = async () => {
    const transactionNo: string = transactionNoInput?.trim();
    setTransactionsInfoLoading(true);

    const { data, error } = await taptapGetCustomerDetailsViaTransactionNo(
      transactionNo
    );

    if (error) {
      console.error('Error Fetching Transaction Data', error);
      snackBar.show({
        severity: 'error',
        message:
          'Error Fetching Transaction Data From Live Transactions Manager'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    if (data?.length > 0) {
      snackBar.show({
        severity: 'error',
        message: 'Transaction Already Exist in Live Transactions Manager'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    const transactionInfoResp: any = unwrapResult(
      await dispatch(
        transactionActions.getTransactionViaTransactionNo(transactionNo)
      )
    );

    if (
      !transactionInfoResp?.success ||
      isEmpty(transactionInfoResp?.originalData)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Error Fetching Transaction Data'
      });
      resetFormAlongWithOtherStates();
      return;
    }
    if (transactionInfoResp?.originalData?.items?.length <= 0) {
      snackBar.show({
        severity: 'error',
        message: 'There is not even a product in this transaction?'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    const transactionInfo: GetTransactionsViaTransactionNoResponse =
      transactionInfoResp?.originalData;
    const transactionInfoItems = transactionInfo?.items || [];

    const allDeleted = transactionInfoItems.every(
      (item) => item.isDeleted === 1
    );

    if (allDeleted) {
      snackBar.show({
        severity: 'error',
        message: 'This transaction is already voided?'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    const transactionPaymentsResp = unwrapResult(
      await dispatch(
        transactionPaymentActions.getTransactionPaymentsThunk(transactionNo)
      )
    );

    setTransactionInfoResponse(transactionInfo);
    setTransactionPaymentsResponse(transactionPaymentsResp?.originalData);

    const newAddFormValues: FormValues = {
      [taptapCustomerField.INTERNAL_QUOTATION_ID]: null, // Syempre walang quotation id kasi direct na eh.
      [taptapCustomerField.FIRST_NAME]: transactionInfo?.customer?.first_name,
      [taptapCustomerField.LAST_NAME]: transactionInfo?.customer?.last_name,
      [taptapCustomerField.CONTACT_NO]: transactionInfo?.customer?.contact_no,
      [taptapCustomerField.ADDRESS]: transactionInfo?.customer?.address,
      [taptapCustomerField.REMARKS]: transactionInfo?.transaction_remarks
        ? `Automated Message From Transaction No: ${transactionInfo?.transaction_remarks}`
        : '',
      [taptapCustomerField.TOTAL_AMOUNT]: transactionInfo?.sub_total_price,
      [taptapCustomerField.INTERNAL_TRANSACTION_NO]:
        transactionInfo?.transaction_no,
      [taptapCustomerField.INTERNAL_CUSTOMER_ID]: transactionInfo?.customer?.id
    };

    if (transactionPaymentsResp?.originalData?.success) {
      newAddFormValues.balance =
        transactionPaymentsResp?.originalData?.total_balance || 0;
      newAddFormValues.paid_amount =
        transactionPaymentsResp?.originalData?.total_paid_amount || 0;
    } else {
      newAddFormValues.balance = transactionInfo?.sub_total_price;
      newAddFormValues.paid_amount = 0;
    }

    formik.setValues({ ...formik.values, ...newAddFormValues });
    setTransactionsInfoLoading(false);
  };

  const fetchQuotationProductsAvailability = async (
    quotationResponseArg?: TemplateDetails
  ) => {
    const response = unwrapResult(
      await dispatch(
        quotationActions.checkQuotationAvailabilityThunk({
          items: quotationResponseArg?.products || [],
          branch_id: quotationResponseArg?.branch_id
        })
      )
    );

    return response;
  };

  const onBranchFieldSetBasedOnQuotation = (
    quotationResponseArg: TemplateDetails
  ) => {
    const branchBasedOnQuotation = taptapAddCustomerFields
      .find((x) => x.key === taptapCustomerField.BRANCH_RELEASED)
      ?.options?.find(
        (x: any) => x.branch_id === quotationResponseArg?.branch_id
      )?.value;

    if (branchBasedOnQuotation) {
      formik.setFieldValue(
        taptapCustomerField.BRANCH_RELEASED,
        branchBasedOnQuotation
      );
    }
  };

  const fetchQuotationProducts = async (internal_quotation_id: number) => {
    setQuotationProductsLoading(true);
    const response = unwrapResult(
      await dispatch(
        quotationActions.getTemplateDetailsThunk({
          template_id: Number(internal_quotation_id)
        })
      )
    );

    if (!response?.success) {
      snackBar.show({
        severity: 'error',
        message: 'Error Fetching Quotation Data'
      });
      setQuotationProductsLoading(false);
      return;
    }
    if (response?.originalData?.data) {
      const origQuotationResponse = response?.originalData?.data;
      const QuoteWithChecker = await fetchQuotationProductsAvailability(
        origQuotationResponse
      );
      origQuotationResponse.products =
        QuoteWithChecker?.originalData?.quote_items;
      setQuotationResponse(origQuotationResponse);
      onBranchFieldSetBasedOnQuotation(origQuotationResponse);

      formik.setFieldValue('total_amount', origQuotationResponse?.total_amount);
    }

    setQuotationProductsLoading(false);
  };

  const setInitialFormValues = (
    someValuesAsideConstInitialValues: FormValues
  ) => {
    formik.setValues({
      ...initialValues,
      ...someValuesAsideConstInitialValues
    });

    if (someValuesAsideConstInitialValues?.internal_quotation_id) {
      fetchQuotationProducts(
        someValuesAsideConstInitialValues?.internal_quotation_id
      );
    }
  };

  const resetState = () => {
    formik.resetForm();
  };

  // When adding a taptap customer some fields have conditions that need to be met before they can appear
  // Here, what we do is remove the value of the field if the condition is not met
  const isSomeConditionalFieldsHaveUselessValues = () => {
    const currentFormikValues: any = cloneDeep(formik?.values);
    taptapAddCustomerFields?.forEach((field) => {
      if (field?.conditional) {
        const dependentValue = currentFormikValues[field.conditional.dependsOn];
        if (!field?.conditional?.value?.includes(dependentValue)) {
          formik.setFieldValue(field.key, initialValues[field.key]);
          currentFormikValues[field.key] = initialValues[field.key];
        }
      }
    });

    return currentFormikValues;
  };

  const validateQuotationStocks = async (newTapTapCustomerData: FormValues) => {
    if (newTapTapCustomerData?.internal_quotation_id && quotationResponse) {
      const productsFromQuotatationWithoutProductIds = quotationResponse?.products?.filter(
        (product) => !product?.product?.product_id
      );
      if (
        productsFromQuotatationWithoutProductIds &&
        productsFromQuotatationWithoutProductIds?.length > 0
      ) {
        const productNames = productsFromQuotatationWithoutProductIds
          .map((x) => x.product?.product_name)
          .join(',');
        snackBar.show({
          severity: 'error',
          message: `Some products from the quotation do not have product IDs. Are they preorders? ${productNames}`
        });
        return false;
      }
    }

    const checkerRes = await fetchQuotationProductsAvailability(
      quotationResponse
    );

    if (checkerRes?.success) {
      const resItems = checkerRes?.originalData?.quote_items || [];
      const hasWarning = resItems?.filter((x) => x.warning_note);

      if (hasWarning?.length) {
        snackBar.show({
          severity: 'error',
          message:
            'Some item(s) might be out of stock. Please check availability.'
        });

        const itemsWithConcerns = resItems?.map(
          (x) => x.product?.product_name || ''
        );
        setQuotationItemsConcerns(itemsWithConcerns);

        const clonedQuotationResponse = cloneDeep(quotationResponse);
        if (clonedQuotationResponse) {
          clonedQuotationResponse.products = resItems;
          setQuotationResponse(clonedQuotationResponse);
        }

        return false;
      }

      return true;
    }

    return false;
  };

  /**
   *
   * @param newCustomerData
   * @returns new customer id if successful api call
   */
  const createCustomerBasedOnQuotation = async (
    newCustomerData: FormValues
  ) => {
    if (newCustomerData?.internal_quotation_id && quotationResponse) {
      let internal_customer_values = {
        first_name: newCustomerData.first_name,
        middle_name: '',
        last_name: newCustomerData.last_name || '',
        email: '',
        address: newCustomerData.address || '',
        social_media: '',
        contact_no: newCustomerData.contact_no || '',
        notes: newCustomerData.remarks || ''
      };

      const createInternalCustomerResponse = unwrapResult(
        await dispatch(
          createCustomerServiceActions.createCustomerServiceThunk(
            internal_customer_values
          )
        )
      );

      const newInternalCustomerId =
        createInternalCustomerResponse?.originalData?.customer?.id;

      if (!newInternalCustomerId) {
        snackBar.show({
          severity: 'error',
          message: `Error creating internal system customer ${JSON.stringify(
            createInternalCustomerResponse?.message ||
              createInternalCustomerResponse?.errors
          )} `
        });
      }

      return newInternalCustomerId;
    }
  };

  const createTransactionViaTemplate = async (newCustomerData: FormValues) => {
    if (newCustomerData.internal_quotation_id && quotationResponse) {
      const createTransactionResponse = unwrapResult(
        await dispatch(
          transactionActions.createTransactionViaQuotationTemplateThunk({
            template_id: newCustomerData.internal_quotation_id,
            customer_id: newCustomerData.internal_customer_id
          })
        )
      );

      const internalTransactionNo =
        createTransactionResponse?.originalData?.data?.transaction_no;

      if (
        !internalTransactionNo ||
        !createTransactionResponse?.originalData?.success
      ) {
        const concerns: any =
          createTransactionResponse?.originalData?.data || [];

        if (concerns?.length > 0) {
          setQuotationItemsConcerns(concerns);
        }

        snackBar.show({
          severity: 'error',
          message: `Error creating internal system transaction. ${JSON.stringify(
            createTransactionResponse?.message
          )} ${JSON.stringify(concerns?.join(','))}`
        });
        return;
      }

      return internalTransactionNo;
    }
  };

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);
    let newTapTapCustomerData: TapTapCustomerData = values;

    if (isLoading) {
      snackBar.show({
        severity: 'error',
        message: 'Currently loading. Please wait.'
      });
      setIsLoading(false);
      return;
    }

    // Check if formik fields are valid
    if (formik?.errors && Object.keys(formik?.errors)?.length > 0) {
      setIsLoading(false);

      snackBar.show({
        severity: 'error',
        message: JSON.stringify(formik?.errors)
      });
      return;
    }

    // Check if there are conditional fields that have useless values
    const thereIsAConditionalFieldThatHasAUselessValue = isSomeConditionalFieldsHaveUselessValues();
    newTapTapCustomerData = thereIsAConditionalFieldThatHasAUselessValue;

    if (newTapTapCustomerData?.internal_quotation_id && quotationResponse) {
      /** Check if there are quotation items that are out of stock or preorder */
      const isQuotationValid = await validateQuotationStocks(
        newTapTapCustomerData
      );
      if (!isQuotationValid) {
        setIsLoading(false);
        return;
      }

      // Create a customer based on the quotation if there is a quotation
      const internalCustomerId = await createCustomerBasedOnQuotation(
        newTapTapCustomerData
      );
      if (!internalCustomerId) {
        setIsLoading(false);
        return;
      }
      // IMPORTANT!!! Set the internal_customer_id to the newTapTapCustomerData
      newTapTapCustomerData.internal_customer_id = internalCustomerId;

      // Create a transaction based on the quotation if there is a quotation
      const internalTransactionNo = await createTransactionViaTemplate(
        newTapTapCustomerData
      );
      // IMPORTANT!!! Set the internal_transaction_no to the newTapTapCustomerData
      newTapTapCustomerData.internal_transaction_no = internalTransactionNo;
      newTapTapCustomerData.balance = newTapTapCustomerData.total_amount;
    }

    // Next time do this as a util..
    if (!newTapTapCustomerData?.shipping_fee) {
      newTapTapCustomerData.shipping_fee = 0;
      formik.setFieldValue(taptapCustomerField.SHIPPING_FEE, 0);
    }
    if (!newTapTapCustomerData?.total_amount) {
      newTapTapCustomerData.total_amount = 0;
      formik.setFieldValue(taptapCustomerField.TOTAL_AMOUNT, 0);
    }

    // Tangina may ganitong logic pala.
    // If total_amount is more than 0 and balance is 0,
    // then set balance based on total_amount
    if (
      newTapTapCustomerData?.total_amount > 0 &&
      (newTapTapCustomerData?.balance === 0 || !newTapTapCustomerData?.balance)
    ) {
      newTapTapCustomerData.balance = newTapTapCustomerData.total_amount;
    }

    const { data, error } = await SupabaseClientService.from('customers')
      .insert({ ...newTapTapCustomerData })
      .select('id');

    if (error) {
      snackBar.show({
        severity: 'error',
        message: JSON.stringify(error)
      });
      setIsLoading(false);
      console.error(error);
      return;
    }

    // Check if may payments from transactions
    if (
      transactionPaymentsResponse &&
      transactionPaymentsResponse?.data &&
      transactionPaymentsResponse?.data?.length > 0
    ) {
      // Check if may INTERNAL_TRANSACTION_NO and data
      if (
        formik.values[taptapCustomerField.INTERNAL_TRANSACTION_NO] &&
        data?.[0]?.id
      ) {
        onTapTapCustomerPaymentAddFromTransactionPayments(
          transactionPaymentsResponse.data,
          {
            transaction_no:
              formik.values[taptapCustomerField.INTERNAL_TRANSACTION_NO],
            taptap_customer_id: data?.[0]?.id
          }
        );
      }
    }

    snackBar.show({
      severity: 'success',
      message: 'Customer added successfully'
    });

    if (data?.[0]?.id) {
      sleep(2000);
      navigate(`/app/customer-taptap/${data?.[0]?.id}`);
    }

    setIsLoading(false);
    resetState();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  const taptapCustomerToBePayments = useMemo(() => {
    const data = transactionPaymentsToTapTapCustomerPaymentPayloadTransformer(
      transactionPaymentsResponse?.data,
      {
        transaction_no: formik.values?.internal_transaction_no,
        taptap_customer_id: 0
      }
    );

    return data || [];
  }, [formik.values.internal_transaction_no, transactionPaymentsResponse]);

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);

    const paramsKeyValuePair: any = {};
    params.forEach((value, key) => {
      paramsKeyValuePair[key] = value;
    });

    const validKeys = taptapAddCustomerFields.map((item) => item.key);

    // Filter the data object to include only valid keys
    const filteredData = Object.keys(paramsKeyValuePair)
      .filter((key) => validKeys.includes(key)) // Keep keys that are in the schema
      .reduce((acc: any, key) => {
        acc[key] = paramsKeyValuePair[key];
        return acc;
      }, {});

    setInitialFormValues(filteredData);
    // formik.setValues({ ...initialValues, ...filteredData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Add to Live Customers Manager">
      <Container
        maxWidth={false}
        style={{
          opacity:
            isLoading || quotationProductsLoading || transactionsInfoLoading
              ? 0.3
              : 1
        }}
      >
        {formik?.values?.is_deleted && (
          <Alert
            hidden
            severity="error"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Typography variant="h3">
              CUSTOMER IS ALREADY DELETED. YOU CANNOT EDIT THIS CUSTOMER
              ANYMORE.
            </Typography>
          </Alert>
        )}
        <LoaderBar isLoading={isLoading} />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom variant="h3">
            Add Live Customer{' '}
            {`- ${formik?.values?.first_name} ${formik?.values?.last_name}`}
          </Typography>
        </Box>

        {!formik?.values?.internal_quotation_id && (
          <Card elevation={5} style={{ marginTop: 12, marginBottom: 16 }}>
            <CardHeader title="ONLY ENTER EXISTING TRANSACTION NO IF IT STILL NOT LINKED TO A CURRENT REALTIME CUSTOMER" />
            <Divider />
            <CardContent style={{ padding: 20 }}>
              <TextField
                size="small"
                fullWidth
                id={'transaction_no'}
                variant="outlined"
                value={transactionNoInput}
                label="Existing Transaction No"
                onChange={(e) => setTransactionNoInput(e.target.value?.trim())}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    onInputOfTransactionNo();
                  }
                }}
                helperText="Enter an existing transaction no to prefill the form"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
              />
            </CardContent>
            <LoaderBar isLoading={transactionsInfoLoading} />
          </Card>
        )}

        {formik?.values?.internal_transaction_no && transactionInfoResponse && (
          <TapTapTransactionsCard
            purpose="preview"
            onRefreshTransaction={() => {}}
            isLoading={transactionsInfoLoading}
            transactionInfo={transactionInfoResponse}
          />
        )}

        {transactionPaymentsResponse &&
        transactionPaymentsResponse?.data && // FUCK THIS TYPESCRIPT
        transactionPaymentsResponse?.data?.length > 0 ? (
          <TapTapPaymentsCard
            purpose="preview"
            isLoading={false}
            data={taptapCustomerToBePayments}
            transactionNo={formik?.values?.internal_transaction_no}
            currentFormikValues={formik.values}
          />
        ) : null}

        {formik?.values?.internal_quotation_id && quotationResponse ? (
          <TapTapQuotationCard
            initialCollapse
            quotationResponse={quotationResponse}
            internalQuotationId={formik?.values?.internal_quotation_id}
            quotationProductsLoading={quotationProductsLoading}
            fetchQuotationProducts={() =>
              fetchQuotationProducts(formik?.values?.internal_quotation_id)
            }
          />
        ) : null}

        <Box mt={3}>
          <TapTapForm
            formFields={taptapAddCustomerFields}
            formikHookRef={formik}
          />
        </Box>

        <LoadingButton
          fullWidth
          onClick={async () => {
            const validateForm = await formik.validateForm();
            if (validateForm && Object.keys(validateForm).length > 0) {
              const errorFields = Object.keys(validateForm).join(', ');
              const message = `Please check the following fields: ${errorFields}`;
              snackBar.show({ severity: 'error', message, useSound: true });
              return;
            }

            if (isLoading) {
              snackBar.show({
                severity: 'error',
                message: 'Currently loading. Please wait.'
              });
              return;
            }

            formik.handleSubmit();
          }}
          color="primary"
          variant="contained"
          height={50}
          disabled={isLoading}
          loading={isLoading}
          title="Add Customer"
        />
      </Container>

      <AlertDialog
        title="Some items are out of stock"
        isVisible={
          quotationItemsConcerns && quotationItemsConcerns?.length > 0 // FUCK THIS SHITTY TYPESCRIPT
            ? true
            : false
        }
        subTitle={` ${quotationItemsConcerns?.join(', \r !!! ')}`}
        handleClose={() => setQuotationItemsConcerns(undefined)}
      />
    </Page>
  );
};

export default AddTapTapCustomer;

const formSchema = yup.object().shape({
  [taptapCustomerField.FIRST_NAME]: yup
    .string()
    .required('First Name is required'),
  [taptapCustomerField.BRANCH_RELEASED]: yup
    .string()
    .required('Branch is required'),
  [taptapCustomerField.SOURCE]: yup.string().required('Source is required'),
  [taptapCustomerField.COURIER]: yup.string().required('Courier is required'),
  [taptapCustomerField.CONTACT_NO]: yup
    .string()
    .nullable()
    .matches(/\d+$/, 'Contact number must be numeric'),
  [taptapCustomerField.IS_AGREE_TNC]: yup
    .boolean()
    .required('You must accept the terms and conditions')
    .oneOf([true], 'You must accept the terms and conditions'),
  [taptapCustomerField.TOTAL_AMOUNT]: yup
    .number()
    .required('Total Amount is required')
    .typeError('Total Amount must be a number')
    .positive('Total Amount must be greater than zero')
});
