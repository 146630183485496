import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button
} from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  errs: string[];
  open: boolean;
  closeDialog: () => void;
}

// use with useErrorDialog hook
export const ErrorDialog: FC<Props> = ({ errs, open, closeDialog }) => { return errs ? ( <Dialog open={open}>
      <DialogTitle style={{ background: '#fdecea', color: '#a13c46' }}>
        Errors
      </DialogTitle>
      <DialogContent style={{ background: '#fdecea', color: '#a13c46' }}>
        {errs.map((i) => (
          <Typography key={i}>{i}</Typography>
        ))}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
          background: '#fdecea',
          color: '#a13c46'
        }}
      >
        <Button color="secondary" onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
