import React, { FC, memo, useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { SearchKeyword } from 'src/components/filters';
import { CategoryListDropdown } from 'src/components/filters/CategoryDropdown';
import { BranchListDropDown } from 'src/components/dropdown';
import { DatePickerRangeComponent } from 'src/components';
import { dateToday, transformerDateField } from 'src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PopoverNotification } from 'src/components/popover';
import { debounce, isEmpty } from 'lodash';
import { StockFilter } from 'src/enums/fast-moving-products';

type onKeyDownAction = 'search' | 'category';

interface Props {
  loadingData: boolean;
  initialBranchIds?: number[];
  categoryRequire: boolean;
  dateRangeRequire: boolean;
  onApplyFilter: () => void;
  onHandleSearchKeyword: (keyword?: string) => void;
  onHandleCategoryId: (categortId: number) => void;
  onHandleBranch: (branchIds?: number[]) => void;
  onHandleDateFrom: (dateFrom?: string | undefined) => void;
  onHandleDateTo: (dateTo?: string | undefined) => void;
  onHandleErrorClose: () => void;
  onChangeStatusFilter: (val: StockFilter) => void;
}

const component: FC<Props> = ({
  loadingData,
  initialBranchIds,
  categoryRequire,
  dateRangeRequire,
  onApplyFilter,
  onHandleSearchKeyword,
  onHandleCategoryId,
  onHandleBranch,
  onHandleDateFrom,
  onHandleDateTo,
  onHandleErrorClose,
  onChangeStatusFilter
}) => {
  const categoryFieldRef = useRef(null);
  const dateRangeFieldRef = useRef(null);

  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [isClearSearchKeyword, setIsClearSearchKeyword] = useState<boolean>(
    false
  );
  const [isCategory, setIsCategory] = useState<boolean>(false); //check if category is set
  const [isSearchKeyword, setIsSearchKeyword] = useState<string>(); //check if keyword is set
  const [isKeyDown, setIsKeyDown] = useState<boolean>(false); //to avoid spamming keys

  const stockFilterOptions = [
    { name: 'ALL', value: StockFilter.All },
    { name: 'HAS AVAILABLE STOCK', value: StockFilter.HasStocks },
    { name: 'NO STOCK', value: StockFilter.NoStocks }
  ];

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | undefined, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;

      if (field === 'to') {
        setToDate(transformedDate);
        onHandleDateTo(transformedDate);
      }
      if (field === 'from') {
        setFromDate(transformedDate);
        onHandleDateFrom(transformedDate);
      }
    },
    [onHandleDateFrom, onHandleDateTo]
  );

  const handleCategoryChange = (categoryId: number) => {
    onHandleCategoryId(categoryId);
    setIsClearSearchKeyword(true);
    if (categoryId) {
      setIsCategory(true);
    } else {
      setIsCategory(false);
    }
  };

  const onChangeKeyword = (keyword?: string) => {
    onHandleSearchKeyword(keyword);
    setIsSearchKeyword(keyword);
    if (isEmpty(keyword)) {
      setIsClearSearchKeyword(false);
    }
  };

  const onHandleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    action?: onKeyDownAction
  ) => {
    //no category no onkeydown event
    if (!isCategory && action === 'category') {
      return;
    }
    //no keyword no onkeydown event
    if (!isSearchKeyword && action === 'search') {
      return;
    }
    if (event.key === 'Enter' && !loadingData && !isKeyDown) {
      setIsKeyDown(true);
      debounce(() => {
        onApplyFilter();
        setIsKeyDown(false);
      }, 500)();
    }
  };

  return (
    <Card style={{ padding: '2rem' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <div onKeyDown={(event) => onHandleKeyDown(event, 'search')}>
            <SearchKeyword
              isClearEvent={isClearSearchKeyword}
              onHandleSearchKeyword={(keyword: string) =>
                onChangeKeyword(keyword)
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <div
            ref={categoryFieldRef}
            onKeyDown={(event) => onHandleKeyDown(event, 'category')}
          >
            <CategoryListDropdown
              onHandleCategories={(categoryId: number) =>
                handleCategoryChange(categoryId)
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={2}>
          <BranchListDropDown
            defaultValue={initialBranchIds}
            isElevated={false}
            multiple
            paperSpace={0}
            onHandleBranchChange={(branchIds?: number[]) =>
              onHandleBranch(branchIds)
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="Availability">Availability</InputLabel>
            <Select
              defaultValue={StockFilter.All}
              onChange={(e: any) => onChangeStatusFilter(e.target.value)}
              label="Availability"
            >
              {stockFilterOptions.map((i) => (
                <MenuItem key={i.name} value={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column'
          }}
        >
          <div ref={dateRangeFieldRef}>
            <DatePickerRangeComponent
              title="Date Sold Range"
              fromDateMax={dateToday()}
              fromDateValue={fromDate || null}
              toDateValue={toDate || null}
              dateToLabel="End Date"
              dateFromLabel="Start Date"
              toDateMin={fromDate}
              onChangeToDate={(date) => onChangedDate(date, 'to')}
              onChangeFromDate={(date) => onChangedDate(date, 'from')}
            />
          </div>
          <Box sx={{ marginTop: '1.5rem' }}>
            <Button
              variant="contained"
              color={'primary'}
              onClick={onApplyFilter}
              disabled={loadingData}
            >
              Apply Filter
            </Button>
          </Box>
        </Box>
      </Grid>

      <PopoverNotification
        severity={'error'}
        textInfo="Category is required"
        isOpen={categoryRequire}
        anchorRef={categoryFieldRef.current ?? undefined}
        onHandleClose={onHandleErrorClose}
      />
      <PopoverNotification
        severity={'error'}
        textInfo="Sold date from and to is required"
        isOpen={dateRangeRequire}
        anchorRef={dateRangeFieldRef.current ?? undefined}
        onHandleClose={onHandleErrorClose}
      />
    </Card>
  );
};

export const FastMovingProductToolBar = memo(component);
