import React, { useEffect, useMemo, useState } from 'react';
import ListIcon from '@material-ui/icons/List';
import GetAppIcon from '@material-ui/icons/GetApp';
import { LoaderBar, Page } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import {
  Box,
  Card,
  Table,
  makeStyles,
  Container,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { usePermissions, useSnackBar } from 'src/hooks';
import InventoryToolbar from './component/InventoryToolbar';
import InventoryFilter from './component/InventoryFilter';
import {
  InventoryColumnsFilterModal,
  InventoryLocalFilterModal,
  InventoryTableHeader
} from './component';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { InventoryTableBody } from './component/InventoryTableBody';
import { Inventory, InventoryColumn, ManufacturerOption } from 'src/types';
import useUserInfo from 'src/hooks/user/use-user-info';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import { Alert, Pagination } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { unwrapResult } from '@reduxjs/toolkit';
import { downloadCSV } from 'src/utils';

const { actions: manufacturerActions } = slices.manufacturer;

const {
  actions: inventoryActions,
  selectors: inventorySelectors
} = slices.inventory;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  columnBtn: {
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    display: 'flex'
  },
  toCsvText: {
    color: theme.palette.primary.main
  },
  toCsvBtn: { marginLeft: theme.spacing(2) }
}));

const InventoryView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const inventoryResponse = useAppSelector(
    inventorySelectors.selectInventoryResponse
  );
  const items = useAppSelector(inventorySelectors.selectInventoryResponseItems);
  const meta = useAppSelector(inventorySelectors.selectMeta);
  const isSummaryFetched = useAppSelector(
    inventorySelectors.selectIsSummaryFetched
  );
  const status = useAppSelector(inventorySelectors.selectStatus);
  const permissions = usePermissions();
  const { getUserDetails, userBranchOptions, allBranchIDs } = useUserInfo();
  const { selectedBranchIDs, setSelectedBranchIDs } = useBranchInfo();

  const [manufacturers, setManufacturers] = useState<ManufacturerOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [selectedColumnFilter, setSelectedColumnFilter] = useState<
    InventoryColumn
  >();

  const hasStatus = useMemo(() => {
    return !!status && !!status[0];
  }, [status]);

  const handleSetManufacturer = (id?: number) => {
    if (id) {
      dispatch(
        inventoryActions.updateManufacturerFilter({
          manufacturer_id: id
        })
      );
    } else {
      dispatch(
        inventoryActions.updateManufacturerFilter({ manufacturer_id: null })
      );
    }
  };

  const handleExport = async () => {
    // any as bandaid solution to ts error
    const res: any = unwrapResult(
      await dispatch(inventoryActions.getInventoryForCSVThunk())
    );
    if (res.success) {
      const formattedData = res.originalData.items.map((item: Inventory) => ({
        branch_id: item?.branch_id,
        branch_name: item?.branch_name,
        id: item?.id,
        status: item?.status,
        dr_no: item?.dr_no,
        category_id: item?.product_type,
        manufacturer_id: item?.manufacturer_id,
        encoded_by: item?.encoded_by,
        released_by: item?.released_by,
        transaction_no: item?.transaction_no,
        ec_order_id: null,
        product_name: item?.product_name,
        sku: item?.sku,
        serial_no: item?.serial_no,
        quantity: item?.quantity,
        customer_name: item?.customer_name,
        dealers_price: item?.dealers_price,
        retail_price: item?.retail_price,
        supplier_name: item?.supplier_name,
        date_sold: item?.date_sold,
        date_purchased: item?.date_purchased,
        date_encoded: item?.date_encoded,
        ...item
      }));

      downloadCSV(formattedData, 'inventory');
    }
  };

  const getManufacturers = async (keyword?: string) => {
    const getManufacturersResponse = unwrapResult(
      await dispatch(manufacturerActions.getManufacturersThunk({ keyword }))
    );
    if (getManufacturersResponse?.success) {
      const manufacturers =
        getManufacturersResponse?.originalData?.manufacturers;
      let manufacturerOptions = manufacturers.map((i) => ({
        label: i.name,
        id: i.id
      }));
      setManufacturers(manufacturerOptions);
      return;
    } else {
      console.error('Failed to get Manufacturers');
      return;
    }
  };

  const onHandlePageChange = (event: any, page: number) => {
    setIsLoading(true);
    dispatch(inventoryActions.updatePageFilter({ page: page }));
    dispatch(inventoryActions.getInventoryThunk()).finally(() =>
      setIsLoading(false)
    );
  };

  const handleBranchChange = (v: number[]) => {
    if (v[0]) {
      setSelectedBranchIDs(v);

      dispatch(
        inventoryActions.updateBranchFilter({
          branch_ids: v
        })
      );
      //   set all if no value has no items
    } else {
      setSelectedBranchIDs(allBranchIDs);

      dispatch(
        inventoryActions.updateBranchFilter({
          branch_ids: allBranchIDs
        })
      );
    }
  };

  const getInventory = async (val: string) => {
    setIsLoading(true);
    dispatch(inventoryActions.updatePageFilter({ page: 1 }));
    dispatch(inventoryActions.updateActionFilter({ action: val }));
    dispatch(inventoryActions.getInventoryThunk()).finally(() =>
      setIsLoading(false)
    );
  };

  const clearColumnsFilter = () => {
    dispatch(inventoryActions.clearLocalFilter());
  };

  const onColumnFilterClick = (column: InventoryColumn) => {
    setSelectedColumnFilter(column);
  };

  useEffect(() => {
    if (allBranchIDs[0]) {
      handleBranchChange(allBranchIDs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBranchIDs]);

  useEffect(() => {
    getManufacturers();
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inventoryResponse?.success) {
      snackBar.show({
        severity: 'success',
        message: inventoryResponse?.message
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryResponse]);

  return (
    <Page className={classes.root} title="Inventory">
      <Container maxWidth={false}>
        <InventoryFilter
          hasStatus={hasStatus}
          userBranchOptions={userBranchOptions}
          manufacturers={manufacturers}
          isLoading={isLoading}
          selectedBranchIDs={selectedBranchIDs}
          onApplyFilterPress={getInventory}
          handleBranchChange={handleBranchChange}
          handleSetManufacturer={handleSetManufacturer}
        />
        <Grid>
          {!isEmpty(inventoryResponse?.errors) ? (
            <Alert severity="error">
              {Object.values(inventoryResponse?.errors || {}).map(
                (errorMessage, index) => (
                  <Typography key={index} variant="subtitle2">
                    {'-' + errorMessage}
                  </Typography>
                )
              )}
            </Alert>
          ) : null}
          {!hasStatus && (
            <Alert severity="error">
              <Typography variant="subtitle2">
                Please select atleast one status
              </Typography>
            </Alert>
          )}
        </Grid>
        {permissions?.canViewInventoryTotal ? <InventoryToolbar /> : null}
        <div className={classes.columnBtn}>
          {isSummaryFetched && (
            <Button
              disabled={!hasStatus}
              variant="outlined"
              endIcon={<ListIcon />}
              onClick={() => getInventory('list')}
              color="primary"
            >
              Show List
            </Button>
          )}
          <Box>
            <Button
              variant="outlined"
              endIcon={<ClearIcon />}
              onClick={clearColumnsFilter}
              color="secondary"
            >
              Clear Columns Filter
            </Button>
            <Button
              className={classes.toCsvBtn}
              variant="outlined"
              endIcon={<ViewWeekIcon />}
              onClick={() => setFilterVisible(!filterVisible)}
              color="primary"
            >
              Columns
            </Button>
            {items?.length ? (
              <Button
                className={classes.toCsvBtn}
                variant="outlined"
                endIcon={<GetAppIcon />}
                color="primary"
                onClick={handleExport}
              >
                Export to CSV
              </Button>
            ) : null}
          </Box>
        </div>
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Card>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table size="small">
                  <InventoryTableHeader
                    onColumnFilterClick={onColumnFilterClick}
                  />
                  <InventoryTableBody manufacturers={manufacturers} />
                </Table>
                {items?.length ? (
                  <Container
                    style={{
                      padding: '1em',
                      display: 'flex',
                      justifyContent: 'end'
                    }}
                  >
                    <Pagination
                      disabled={!hasStatus}
                      count={meta?.last_page}
                      page={meta?.current_page}
                      onChange={onHandlePageChange}
                    />
                  </Container>
                ) : null}
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
      <InventoryColumnsFilterModal
        visible={filterVisible}
        onHandleClose={() => setFilterVisible(false)}
      />
      {selectedColumnFilter ? (
        <InventoryLocalFilterModal
          visible={selectedColumnFilter ? true : false}
          onHandleClose={() => setSelectedColumnFilter(undefined)}
          columnKey={selectedColumnFilter || 'product_name'}
        />
      ) : null}
    </Page>
  );
};

export default InventoryView;
