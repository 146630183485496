import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { formatCurrency } from 'src/utils';

interface Props {
  amount: number;
}

const styles = StyleSheet.create({
  text: { fontWeight: 'bold' },
  image: {
    height: '5.5px',
    width: '5.5px',
    marginTop: 2,
    marginHorizontal: 2
  },
  container: { flexDirection: 'row' }
});

// todo: add props to modify text and container style in the future if needed
// intended to be global reusable component for react pdf currency
export const RenderPDFCurrency = ({ amount }: Props) => {
  return (
    <View style={[styles.container]}>
      <Image
        style={[styles.image, { paddingLeft: 2 }]}
        src="/static/images/icons/ico-php.png"
      />
      <Text style={styles.text}>
        {`${formatCurrency(amount, '', 2, { removeCurrency: true })}`}
      </Text>
    </View>
  );
};
