import { PCWorthBranchesEnum } from 'src/enums/branches';

export const taptapBranchReleased = {
  EARNSHAW: 'EARNSHAW',
  LPC: 'LPC',
  EXPERIENCE: 'EXPERIENCE'
};

export const taptapOptionsBranchReleased = [
  {
    key: 'earnshaw',
    label: taptapBranchReleased.EARNSHAW,
    value: taptapBranchReleased.EARNSHAW,
    branch_id: PCWorthBranchesEnum.Earnshaw
  },
  {
    key: 'lpc',
    label: taptapBranchReleased.LPC,
    value: taptapBranchReleased.LPC,
    branch_id: PCWorthBranchesEnum.LPC
  },
  {
    key: 'experience',
    label: taptapBranchReleased.EXPERIENCE,
    value: taptapBranchReleased.EXPERIENCE
    // TODO: Add branch_id 
  }
];
