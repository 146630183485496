export const taptapTransactionTypes = {
  FACEBOOK_CHAT: 'Facebook Chat',
  VIBER_CHAT: 'Viber Chat',
  WEBSITE_CHAT: 'Website Chat',
  WALK_IN: 'Walk In'
};

export const taptapOptionsFBTransactionType = [
  {
    key: 'facebook_chat',
    label: taptapTransactionTypes.FACEBOOK_CHAT,
    value: taptapTransactionTypes.FACEBOOK_CHAT
  },
  {
    key: 'walk_in',
    label: taptapTransactionTypes.WALK_IN,
    value: taptapTransactionTypes.WALK_IN
  }
];

export const taptapOptionsViberTransactionType = [
  {
    key: 'viber_chat',
    label: taptapTransactionTypes.VIBER_CHAT,
    value: taptapTransactionTypes.VIBER_CHAT
  },
  {
    key: 'walk_in',
    label: taptapTransactionTypes.WALK_IN,
    value: taptapTransactionTypes.WALK_IN
  }
];

export const taptapOptionsEcommTransactionType = [
  {
    key: 'website_chat',
    label: taptapTransactionTypes.WEBSITE_CHAT,
    value: taptapTransactionTypes.WEBSITE_CHAT
  },
  {
    key: 'facebook_chat',
    label: taptapTransactionTypes.FACEBOOK_CHAT,
    value: taptapTransactionTypes.FACEBOOK_CHAT
  },
  {
    key: 'walk_in',
    label: taptapTransactionTypes.WALK_IN,
    value: taptapTransactionTypes.WALK_IN
  }
];
