import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { AlternatingColorTableRow, LoaderBar } from 'src/components';
import { formatCurrency } from 'src/utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import { TemplateDetails } from 'src/types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { colors } from 'src/constants';

interface Props {
  quotationResponse?: TemplateDetails;
  internalQuotationId: number;
  quotationProductsLoading: boolean;
  fetchQuotationProducts: () => void;
  initialCollapse: boolean; // If visible ba sa start or hidden
}

const TapTapQuotationCard = ({
  quotationResponse,
  internalQuotationId,
  quotationProductsLoading,
  fetchQuotationProducts,
  initialCollapse
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(initialCollapse);

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader
        avatar={
          <Button
            color="primary"
            variant="text"
            onClick={() => setIsExpanded((prev) => !prev)}
            startIcon={isExpanded ? <ExpandLess /> : <ExpandMoreIcon />}
          />
        }
        action={
          isExpanded && (
            <Button
              startIcon={<RefreshIcon />}
              variant="outlined"
              color="primary"
              onClick={fetchQuotationProducts}
              style={{ justifyContent: 'center' }}
            >
              Sync Quotation Data
            </Button>
          )
        }
        // title={`Quotation Information`}
        subheader={
          <div>
            <Typography variant="h6">
              Quotation Total: {formatCurrency(quotationResponse?.total_amount)}
            </Typography>
            <Typography variant="caption">{`Quotation ID: ${internalQuotationId}. Created By: ${quotationResponse?.created_by}. Last updated on ${quotationResponse?.updated_date}`}</Typography>
          </div>
        }
      />
      <LoaderBar isLoading={quotationProductsLoading} />
      <Divider />

      <Collapse in={isExpanded}>
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotationResponse?.products?.map((product) => (
                  <AlternatingColorTableRow
                    hover
                    key={product?.product?.product_id}
                  >
                    <TableCell style={{ maxWidth: 100 }}>
                      {product?.quantity}
                    </TableCell>
                    <TableCell
                      style={{
                        maxWidth: 700,
                        color: !product?.product?.product_id || product?.warning_note
                          ? colors.error
                          : 'inherit'
                      }}
                    >
                      {product?.warning_note && `${product?.warning_note}: `}

                      {!product?.product?.product_id &&
                        'Pre-order is not allowed here: '}
                      {product?.product?.product_name}
                    </TableCell>
                    <TableCell style={{ maxWidth: 200 }}>
                      {formatCurrency(
                        (product?.product?.retail_price || 0) *
                          (product?.quantity || 1)
                      )}
                    </TableCell>
                  </AlternatingColorTableRow>
                ))}

                <TableRow>
                  <TableCell align="right" colSpan={2}>
                    <Typography variant="h6">Quotation Total:</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography variant="h5">
                      {formatCurrency(quotationResponse?.total_amount)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default TapTapQuotationCard;
