import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  DatePickerRangeComponent,
  DragAndDropImgUpload,
  Page,
  SelectImageButton
} from 'src/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  convertImageFileToBase64,
  dateToday,
  transformerDateField
} from 'src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { PromoProductListings } from '../component/PromoProductListing';
import usePromotional from 'src/hooks/promotional/use-promotional';
import { ErrorDialog } from 'src/components/error/ErrorDialog';
import usePrompt from 'src/utils/navigation-prompt';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { PromoContentDialog } from '../component/PromoContentDialog';
import { useSnackBar } from 'src/hooks';
import { isBefore } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const PromotionalDetailPage = () => {
  const classes = useStyles();
  const {
    isLoading,
    initialPromoProductListings,
    errMsgArr,
    showErrMsg,
    initialPromotionalDetails,
    hasPromoContent,
    removedProductIds,
    decodedHtmlPromoContent,
    setShowErrMsg,
    updatePromotionalDetails,
    getPromoDetails,
    getPromotionalProductCreate
  } = usePromotional();
  const isMobile = useIsMobile();
  const snackbar = useSnackBar();

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [banner, setBanner] = useState<any>();
  const [badgeUrl, setBadgeUrl] = useState<string>('');
  const [isPromoContentOpen, setIsPromoContentOpen] = useState<boolean>(false);
  const [errHelper, setErrHelper] = useState<string>('');
  const [isPromoExpired, setIsPromoExpired] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      title: initialPromotionalDetails?.title || '',
      banner_url: initialPromotionalDetails?.banner_url || '',
      badge_url: initialPromotionalDetails?.badge_url || '',
      start_date: initialPromotionalDetails?.start_date || '',
      end_date: initialPromotionalDetails?.end_date || '',
      is_published: initialPromotionalDetails?.is_published === 1 ? true : false
    },
    validationSchema,
    onSubmit: () => {},
    enableReinitialize: true
  });

  const isFormDirty = formik.dirty; //check if there is changes in  form, no need to memoize
  const hasFormErrors = useMemo(() => Object.keys(formik.errors).length > 0, [
    formik.errors
  ]); //check if there is errors in any fields

  const disableUpdate = useMemo(() => {
    const isPromoNotTouched = !isFormDirty;

    return (
      isLoading || isPromoNotTouched || isPromoExpired || !isEmpty(errHelper)
    );
  }, [isFormDirty, isLoading, isPromoExpired, errHelper]);

  const disablePromotionalProductsUpdate = useMemo(() => {
    const isPromoProductsEmpty =
      (isEmpty(initialPromoProductListings?.data) && //this check state changes on products
        isEmpty(removedProductIds)) ||
      (isEmpty(initialPromotionalDetails?.products) && //both current and new product is empty
        isEmpty(initialPromoProductListings?.data));

    return isPromoProductsEmpty;
  }, [
    initialPromoProductListings.data,
    initialPromotionalDetails.products,
    removedProductIds
  ]);

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | undefined, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize to midnight for date-only comparison

      setErrHelper('');

      if (field === 'to') {
        if (transformedDate && new Date(transformedDate) < today) {
          setErrHelper("The 'to' date cannot be earlier than today's date.");
          return; // Prevent setting the invalid date
        }

        if (
          transformedDate &&
          fromDate &&
          new Date(transformedDate) < new Date(fromDate)
        ) {
          setErrHelper('The end date cannot be earlier than the start date.');
          return;
        }

        setToDate(transformedDate);
        formik.setFieldValue('end_date', transformedDate);
        setIsPromoExpired(false);
      }

      if (field === 'from') {
        if (
          transformedDate &&
          toDate &&
          new Date(transformedDate) > new Date(toDate)
        ) {
          setErrHelper('The start date cannot be later than the end date.');
          return; // Prevent setting the invalid date
        }

        setFromDate(transformedDate);
        formik.setFieldValue('start_date', transformedDate);
      }
    },
    [formik, fromDate, toDate]
  );

  const handleChangePublish = (value: boolean) => {
    setIsPublish(value);
    formik.setFieldValue('is_published', value);
  };

  const onChangeBanner = useCallback(
    async (img: File[]) => {
      if (img && img?.length > 0) {
        const firstFile = img[0];
        const imgInTxt: any = await convertImageFileToBase64(firstFile);
        setBanner(imgInTxt);
        formik.setFieldValue('banner_url', imgInTxt ? imgInTxt : '');
      }
    },
    [formik]
  );

  const onChangeBadge = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setBadgeUrl(imgInBase64);
      formik.setFieldValue('badge_url', imgInBase64);
    }
  };

  usePrompt(
    `There are some unsaved changes. Are you sure you want to leave?`,
    isFormDirty
  );

  const getUpdatedValue = useCallback(
    (
      field:
        | 'title'
        | 'banner_url'
        | 'badge_url'
        | 'start_date'
        | 'end_date'
        | 'is_published'
    ) =>
      initialPromotionalDetails?.[field] !== formik.values[field]
        ? formik.values[field]
        : undefined,
    [initialPromotionalDetails, formik.values]
  );

  const onUpdatePromotional = useCallback(async () => {
    const initialPublishState =
      initialPromotionalDetails?.is_published === 1 ? true : false;
    if (!formik.values.end_date || !formik.values.start_date) {
      setErrHelper('Start date and end date is required');
      return;
    }

    if (errHelper) {
      setErrHelper('Please check the fields for error before saving');
      return;
    }

    if (!disablePromotionalProductsUpdate) {
      snackbar.show({
        severity: 'warning',
        message: 'Please save first your changes in promotional products'
      });
      return;
    }

    if (hasFormErrors) {
      return;
    }

    if (!formik.values.title) {
      return;
    }
    const updatedParams = {
      title: getUpdatedValue('title')?.toString(),
      banner_url: getUpdatedValue('banner_url')?.toString(),
      badge_url: getUpdatedValue('badge_url')?.toString(),
      start_date: getUpdatedValue('start_date')?.toString(),
      end_date: getUpdatedValue('end_date')?.toString(),
      is_published:
        initialPublishState === formik.values.is_published
          ? undefined
          : isPublish
    };
    updatePromotionalDetails({ ...updatedParams }, 'info');
  }, [
    initialPromotionalDetails.is_published,
    formik.values.end_date,
    formik.values.start_date,
    formik.values.title,
    formik.values.is_published,
    errHelper,
    disablePromotionalProductsUpdate,
    hasFormErrors,
    getUpdatedValue,
    isPublish,
    updatePromotionalDetails,
    snackbar
  ]);

  const onUpdatePromotionalProducts = useCallback(() => {
    const resultGetPromoProduct = getPromotionalProductCreate();

    resultGetPromoProduct.then((result) => {
      if (
        isEmpty(initialPromoProductListings?.data) &&
        isEmpty(removedProductIds)
      ) {
        return;
      }
      updatePromotionalDetails({ products: result }, 'products');
    });
  }, [
    getPromotionalProductCreate,
    initialPromoProductListings.data,
    removedProductIds,
    updatePromotionalDetails
  ]);

  useEffect(() => {
    getPromoDetails();
  }, [getPromoDetails]);

  useEffect(() => {
    if (initialPromotionalDetails?.banner_url) {
      setBanner(initialPromotionalDetails?.banner_url);
    }

    if (initialPromotionalDetails?.badge_url) {
      setBadgeUrl(initialPromotionalDetails?.badge_url);
    }

    if (initialPromotionalDetails) {
      setIsPublish(
        initialPromotionalDetails?.is_published === 1 ? true : false
      );
    }

    if (
      initialPromotionalDetails?.end_date &&
      initialPromotionalDetails?.end_date
    ) {
      //check if promo is already expired
      const endDate = new Date(initialPromotionalDetails?.end_date || '');
      const today = new Date(dateToday());

      const isEndDatePassed = isBefore(endDate, today);
      setIsPromoExpired(isEndDatePassed);

      setFromDate(initialPromotionalDetails?.start_date);
      setToDate(initialPromotionalDetails?.end_date);
    }
  }, [
    initialPromotionalDetails.badge_url,
    initialPromotionalDetails.banner_url,
    initialPromotionalDetails.end_date,
    initialPromotionalDetails,
    initialPromotionalDetails.start_date
  ]);

  return (
    <Page title="Promotional Information" className={classes.root}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Container maxWidth={false}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', lg: 'row' }
              }}
            >
              <Typography color="textPrimary" gutterBottom variant="h3">
                Promo Information
              </Typography>
              <Tooltip
                title={
                  !hasPromoContent
                    ? 'You need to create promotional content to publish this promo'
                    : ''
                }
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={isPublish === true}
                        onChange={() => handleChangePublish(true)}
                        value={true}
                      />
                    }
                    disabled={!hasPromoContent}
                    label="Publish"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={isPublish === false}
                        onChange={() => handleChangePublish(false)}
                        value={false}
                      />
                    }
                    disabled={!hasPromoContent}
                    label="Unpublish"
                  />
                </Box>
              </Tooltip>
            </Box>

            <Box sx={{ mt: '1.5rem' }}>
              {banner && (
                <Box
                  sx={{
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    p: '1rem'
                  }}
                >
                  <img
                    alt="Cover Image"
                    src={banner}
                    style={{
                      width: 900,
                      height: isMobile ? 100 : 300,
                      objectFit: 'contain'
                    }}
                  />
                </Box>
              )}
              <DragAndDropImgUpload
                title="Drag or select photos for Banner"
                onImageSelected={onChangeBanner}
              />

              <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                  {!hasPromoContent ? (
                    <Alert severity="warning" style={{ marginBottom: '1rem' }}>
                      {`Currently, this promo does not have content. Set the content now by clicking the button below.`}
                    </Alert>
                  ) : null}
                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    size="large"
                    style={{ padding: '1rem' }}
                    onClick={() => setIsPromoContentOpen(true)}
                  >
                    Create Promotional Content
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="title"
                    name="title"
                    label="Promo Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box sx={{ display: 'flex' }}>
                    {badgeUrl && (
                      <img
                        alt="promo-badge"
                        style={{ width: 150, height: 50, marginRight: '1rem' }}
                        src={badgeUrl}
                      />
                    )}
                    <SelectImageButton
                      containerStyle={{ width: '100%' }}
                      title="Select Promo Badge"
                      multiple={false}
                      onImageSelected={onChangeBadge}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    {isPromoExpired ? (
                      <Alert
                        severity="warning"
                        style={{ marginBottom: '1rem' }}
                      >
                        {`Currently, this promo has already ended. Please update the promo duration to the latest date to make it publishable and allow updates to the promo.`}
                      </Alert>
                    ) : null}
                  </Box>
                  <DatePickerRangeComponent
                    title="Promo Duration"
                    fromDateValue={fromDate || null}
                    toDateValue={toDate || null}
                    dateToLabel="End Date"
                    dateFromLabel="Start Date"
                    hasNoDateToday={true}
                    toDateMin={dateToday()}
                    onChangeToDate={(date) => onChangedDate(date, 'to')}
                    onChangeFromDate={(date) => onChangedDate(date, 'from')}
                  />
                  <Typography variant="body2" color="secondary">
                    {errHelper}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '1rem' }}>
                <Divider style={{ marginBottom: '1.5rem' }} />
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={onUpdatePromotional}
                  disabled={disableUpdate}
                >
                  Update Promotional Information
                </Button>
              </Grid>
              <PromoProductListings updateView={true} />
            </Box>
          </form>

          {!disablePromotionalProductsUpdate ? (
            <Box sx={{ width: '100%', p: 3 }}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="large"
                onClick={onUpdatePromotionalProducts}
                disabled={disablePromotionalProductsUpdate}
              >
                Add/Update New Products to Promotional
              </Button>
            </Box>
          ) : null}
          <ErrorDialog
            errs={errMsgArr}
            open={showErrMsg}
            closeDialog={() => setShowErrMsg(false)}
          />
        </Container>
      )}

      <PromoContentDialog
        isOpen={isPromoContentOpen}
        description={decodedHtmlPromoContent}
        handleClose={() => setIsPromoContentOpen(false)}
        reloadProductDetails={() => {}}
      />
    </Page>
  );
};

const validationSchema = Yup.object({
  title: Yup.string()
    .required('Promo title is required')
    .min(2, 'Title is too short - should be 2 chars minimum.')
    .max(150, 'Title cannot exceed 150 characters')
    .test(
      'is-not-numeric',
      'This field must contain letters and cannot be only numbers',
      (value) => isNaN(Number(value))
    )
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      'Title must contain only letters, numbers, and spaces'
    )
});
