import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import {
  InitialState,
  PromoProductParams,
  PromotionalProductData
} from './types';

const initialState: InitialState = {
  isLoading: false,
  initialPromoDetails: {},
  initialPromoProducts: {},
  productsToAdd: {},
  removedProductIds: [], //reference on what ID should be removed for added promo products
  promoProductParams: {},
  hasCurrentProduct: false
};

const createPromotionalProductsAction = (
  state: InitialState,
  { payload }: PayloadAction<PromotionalProductData[]>
) => {
  const filterAddedProduct = payload?.filter(
    (product) => product?.id && !state?.removedProductIds?.includes(product?.id)
  );
  state.initialPromoProducts = {
    data: filterAddedProduct,
    meta: state.initialPromoProducts?.meta
  };
};

const resetStateAction = (state: InitialState) => {
  state.removedProductIds = [];
  state.initialPromoProducts = {};
};

const resetPromoDetailsAction = (state: InitialState) => {
  state.initialPromoDetails = {};
};

const removeCurrentProductAction = (
  state: InitialState,
  { payload }: PayloadAction<number>
) => {
  const filterNewPromoProducts = state.initialPromoDetails?.products?.filter(
    (product) => product.id !== payload
  );

  state.initialPromoDetails = {
    ...state.initialPromoDetails,
    products: filterNewPromoProducts ?? []
  };

  state.removedProductIds = [...state.removedProductIds, payload];
};

const removedPromoProductsAction = (
  state: InitialState,
  { payload }: PayloadAction<number>
) => {
  const filterNewPromoProducts = state.initialPromoProducts?.data?.filter(
    (product) => product.id !== payload
  );
  state.initialPromoProducts = {
    data: filterNewPromoProducts,
    meta: state.initialPromoProducts?.meta
  };
  state.removedProductIds = [...state.removedProductIds, payload];
};

const promoProductParams = (
  state: InitialState,
  { payload }: PayloadAction<PromoProductParams>
) => {
  state.promoProductParams = payload;
};

const slice = createSlice({
  initialState: initialState,
  name: 'promotional',
  reducers: {
    resetStateAction,
    removedPromoProductsAction,
    createPromotionalProductsAction,
    promoProductParams,
    removeCurrentProductAction,
    resetPromoDetailsAction
  },
  extraReducers(builder) {
    builder.addCase(thunks?.getPromotionalDetailsThunk?.pending, (state) => {
      state.initialPromoDetails = {};
      state.isLoading = true;
    });
    builder.addCase(thunks?.getPromotionalDetailsThunk?.rejected, (state) => {
      state.initialPromoDetails = {};
      state.isLoading = false;
    });
    builder.addCase(
      thunks?.getPromotionalDetailsThunk?.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.initialPromoDetails = payload?.originalData?.data || {};
      }
    );

    //get promo products
    builder.addCase(thunks?.getPromotionalProductThunk?.rejected, (state) => {
      state.isLoading = false;
      state.initialPromoProducts = {};
    });
    builder.addCase(
      thunks?.getPromotionalProductThunk?.fulfilled,
      (state, { payload }) => {
        const products = payload?.originalData?.data || [];
        const removedIds = state?.removedProductIds || [];
        const currentProducts = state?.initialPromoDetails?.products || [];

        // Filter out removed products
        const filteredProducts = products.filter(
          (product) =>
            product?.id &&
            !removedIds.includes(product.id) &&
            !currentProducts?.some(
              (currentProduct) => currentProduct?.id === product.id
            )
        );

        //check if some product is already in current promotional
        state.hasCurrentProduct = products?.some((product) =>
          currentProducts?.some(
            (currentProduct) => currentProduct?.id === product?.id
          )
        );

        // Update state
        state.initialPromoProducts = {
          data: filteredProducts,
          meta: payload?.originalData?.meta || {}
        };
      }
    );
  }
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
