export const taptapSource = {
  FACEBOOK: 'FACEBOOK',
  VIBER: 'VIBER',
  ECOMM: 'ECOMM',
  WALK_IN: 'WALK IN'
};

export const taptapOptionsSource = [
  {
    key: 'facebook',
    label: taptapSource.FACEBOOK,
    value: taptapSource.FACEBOOK
  },
  { key: 'viber', label: taptapSource.VIBER, value: taptapSource.VIBER },
  { key: 'ecomm', label: taptapSource.ECOMM, value: taptapSource.ECOMM },
  { key: 'walk_in', label: taptapSource.WALK_IN, value: taptapSource.WALK_IN }
];
