import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { LoadingButton } from 'src/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { taptapCustomerPaymentFields } from 'src/redux/slices/taptap-customer';
import {
  TapTapCustomerData,
  TapTapImageFieldJson
} from 'src/redux/slices/taptap-customer/types';
import { SupabaseClientService } from 'src/services';
import { getRandomUUIDv4Short } from 'src/utils';
import TapTapForm from './components/TapTapForm';
import { LoadingButton } from 'src/components';

interface Props {
  isVisible: boolean;
  customer?: TapTapCustomerData;
  handleClose: () => void;
}

interface FormValues {
  [key: string]: any; // could string, boolean, number, etc
}

const initialValues: FormValues = {
  payment_type: null,
  amount: null
};

const AddCustomerPaymentDialog = ({
  isVisible = false,
  handleClose,
  customer
}: Props) => {
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    formik.resetForm();
  };

  const handleCloseInternal = () => {
    formik.resetForm();
    handleClose();
  };

  const uploadMultipleImages = async (
    path: string,
    images: TapTapImageFieldJson[]
  ) => {
    let uploadedImgs: TapTapImageFieldJson[] = [];
    const userId = (await SupabaseClientService?.auth?.getUser()).data.user?.id;
    // const currentQuoteImgs = formik.values.quotation;

    try {
      for (const image of images) {
        if (image.file) {
          const randomFileName = `${getRandomUUIDv4Short()}-${Date.now()}`;
          console.log(image.file);
          const { data, error } = await SupabaseClientService.storage
            .from('taptap-images')
            .upload(`${path}${randomFileName}`, image.file); // TODO: Change the path based on field key

          if (error) {
            console.error(`Error uploading image for field ${path}:`, error);
            throw new Error(`Failed to upload images for field ${path}.`);
          }

          // IMPORTANT
          image.file = undefined; // Remove the file object

          // Get the public URL
          const imgUrlResp = SupabaseClientService.storage
            .from('taptap-images')
            .getPublicUrl(`${path}${randomFileName}`);

          uploadedImgs.push({
            ...image,
            img_url: imgUrlResp.data.publicUrl,
            created_by: userId,
            created_at: new Date().toISOString()
          });

          console.log(`Image uploaded successfully for field ${path}:`, data);
        } else {
          uploadedImgs.push(image); // Already uploaded images remain unchanged
        }
      }

      console.log('All images uploaded successfully:', uploadedImgs);
      return uploadedImgs;
    } catch (err) {
      console.error('Upload process terminated:', err);
      return null; // Or handle the error as needed
    }
  };

  const handleFormSubmit = async (values: any) => {
    setLoading(true);
    let newCustomerPaymentData: any = values;

    if (loading) {
      return;
    }
    if (formik?.errors && Object.keys(formik?.errors)?.length > 0) {
      console.error(formik?.errors);
      return;
    }
    const imageFields = Object.keys(newCustomerPaymentData).filter(
      (key) =>
        Array.isArray(newCustomerPaymentData[key]) &&
        newCustomerPaymentData[key][0]?.img_url
    );

    console.log('imageFields', imageFields);

    // Step 2: Handle image uploads for each "image" field
    for (const fieldKey of imageFields) {
      const fieldImages: TapTapImageFieldJson[] =
        newCustomerPaymentData[fieldKey];

      // Upload only unuploaded images
      const uploadedImages = await uploadMultipleImages(
        `${fieldKey}/`,
        fieldImages
      );

      if (!uploadedImages) {
        console.error('Failed to upload images for field:', fieldKey);
        return;
      }

      newCustomerPaymentData[fieldKey] = uploadedImages;
    }

    // onInsertCustomerPaymentOnSupabaseViaClient(data);

    const { error } = await SupabaseClientService.from(
      'customers_payments'
    ).insert({ ...newCustomerPaymentData, customer_id: customer?.id });

    if (error) {
      console.log('Malas error');
      console.error(error);
      return;
    }

    resetState();
    setLoading(false);
    handleCloseInternal();
  };

  const onCancelBtnPress = () => {
    resetState();
    handleCloseInternal();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  return (
    <Dialog
      fullWidth
      open={isVisible}
      onClose={(_e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        handleCloseInternal();
      }}
    >
      <DialogTitle>Add Payment for Customer {customer?.first_name}</DialogTitle>
      <DialogContent>
        <TapTapForm
          formFields={taptapCustomerPaymentFields}
          formikHookRef={formik}
        />

        <DialogActions>
          <>
            <LoadingButton
              title="Add"
              loading={loading ? loading : false}
              onClick={() => formik.handleSubmit()}
              color="primary"
              disabled={loading}
            />
            <Button onClick={onCancelBtnPress}>Cancel</Button>
          </>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomerPaymentDialog;

const formSchema = yup.object().shape({
  payment_type: yup.string().required('Payment Method is required'),
  amount: yup.number().required('Amount is required')
});
