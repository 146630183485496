import React, { CSSProperties } from 'react';
import Modal from '@material-ui/core/Modal';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import { CheckBoxLabel } from 'src/components';
import { InventoryColumn } from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { usePermissions } from 'src/hooks';
import { multiBranchFeat } from 'src/constants/feature-toggle';

const {
  actions: inventoryActions,
  selectors: inventorySelectors
} = slices.inventory;

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: 20,
    marginBottom: 20
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  onHandleClose: () => void;
}

const InventoryColumnsFilter = ({ visible, onHandleClose }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const inventoryColumns = useAppSelector(
    inventorySelectors.selectInventoryColumns
  );
  const { canSeeDealersPrice } = usePermissions();

  const onToggleAll = () => {
    dispatch(inventoryActions.showAllColumns());
  };

  const onToggleItem = (item: InventoryColumn) => {
    dispatch(inventoryActions.toggleInventoryColumn(item));
  };

  const onClose = () => {
    onHandleClose();
  };
  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={modalStyle}
    >
      <div style={bodyStyle} className={classes.body}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" color="textPrimary">
            Inventory Columns Filter
          </Typography>
          <Button endIcon={<DoneAllIcon />} onClick={onToggleAll}>
            Toggle All
          </Button>
        </div>
        <CheckBoxLabel
          label="Manufacturer Name"
          checked={inventoryColumns?.manufacturer_id}
          onChange={() => onToggleItem('manufacturer_id')}
        />
        <CheckBoxLabel
          label="id"
          checked={inventoryColumns?.id}
          onChange={() => onToggleItem('id')}
        />
        <CheckBoxLabel
          label="Transaction No."
          checked={inventoryColumns?.transaction_no}
          onChange={() => onToggleItem('transaction_no')}
        />
        <CheckBoxLabel
          label="Quantity"
          checked={inventoryColumns?.quantity}
          onChange={() => onToggleItem('quantity')}
        />
        <CheckBoxLabel
          label="Product"
          checked={inventoryColumns?.product_name}
          onChange={() => onToggleItem('product_name')}
        />
        <CheckBoxLabel
          label="SKU"
          checked={inventoryColumns?.sku}
          onChange={() => onToggleItem('sku')}
        />
        <CheckBoxLabel
          label="Serial No."
          checked={inventoryColumns?.serial_no}
          onChange={() => onToggleItem('serial_no')}
        />
        <CheckBoxLabel
          label="Customer Name"
          checked={inventoryColumns?.customer_name}
          onChange={() => onToggleItem('customer_name')}
        />
        {canSeeDealersPrice ? (
          <CheckBoxLabel
            label="Dealers Price"
            checked={inventoryColumns?.dealers_price}
            onChange={() => onToggleItem('dealers_price')}
          />
        ) : null}
        <CheckBoxLabel
          label="Retail Price"
          checked={inventoryColumns?.retail_price}
          onChange={() => onToggleItem('retail_price')}
        />
        <CheckBoxLabel
          label="Supplier Name"
          checked={inventoryColumns?.supplier_name}
          onChange={() => onToggleItem('supplier_name')}
        />
        {multiBranchFeat ? (
          <CheckBoxLabel
            label="Branch Name"
            checked={inventoryColumns?.branch_name}
            onChange={() => onToggleItem('branch_name')}
          />
        ) : null}
        <CheckBoxLabel
          label="Encoded By"
          checked={inventoryColumns?.encoded_by}
          onChange={() => onToggleItem('encoded_by')}
        />
        <CheckBoxLabel
          label="Released By"
          checked={inventoryColumns?.released_by}
          onChange={() => onToggleItem('released_by')}
        />
        <CheckBoxLabel
          label="Date Sold (Customer)"
          checked={inventoryColumns?.date_sold}
          onChange={() => onToggleItem('date_sold')}
        />
        <CheckBoxLabel
          label="Date Purchased (Supplier)"
          checked={inventoryColumns?.date_purchased}
          onChange={() => onToggleItem('date_purchased')}
        />
        <CheckBoxLabel
          label="Date Encoded"
          checked={inventoryColumns?.date_encoded}
          onChange={() => onToggleItem('date_encoded')}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          className={classes.footer}
        >
          <Button
            fullWidth
            onClick={onClose}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export const InventoryColumnsFilterModal = React.memo(InventoryColumnsFilter);
