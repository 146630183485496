import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import usePromotional from 'src/hooks/promotional/use-promotional';

import {
  CategoryListDropdown,
  ManufacturerDropdownList
} from 'src/components/dropdown';

import { isArray, isEmpty } from 'lodash';
import { PromoProductTable } from './PromoProductTable';
import { useSnackBar } from 'src/hooks';
import { Alert, Pagination } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type ManufacturerOption = {
  id?: number;
  name?: string;
};

interface Props {
  updateView?: boolean;
}

const Component: FC<Props> = ({ updateView = false }) => {
  const snackbar = useSnackBar();
  const {
    initialPromoProductListings,
    initialPromotionalDetails,
    hasCurrentProducts,
    resetStatePromotional,
    getPromoProducts,
    setPromoProductParams
  } = usePromotional();
  const [searchPromo, setSearchPromo] = useState<string>();
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [manufacturerIds, setManufacturerIds] = useState<number[]>([]);
  const [page_num, setPageNum] = useState<number>(1);

  const isPromoProductsEmpty = useMemo(
    () =>
      isEmpty(initialPromotionalDetails?.products) &&
      isEmpty(initialPromoProductListings?.data),
    [initialPromoProductListings, initialPromotionalDetails]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchPromo(value);
  };

  const onHandleSelectedManufacturer = (options: ManufacturerOption) => {
    if (isArray(options)) {
      const manufacturerIds = options?.map((item) => {
        return item?.id;
      });
      setManufacturerIds(manufacturerIds);
    }
  };

  const onHandleSelectedCategory = (options: number[]) => {
    if (isArray(options)) {
      setCategoryIds(options);
      return;
    }
  };

  const onHandleGetPromoProduct = useCallback(() => {
    if (!searchPromo && isEmpty(categoryIds) && isEmpty(manufacturerIds)) {
      snackbar.show({
        severity: 'error',
        message: 'keyword, category and brands is required.'
      });
      return;
    }

    if (
      (!searchPromo && !isEmpty(categoryIds) && isEmpty(manufacturerIds)) ||
      (isEmpty(categoryIds) && !isEmpty(manufacturerIds))
    ) {
      snackbar.show({
        severity: 'error',
        message:
          'both category and brands is required when no keyword is presented.'
      });
      return;
    }

    //every time user search, reset the removed ids state
    resetStatePromotional();
    setPageNum(1);
    getPromoProducts({
      keyword: searchPromo || undefined,
      category_ids: categoryIds,
      manufacturer_ids: manufacturerIds
    });

    //have this store in state when fetching new promo products
    setPromoProductParams({
      keyword: searchPromo || undefined,
      category_ids: categoryIds,
      manufacturer_ids: manufacturerIds
    });
  }, [
    categoryIds,
    searchPromo,
    manufacturerIds,
    snackbar,
    getPromoProducts,
    resetStatePromotional,
    setPromoProductParams
  ]);

  const handleChangePage = useCallback(
    (page_num: number) => {
      setPageNum(page_num);
      getPromoProducts({
        keyword: searchPromo || undefined,
        category_ids: categoryIds,
        manufacturer_ids: manufacturerIds,
        page: page_num
      });
    },
    [categoryIds, getPromoProducts, manufacturerIds, searchPromo]
  );

  return (
    <Box sx={{ mt: '1.5rem' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: '1.5rem' }} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            fullWidth
            id="search-products"
            label="Search Products"
            value={searchPromo}
            onChange={handleChange}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box style={{ position: 'relative', minHeight: 50 }}>
            <CategoryListDropdown
              isMultiple={true}
              onHandleCategories={(selectedOptions: any) =>
                onHandleSelectedCategory(selectedOptions)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box style={{ position: 'relative', minHeight: 50 }}>
            <ManufacturerDropdownList
              label="Brand(s) Included"
              isMultiple={true}
              onSelectionChange={(selectedOptions: any) =>
                onHandleSelectedManufacturer(selectedOptions)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={3} style={{ placeContent: 'center' }}>
          <Button
            variant={'contained'}
            color="primary"
            onClick={onHandleGetPromoProduct}
            fullWidth
            size="large"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          {isPromoProductsEmpty ? (
            <Alert severity="warning" style={{ marginBottom: '1rem' }}>
              {`At least one assigned product is required to update this promo.`}
            </Alert>
          ) : null}
        </Grid>
        {initialPromoProductListings?.data ? (
          <Grid
            item
            xs={12}
            lg={12}
            style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
          >
            {updateView ? (
              <>
                <Divider />
                <Typography
                  variant="h4"
                  style={{ marginTop: '1rem', padding: 3 }}
                >
                  Temporary Promo Product Listings
                </Typography>
              </>
            ) : null}

            {hasCurrentProducts ? (
              <Alert severity="warning" style={{ marginBottom: '1rem' }}>
                {`Some searched items are currently included in this promotional, those item(s) are now excluded from this list and can be viewed in current assigned promotional listings below.`}
              </Alert>
            ) : null}
            <PromoProductTable
              promoProducts={initialPromoProductListings?.data}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Pagination
                count={initialPromoProductListings?.meta?.last_page}
                page={
                  page_num || initialPromoProductListings?.meta?.current_page
                }
                onChange={(_, page_num) => handleChangePage(page_num)}
              />
            </Box>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {initialPromotionalDetails && initialPromotionalDetails?.products ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h4">
                  Current Assigned Promotional Products
                </Typography>
              </AccordionSummary>

              <PromoProductTable
                promoProducts={initialPromotionalDetails?.products}
                updateView={updateView}
              />
            </Accordion>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export const PromoProductListings = memo(Component);
