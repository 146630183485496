import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { usePermissions } from 'src/hooks';
import { PromoListings } from './component/PromoListings';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

export const PromotionalsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { canCreatePromotional } = usePermissions();

  const onHandleNavigateCreate = () => {
    navigate(`/app/promotional/create`);
  };

  return (
    <Page title="Promotionals" className={classes.root}>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography color="textPrimary" gutterBottom variant="h3">
            Promotional
          </Typography>
          {canCreatePromotional ? (
            <Button
              variant="outlined"
              size="large"
              startIcon={<LoyaltyIcon />}
              onClick={onHandleNavigateCreate}
            >
              Create Promo
            </Button>
          ) : null}
        </Box>
        <PromoListings />
      </Container>
    </Page>
  );
};
