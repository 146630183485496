import { useCallback, useMemo, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { useSnackBar } from '../use-snackbar';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  CreatePromoPayload,
  GetPromotionalListingsPayload,
  PromotionalProductData,
  PromotionalProductsPayload,
  UpdatePromotionalDetailsPayload,
  GetPromotionalListingsResponse,
  PromoProductParams,
  UpdatePromotionalPositionsPayload
} from 'src/redux/slices/promotional';
import { useNavigate, useParams } from 'react-router';
import { isEmpty, uniq } from 'lodash';

const { actions: promoActions, selectors: promoSelectors } = slices.promotional;

const usePromotional = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('Something Went Wrong');
  const [errMsgArr, setErrMsgArr] = useState<any[]>([]);
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);
  const [shouldPrompt, setShouldPrompt] = useState<boolean>(true);

  const [promoListing, setPromoListing] = useState<
    GetPromotionalListingsResponse
  >();
  const [promoProducts, setPromoProducts] = useState<PromotionalProductData[]>(
    []
  );

  const initialPromotionalDetails = useAppSelector(
    promoSelectors?.selectInitialPromoDetails
  );

  const initialPromoProductListings = useAppSelector(
    promoSelectors?.selectInitialPromoProducts
  );

  const initialPromoContent = useAppSelector(
    promoSelectors?.selectInitialPromoContent
  );

  const promoProductParams = useAppSelector(
    promoSelectors?.selectPromoProductParams
  );

  const removedProductIds = useAppSelector(
    promoSelectors?.selectRemovedProductIds
  );

  const hasCurrentProducts = useAppSelector(
    promoSelectors?.selectHasCurrentProduct
  );

  const decodedHtmlPromoContent = useMemo(() => {
    if (initialPromoContent) {
      const decodeBase64 = decodeURIComponent(
        escape(window.atob(initialPromoContent))
      );
      return decodeBase64;
    }
    return '';
  }, [initialPromoContent]);

  const hasPromoContent = useMemo(
    () =>
      initialPromotionalDetails?.details !== undefined &&
      initialPromotionalDetails?.details !== null,
    [initialPromotionalDetails.details]
  );

  const resetStatePromotional = useCallback(() => {
    dispatch(promoActions?.resetStateAction());
  }, [dispatch]);

  const removeProduct = (id: number, updateView?: boolean) => {
    if (id && updateView) {
      dispatch(promoActions?.removeCurrentProductAction(id));
    }

    if (id) {
      dispatch(promoActions?.removedPromoProductsAction(id));
    }
  };

  const resetPromotionalDetails = useCallback(() => {
    dispatch(promoActions?.resetPromoDetailsAction());
  }, [dispatch]);

  const setPromoProductParams = useCallback(
    async (params: PromoProductParams) => {
      dispatch(promoActions?.promoProductParams(params));
    },
    [dispatch]
  );

  const updateArrangements = (payload: UpdatePromotionalPositionsPayload) => {
    dispatch(promoActions.updatePromotionalPositionThunk(payload));
  };

  const getPromoListings = useCallback(
    async (payload?: GetPromotionalListingsPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(promoActions?.getPromotionalListingsThunk(payload))
        );

        if (response?.success) {
          setIsLoading(false);
          setPromoListing(response?.originalData);
          if (payload?.keyword) {
            snackBar.show({ severity: 'success', message: response?.message });
            setErrMsg(JSON.stringify(response?.message));
          }
        } else {
          snackBar.show({ severity: 'error', message: response?.message });
        }
      } catch (error) {
        console.error(error);
        setErrMsg(JSON.stringify(error));
        snackBar.show({ severity: 'error', message: JSON.stringify(error) });
      } finally {
        setIsLoading(false);
      }
    },
    //do not include snackbar as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const getPromoDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      resetStatePromotional(); //always reset state when loading new details
      const response = unwrapResult(
        await dispatch(promoActions?.getPromotionalDetailsThunk(id || ''))
      );

      if (!response?.success) {
        snackBar.show({ severity: 'error', message: response?.message });
      }
    } catch (error) {
      console.error(error);
      snackBar.show({ severity: 'error', message: JSON.stringify(error) });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  //This function is used to fetch all products from division of pages response
  const getPromotionalProductCreate = useCallback(async () => {
    const last_page = initialPromoProductListings?.meta?.last_page || 1;

    //if there is no params meaning the user is not using search products
    if (isEmpty(promoProductParams) && initialPromotionalDetails?.products) {
      const currentProductIds = initialPromotionalDetails?.products?.map(
        (product) => ({
          id: product?.id
        })
      );
      return currentProductIds;
    }

    try {
      //just a loop of promises before create or update promo
      const fetchPromises = Array.from({ length: last_page }, (_, index) =>
        dispatch(
          promoActions.getPromotionalProductThunk({
            ...promoProductParams,
            limit: 50,
            page: index + 1 // Start pages from 1
          })
        ).then(unwrapResult)
      );

      const responses = await Promise.all(fetchPromises);
      const accumulatedProducts = responses.flatMap(
        (res) => res?.originalData?.data || []
      );

      const filteredProducts = accumulatedProducts
        .filter(
          (product) => product?.id && !removedProductIds.includes(product.id)
        )
        .map((product) => ({ id: product?.id }));

      //if there is current promotional products combine it
      if (initialPromotionalDetails?.products) {
        const currentProductIds = initialPromotionalDetails?.products?.map(
          (product) => ({
            id: product?.id
          })
        );
        return [...filteredProducts, ...currentProductIds];
      }

      return filteredProducts;
    } catch (error) {
      console.error('Error fetching promotional products:', error);
      return [];
    }
  }, [
    dispatch,
    initialPromoProductListings,
    initialPromotionalDetails.products,
    promoProductParams,
    removedProductIds
  ]);

  //for displaying fetched list
  const getPromoProducts = useCallback(
    async (payload: PromotionalProductsPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(
            promoActions?.getPromotionalProductThunk({ ...payload, limit: 50 })
          )
        );

        if (response?.success) {
          setIsLoading(false);
          setPromoProducts(response?.originalData?.data || []);
          if (
            response?.originalData?.data &&
            response?.originalData?.data.length <= 0
          ) {
            snackBar.show({ severity: 'info', message: response?.message });
          }
        } else {
          snackBar.show({ severity: 'error', message: response?.message });
          setPromoProducts([]);
        }
      } catch (error) {
        console.error(error);
        snackBar.show({ severity: 'error', message: JSON.stringify(error) });
        setPromoProducts([]);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, snackBar]
  );

  const createPromotional = useCallback(
    async (payload: CreatePromoPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(
            promoActions?.createPromotionalThunk({
              ...payload
              // products: initialPromoProductListings?.data
            })
          )
        );

        if (response?.success) {
          setIsLoading(false);
          resetStatePromotional();
          navigate(
            `/app/promotional/details/${response?.originalData.data?.id}`,
            { replace: true }
          );
          snackBar.show({ severity: 'success', message: response?.message });
        } else {
          const errorMessages = Object.values(response.errors).flat();
          setErrMsgArr(errorMessages);
          setShouldPrompt(true);
          snackBar.show({ severity: 'error', message: response?.message });
          setShowErrMsg(true);
        }
      } catch (error) {
        console.error(error);
        snackBar.show({ severity: 'error', message: JSON.stringify(error) });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, navigate, resetStatePromotional, snackBar]
  );

  const updatePromotionalDetails = useCallback(
    async (
      payload: UpdatePromotionalDetailsPayload,
      action: 'info' | 'products'
    ) => {
      let params = {};
      const productsArray = uniq(
        payload?.products?.map((product) => product?.id)
      );

      //meaning there is no changes in promo products
      if (isEmpty(productsArray)) {
        params = {
          id: id,
          ...payload
        };
      } else {
        params = {
          id: id,
          ...payload,
          products: productsArray || [] //if there is changes then pass this params
        };
      }

      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(promoActions?.updatePromotionalDetailsThunk(params))
        );

        if (response?.success) {
          setIsLoading(false);
          getPromoDetails(); //refetch new state
          snackBar.show({
            severity: 'success',
            message:
              action === 'info'
                ? response?.message
                : 'Successfully updated promo products'
          });
        } else {
          snackBar.show({ severity: 'error', message: response?.message });
        }
      } catch (error) {
        console.error(error);
        snackBar.show({ severity: 'error', message: JSON.stringify(error) });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, getPromoDetails, id, snackBar]
  );

  const updatePromotionalContent = useCallback(
    async (content: string) => {
      try {
        setIsLoading(false);
        const response = unwrapResult(
          await dispatch(
            promoActions?.updatePromotionalContentThunk({
              id: id,
              details_content: content
            })
          )
        );

        if (response?.success) {
          setIsLoading(true);
          snackBar.show({ severity: 'success', message: response?.message });
        } else {
          snackBar.show({
            severity: 'error',
            message: response?.errors['details_content']
          });
        }
      } catch (error) {
        console.error(error);
        snackBar.show({ severity: 'error', message: JSON.stringify(error) });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, id, snackBar]
  );

  return {
    initialPromoContent,
    initialPromotionalDetails,
    isLoading,
    errMsg,
    promoProducts,
    promoListing,
    initialPromoProductListings,
    promoProductParams,
    errMsgArr,
    showErrMsg,
    shouldPrompt,
    hasPromoContent,
    hasCurrentProducts,
    removedProductIds,
    decodedHtmlPromoContent,

    getPromoListings,
    updateArrangements,
    getPromoDetails,
    getPromoProducts,
    createPromotional,
    updatePromotionalContent,
    updatePromotionalDetails,
    setPromoProducts,
    resetStatePromotional,
    removeProduct,
    setPromoProductParams,
    setShowErrMsg,
    setShouldPrompt,
    getPromotionalProductCreate,
    resetPromotionalDetails
  };
};

export default usePromotional;
