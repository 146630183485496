import React from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { TapTapCustomerPayment } from 'src/redux/slices/taptap-customer/types';
import { LinkComponent } from 'src/components';
import { formatCurrency, formatDate } from 'src/utils';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { colors } from 'src/constants';

interface Props {
  transactionNo?: string;
  customerPayments?: TapTapCustomerPayment[];
  isPaymentsCollapsibleOpen: boolean;
  onClickEditPayment: (payment: TapTapCustomerPayment) => void;
  // onClickAddPayment: () => void;
}

const TapTapCustomerPaymentRow = ({
  customerPayments,
  isPaymentsCollapsibleOpen,
  onClickEditPayment,
  transactionNo
}: // onClickAddPayment
Props) => {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={isPaymentsCollapsibleOpen} timeout="auto" unmountOnExit>
          <Box margin={2}>
            {customerPayments && customerPayments?.length > 0 && (
              <Table size="small" aria-label="payments">
                <TableHead>
                  <TableRow>
                    <TableCell>Acknowledgement Receipt No.</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Screenshots</TableCell>
                    <TableCell>Date Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerPayments?.map((payment) => {
                    return (
                      <TableRow hover key={payment?.id}>
                        <TableCell>
                          {payment?.acknowledgement_receipt_no || '--'}
                        </TableCell>
                        <TableCell component="th">
                          {payment?.payment_type}
                        </TableCell>
                        <TableCell component="th">
                          {formatCurrency(payment?.amount)}
                        </TableCell>
                        <TableCell component="th">
                          {payment?.screenshots?.map((screenshot, i) => (
                            <LinkComponent
                              key={i}
                              openInNewTab
                              title={
                                <Typography
                                  style={{ color: colors.link }}
                                  variant="inherit"
                                >{`screenshot-${i + 1}, `}</Typography>
                              }
                              href={screenshot?.img_url}
                            />
                          ))}

                          <IconButton
                            size="small"
                            onClick={() => onClickEditPayment(payment)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            payment?.created_at,
                            'EEEE, MMM d, yyyy h:mm a'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}

            {transactionNo ? (
              <LinkComponent
                openInNewTab
                title={
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ margin: 10 }}
                    startIcon={<AttachMoneyIcon />}
                  >
                    Add Payment
                  </Button>
                }
                href={`/app/transaction/${transactionNo}`}
              />
            ) : (
              <Typography>Not Available. Need Transaction no.</Typography>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default TapTapCustomerPaymentRow;
