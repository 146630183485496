import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { KachiHistoryTable } from '../components/KachiHistoryTable';
import { PaginationMeta } from 'src/types';
import { usePermissions } from 'src/hooks';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Pagination } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useParams } from 'react-router-dom';
import { useKachiPoints } from 'src/hooks/kachi-points';

import { AddKachiPointsFormDialog } from '../components/AddKachiPointsFormDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
}));

const KachiPointsHistory = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { canAddKachiPoints } = usePermissions();
  const paramId = id ? id : '';
  const location = useLocation();
  const state: any = location.state;

  const {
    kachiPointsUserHistory,
    getKachiPointsUserHistory
  } = useKachiPoints();
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);

  const [meta, setMeta] = useState<PaginationMeta>();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [kachiPointsVal, setKachiPointsVal] = useState<number>(
    state?.totalPoints || 0
  );

  const getKachiHistory = useCallback(
    async (userId?: number, pageNum?: number) => {
      const response = unwrapResult(
        await getKachiPointsUserHistory(userId, pageNum)
      );
      if (response?.success) {
        setMeta(response?.originalData?.meta);
      }
    },
    [getKachiPointsUserHistory]
  );

  const onHandleChangePage = (user_id?: number, pageNum?: number) => {
    getKachiHistory(user_id, pageNum);
    setCurrentPage(pageNum);
  };

  useEffect(() => {
    if (paramId) {
      getKachiHistory(+paramId);
    }
  }, [getKachiHistory, paramId]);

  return (
    <Page className={classes.root} title="Kachi Points Details">
      <Typography
        color="textPrimary"
        gutterBottom
        variant="h2"
        style={{ marginBottom: '1rem' }}
      >
        Kachi Points History
      </Typography>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card>
              <CardContent className={classes.header}>
                <Box>
                  <Typography variant="h3">{state?.userFullname}</Typography>
                  <Typography>{`total kachi points: ${kachiPointsVal}`}</Typography>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!canAddKachiPoints}
                    onClick={() => setShowAddDialog(!showAddDialog)}
                    startIcon={<AddBoxIcon />}
                  >
                    Add Kachi Points
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12}>
            <KachiHistoryTable
              kachiHistoryList={kachiPointsUserHistory}
              userId={+paramId}
            />
            <Box
              style={{ display: 'grid', placeItems: 'center', padding: '1rem' }}
            >
              <Pagination
                count={meta?.last_page}
                page={currentPage}
                onChange={(e: any, p: number) =>
                  onHandleChangePage(+paramId, p)
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AddKachiPointsFormDialog
        isOpen={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        userId={+paramId}
        updatePoints={(val: number) => setKachiPointsVal(kachiPointsVal + val)}
      />
    </Page>
  );
};

export default KachiPointsHistory;
