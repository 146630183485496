import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { TableCell, TableRow, colors, makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { AlternatingColorTableRow } from 'src/components';
import { TransferStockLogsData } from 'src/redux/slices/transfer-stock-logs';
import { useTransferStockLogs } from 'src/hooks/transfer-stock-logs';
import { TransferLogAccordion } from './TransferStockLogAccordion';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  hoverPointer: { cursor: 'pointer' },
  tableRow: {
    height: 35,
    padding: 30
  },
  tableCell: {
    border: 'none',
    padding: '1rem',
    borderBottom: '1px solid gray'
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  }
}));

interface Props {
  logsData?: TransferStockLogsData;
}

const component: FC<Props> = ({ logsData }) => {
  const classes = useStyles();
  const {
    fetchedStNo,
    setFetchedStNo,
    isTransferLogsSerialLoading,
    transferLogsSerialData,
    getTransferStockLogsThroughSt
  } = useTransferStockLogs();
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const onHandleViewTransferStockLogsSerial = useCallback(
    (st_no?: string) => {
      if (isExpand) {
        setIsExpand(!isExpand);
        return;
      }
      //if st exist no action needed
      if (fetchedStNo.some((st) => st === st_no)) {
        setIsExpand(!isExpand); //flag action
        return;
      }
      getTransferStockLogsThroughSt(st_no);
      setFetchedStNo((prev) => [...prev, st_no || '']);
      setIsExpand(!isExpand);
    },
    [fetchedStNo, getTransferStockLogsThroughSt, isExpand, setFetchedStNo]
  );

  //if there is a new request reset all
  useEffect(() => {
    if (logsData) {
      setIsExpand(false);
      setFetchedStNo([]);
    }
  }, [logsData, setFetchedStNo]);

  return (
    <Fragment>
      <AlternatingColorTableRow
        hover
        className={classes.hoverPointer}
        onClick={() => onHandleViewTransferStockLogsSerial(logsData?.st_no)}
      >
        <TableCell className={classes.tableCell} width={'5%'}>
          {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.with_issue === 0 ? (
            <CheckCircleIcon style={{ color: colors.green[600] }} />
          ) : (
            <CancelIcon color="secondary" />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>{logsData?.st_no}</TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.transferred_by}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.transferred_date}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.from_branch}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {logsData?.to_branch}
        </TableCell>
      </AlternatingColorTableRow>

      {isExpand && (
        <TableRow>
          <TableCell colSpan={7}>
            <TransferLogAccordion
              stNo={logsData?.st_no}
              stBarcode={logsData?.st_no_barcode}
              dateTransferred={logsData?.transferred_date}
              origin={logsData?.from_branch}
              receiving={logsData?.to_branch}
              processedBy={logsData?.transferred_by}
              isLoading={isTransferLogsSerialLoading}
              isOpen={isExpand}
              transferLogsSerialData={transferLogsSerialData || []}
            />
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export const TransferStockRow = memo(component);
