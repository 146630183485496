import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  taptapCustomerPaymentFields,
  taptapUploadMultipleImages
} from 'src/redux/slices/taptap-customer';
import {
  TapTapCustomerPayment,
  TapTapImageFieldJson
} from 'src/redux/slices/taptap-customer/types';
import { clone, isEqual, pickBy } from 'lodash';
import { SupabaseClientService } from 'src/services';
import useUserInfo from 'src/hooks/user/use-user-info';
import TapTapForm from './components/TapTapForm';
import { LoadingButton } from 'src/components';

interface Props {
  isVisible: boolean;
  loading?: boolean;
  paymentDetails?: TapTapCustomerPayment;
  handleClose: () => void;
}

interface FormValues {
  [key: string]: any; // could string, boolean, number, etc
}

// TODO: Add on const OF taptap
const initialValues: FormValues = {
  payment_type: null,
  amount: null
};

const TapTapCustomerPaymentDetailsDialog = ({
  isVisible = false,
  // loading = false,
  handleClose,
  // eslint-disable-next-line no-unused-vars
  paymentDetails
}: Props) => {
  const { userDetails } = useUserInfo();
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    formik.resetForm();
  };

  const handleCloseInternal = () => {
    formik.resetForm();
    handleClose();
  };

  const fetchTapTapCustomerDetails = async () => {
    if (paymentDetails?.id) {
      // Save this clean data to compare later if the server has made changes
      const { data, error } = await SupabaseClientService.from(
        'customers_payments'
      )
        .select()
        .eq('id', paymentDetails.id);

      if (error) {
        console.log('error', error);
        console.error('errData', data);
        return;
      }

      if (data?.length === 0) {
        console.error('No data found');
        return;
      }

      if (data?.length > 0) {
        const response: TapTapCustomerPayment = data[0];
        formik.setValues(response);
        return;
      }
    }
  };

  // MAYBE SA FUTURE. FOR NOW INTERNAL DAPAT KUMONTROL NET
  // eslint-disable-next-line no-unused-vars
  const onDeleteCustomerPayment = async () => {
    const dataForDeletion = {
      deleted_at: new Date().toISOString(),
      deleted_by: userDetails?.id,
      is_deleted: true
    };

    const { data, error } = await SupabaseClientService.from(
      'customers_payments'
    )
      .update(dataForDeletion)
      .eq('id', paymentDetails?.id)
      .select();

    if (error) {
      console.log('error', error);
      console.error('errData', data);
      return;
    }

    if (data?.length === 0) {
      console.error('No data found');
      return;
    }

    console.log('success delete', data);
    if (data?.length > 0) {
      const response: TapTapCustomerPayment = data[0];
      formik.setValues(response);
      handleCloseInternal();
      return;
    }
  };

  const handleFormSubmit = async (newValues: any) => {
    setLoading(true);

    // Cloned and intended `any` type because Fuck TypeScript
    const paymentDetailsClone: any = clone(paymentDetails);

    const imageFields = Object.keys(newValues).filter(
      (key) => Array.isArray(newValues[key]) && newValues[key][0]?.img_url
    );

    console.log('imageFields', imageFields);

    // Step 2: Handle image uploads for each "image" field
    for (const fieldKey of imageFields) {
      const fieldImages: TapTapImageFieldJson[] = newValues[fieldKey];

      // Upload only unuploaded images
      const uploadedImages = await taptapUploadMultipleImages(
        `${fieldKey}/`,
        fieldImages
      );

      if (!uploadedImages) {
        setLoading(false);

        console.error('Failed to upload images for field:', fieldKey);
        return;
      }

      newValues[fieldKey] = uploadedImages;
    }

    const changedValues = pickBy(
      newValues,
      (value, key) => !isEqual(value, paymentDetailsClone[key])
    );

    // JUST UPDATE PROPERTIES THAT HAVE CHANGED
    const { data, error } = await SupabaseClientService.from(
      'customers_payments'
    )
      .update(changedValues)
      .eq('id', paymentDetails?.id)
      .select();

    if (error) {
      console.log('error', error);
      console.error('errData', data);
      setLoading(false);
      return;
    }

    if (data?.length === 0) {
      console.error('No data found');
      setLoading(false);
      return;
    }

    if (data?.length > 0) {
      const response: TapTapCustomerPayment = data[0];
      formik.setValues(response);
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const onCancelBtnPress = () => {
    resetState();
    handleCloseInternal();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  useEffect(() => {
    formik.setValues(paymentDetails || initialValues);

    if (isVisible) {
      fetchTapTapCustomerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]); // INTENDED NA WALA DITO YUNG `paymentDetails`

  return (
    <Dialog
      fullWidth
      open={isVisible}
      onClose={(_e, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        handleCloseInternal();
      }}
    >
      <DialogTitle id="form-dialog-title">Add Payment Screenshot</DialogTitle>
      <DialogContent>
        <TapTapForm
          formikHookRef={formik}
          formFields={taptapCustomerPaymentFields}
        />

        <DialogActions
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          {/* <Button
            color="secondary"
            variant="contained"
            onClick={onDeleteCustomerPayment}
          >
            Delete
          </Button> */}

          {/* <LinkComponent
            openInNewTab
            href={`/app/transaction/${formik?.values?.internal_transaction_no}`}
            title={<Button variant="outlined">Go to Transactions</Button>}
          /> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              title="Upload Screenshots"
              loading={loading}
              onClick={() => formik.handleSubmit()}
              color="primary"
              disabled={loading}
            />
            <Button style={{ marginLeft: 10 }} onClick={onCancelBtnPress}>
              Close
            </Button>
          </div>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default TapTapCustomerPaymentDetailsDialog;

const formSchema = yup.object().shape({
  payment_type: yup.string().required('Payment Method is required'),
  amount: yup.number().required('Amount is required')
});
