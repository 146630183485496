import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectPromotionalState = (state: RootState) => state.promotional;

const selectIsLoading = createSelector(selectPromotionalState, (state) => {
  return state?.isLoading || false;
});

const selectInitialPromoDetails = createSelector(
  selectPromotionalState,
  (state) => {
    return state?.initialPromoDetails || {};
  }
);

const selectInitialPromoContent = createSelector(
  selectPromotionalState,
  (state) => {
    return state?.initialPromoDetails?.details || '';
  }
);

const selectInitialPromoProducts = createSelector(
  selectPromotionalState,
  (state) => {
    return state?.initialPromoProducts || {};
  }
);

const selectPromoProductParams = createSelector(
  selectPromotionalState,
  (state) => {
    return state?.promoProductParams || {};
  }
);

const selectRemovedProductIds = createSelector(
  selectPromotionalState,
  (state) => {
    return state?.removedProductIds || {};
  }
);

const selectHasCurrentProduct = createSelector(
  selectPromotionalState,
  (state) => {
    return state?.hasCurrentProduct;
  }
);

export const selectors = {
  selectIsLoading,
  selectInitialPromoDetails,
  selectInitialPromoContent,
  selectInitialPromoProducts,
  selectPromoProductParams,
  selectRemovedProductIds,
  selectHasCurrentProduct
};
