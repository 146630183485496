import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Page } from 'src/components';
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useDecayingStock } from 'src/hooks/decaying-stock';
import { Alert, Pagination } from '@material-ui/lab';
import { DecayingStockToolBar } from './components/ToolBar';
import { DecayingStockTableList } from './components/DecayingStockTableList';
import { downloadCSV } from 'src/utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DecayingStockData } from 'src/redux/slices/decaying-stock';
import useUserInfo from 'src/hooks/user/use-user-info';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const DecayingStocksView = () => {
  const classes = useStyles();
  const { userBranchOptions } = useUserInfo();
  const {
    decayingProductList,
    pageMeta,
    params,
    isLoading,
    getDecayingStockProducts,
    setParams,
    getStocksForCSV
  } = useDecayingStock();
  const [page, setPage] = useState<number>(1);
  const [filterAction, setFilterAction] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [isCategorryErr, setIsCategorryErr] = useState<boolean>(false);
  const [decayingCSV, setDecayingCSV] = useState<DecayingStockData[]>([]);

  const defaultValueBranch = useMemo(() => {
    let branchIds: number[] = [];

    branchIds = userBranchOptions.map((branch) => branch?.value);
    return branchIds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBranchOptions]);

  const onExportToCSVDecayingStocks = async () => {
    let i = pageMeta?.last_page ? pageMeta.last_page - 1 : 0; // last page reference
    let j = 1; // counter
    let updatedCSV = [...decayingCSV];

    for (; i >= j; j++) {
      const res = await getStocksForCSV({ ...params, page: j + 1 });
      res ? (updatedCSV = [...updatedCSV, ...res]) : null;
    }

    const decayingProdCsv = updatedCSV.map((item) => {
      return {
        Category: item?.category_name,
        Serial: item?.serial_no,
        Product_Name: item?.product_name,
        Status: item?.status,
        Retail_Price: item?.retail_price,
        Dealer_Price: item?.dealer_price,
        Purchased_Date: item?.purchased_date,
        DMC: item?.depreciation_months_count,
        MDV: item?.monthly_depreciation_value,
        SDV: item?.suggested_depreciated_value
      };
    });
    const csvName = 'DecayingStocks_PC_Worth.csv';

    downloadCSV(decayingProdCsv, csvName);
  };

  const onChangePage = (pageNum: number) => {
    setPage(pageNum);
    setParams({ ...params, page: pageNum });
    getDecayingStockProducts({ ...params, page: pageNum });
  };

  const onHandleSearchFilterBy = useCallback(() => {
    if (filterAction) {
      switch (filterAction) {
        case 'serial':
          setParams((prev) => ({
            ...prev,
            serial_no: searchKeyword || undefined,
            product_name: undefined
          }));
          break;
        case 'product':
          setParams((prev) => ({
            ...prev,
            product_name: searchKeyword || undefined,
            serial_no: undefined
          }));
          break;
      }
    }
  }, [filterAction, searchKeyword, setParams]);

  const onHandleCloseErrors = () => {
    setIsCategorryErr(false);
  };

  const onPressApplyfilter = useCallback(() => {
    if (!params.category_id) {
      setIsCategorryErr(true);
      return;
    }
    setParams({ ...params, page: 1 });
    getDecayingStockProducts({ ...params, page: 1 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDecayingStockProducts, params]);

  useEffect(() => {
    onHandleSearchFilterBy();
  }, [onHandleSearchFilterBy]);

  useEffect(() => {
    setParams({ ...params, branch_ids: defaultValueBranch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.page == 1) {
      setDecayingCSV(decayingProductList);
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decayingProductList]);

  return (
    <Page title="Decaying Stocks" className={classes.root}>
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Decaying Stock
        </Typography>

        <DecayingStockToolBar
          isLoading={isLoading}
          defaultBranches={defaultValueBranch}
          isCategorySelect={isCategorryErr}
          onApplyFilter={onPressApplyfilter}
          onHandleFilterSearhBy={(action: string) => setFilterAction(action)}
          onHandleSearch={(keyword: string) => setSearchKeyword(keyword)}
          onHandleCategories={(category_id: number) =>
            setParams((prev) => ({
              ...prev,
              category_id
            }))
          }
          onHandleSortDirection={(sortDir: 'asc' | 'desc') =>
            setParams((prev) => ({ ...prev, sort_direction: sortDir }))
          }
          onHandleBranch={(branch_ids?: number[]) =>
            setParams((prev) => ({ ...prev, branch_ids }))
          }
          onHandleErrorClose={() => onHandleCloseErrors()}
        />
        {isLoading && <LinearProgress />}

        {params && decayingProductList.length <= 0 && (
          <Alert
            severity="info"
            style={{ marginTop: '1rem' }}
          >{`Select Category Filter And Hit Apply Filter To Load Decaying Stock Products`}</Alert>
        )}

        {decayingProductList.length > 0 && (
          <Box style={{ marginTop: '1rem' }}>
            <Box style={{ marginBottom: '1rem' }}>
              <Alert severity="info">
                <Typography variant="subtitle2">
                  DMC - Depreciation Months Count
                </Typography>
                <Typography variant="subtitle2">
                  MDV - Monthly Depreciation Value
                </Typography>
                <Typography variant="subtitle2">
                  SDV - Suggested Depreciated Value
                </Typography>
              </Alert>
            </Box>
            <Paper style={{ marginTop: '1rem' }}>
              <Box
                padding={'1rem'}
                justifyContent={'flex-start'}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={onExportToCSVDecayingStocks}
                >
                  Export CSV
                </Button>
              </Box>
            </Paper>

            <DecayingStockTableList
              decayingStockProducts={decayingProductList}
            />
          </Box>
        )}

        {decayingProductList.length > 0 && (
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Pagination
              style={{ marginTop: '1rem' }}
              count={pageMeta?.last_page || 1}
              page={page}
              onChange={(e, p) => onChangePage(p)}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default DecayingStocksView;
