import { InventoryColumnForArrTable } from 'src/types';

export type Product = {
  id: number;
  name: string;
  category_id: number;
  dealers_price: number;
  retail_price: number;
  manufacturer_id: number;
  warranty_duration: number;
};

export type Category = {
  id: number;
  name: string;
};

export type Manufacturer = {
  id: number;
  name: string;
};

export type DatabaseListing = {
  id: number | null;
  serial_no: string;
  status: string;
  part_no: string | null;
  product_id: number;
  category_id: number;
  supplier_id: number | null;
  purchase_date: null;
  sku: string | null;
  warranty_duration: number;
  retail_price: number;
  dealers_price: number;
};

export type Inventory = {
  product: Product;
  category: Category;
  manufacturer: Manufacturer;
  listings: string[];
  no_sn_quantity: number; // if more than 0 generate timestamp
};

export const categories: Category[] = [
  {
    id: 1,
    name: 'WiFi Dongle'
  },
  {
    id: 2,
    name: 'Mouse'
  },
  {
    id: 3,
    name: 'Keyboard'
  },
  {
    id: 4,
    name: 'Keyboard and Mouse'
  },
  {
    id: 5,
    name: 'Mouse Pad'
  },
  {
    id: 6,
    name: 'VGA Cable'
  },
  {
    id: 7,
    name: 'Enclosure'
  },
  {
    id: 8,
    name: 'SATA Cable'
  },
  {
    id: 9,
    name: 'Thermal Paste'
  },
  {
    id: 10,
    name: 'Patch Cord / LAN Cable'
  },
  {
    id: 11,
    name: 'UPS'
  },
  {
    id: 12,
    name: 'HDMI Cable'
  },
  {
    id: 13,
    name: 'RAM'
  },
  {
    id: 14,
    name: 'SSD'
  },
  {
    id: 15,
    name: 'HDD'
  },
  {
    id: 16,
    name: 'CPU'
  },
  {
    id: 17,
    name: 'Motherboard'
  },
  {
    id: 18,
    name: 'PSU'
  },
  {
    id: 19,
    name: 'Fan'
  },
  {
    id: 20,
    name: 'Casing'
  },
  {
    id: 21,
    name: 'GPU'
  },
  {
    id: 22,
    name: 'Liquid Cooling'
  },
  {
    id: 23,
    name: 'Cable Wire'
  },
  {
    id: 24,
    name: 'LED Strips'
  }
];

export const manufacturers: Manufacturer[] = [
  {
    id: 1,
    name: 'Prolink'
  },
  {
    id: 2,
    name: 'Comfast'
  },
  {
    id: 3,
    name: 'Cooler Master'
  },
  {
    id: 4,
    name: 'Generic'
  },
  {
    id: 5,
    name: 'Galax'
  },
  {
    id: 6,
    name: 'Kingston'
  },
  {
    id: 7,
    name: 'Adata'
  },
  {
    id: 8,
    name: 'Ramsta'
  },
  {
    id: 9,
    name: 'Crucial'
  },
  {
    id: 10,
    name: 'Western Digital'
  },
  {
    id: 11,
    name: 'Gigabyte'
  },
  {
    id: 12,
    name: 'Seagate'
  },
  {
    id: 13,
    name: 'AMD'
  },
  {
    id: 14,
    name: 'Intel'
  },
  {
    id: 15,
    name: 'ASRock'
  },
  {
    id: 16,
    name: 'MSI'
  },
  {
    id: 17,
    name: 'Asus'
  },
  {
    id: 18,
    name: 'Corsair'
  },
  {
    id: 19,
    name: 'Seasonic'
  },
  {
    id: 20,
    name: 'Inplay'
  },
  {
    id: 21,
    name: 'PC Cooler'
  },
  {
    id: 22,
    name: 'AeroCool'
  },
  {
    id: 23,
    name: 'Lian Li'
  },
  {
    id: 24,
    name: 'Xigmatek'
  },
  {
    id: 25,
    name: 'NZXT'
  },
  {
    id: 26,
    name: 'Deep Cool'
  },
  {
    id: 27,
    name: 'Colorful'
  },
  {
    id: 28,
    name: 'Palit'
  },
  {
    id: 29,
    name: 'Zotac'
  }
];

export const inventory: Inventory[] = [
  {
    product: {
      id: 1,
      name: 'PROLINK WIRELESS USB ADAPTER',
      dealers_price: 650,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[0].id,
      manufacturer_id: manufacturers[0].id
    },
    category: categories[0],
    listings: [],
    no_sn_quantity: 1,
    manufacturer: manufacturers[0]
  },
  {
    product: {
      id: 2,
      name: 'COMFAST USB WIFI DONGLE',
      dealers_price: 380,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[0].id,
      manufacturer_id: manufacturers[1].id
    },
    category: categories[0],
    listings: [],
    no_sn_quantity: 10,
    manufacturer: manufacturers[1]
  },
  {
    product: {
      id: 3,
      name: 'COOLER MASTER OPTICAL MOUSE RGB MATTE BLACK',
      dealers_price: 2100,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[1].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[1],
    listings: [],
    no_sn_quantity: 2,
    manufacturer: manufacturers[2]
  },
  {
    product: {
      id: 4,
      name: 'COOLER MASTER KEYBOARD',
      dealers_price: 3200,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[2].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[2],
    listings: [],
    no_sn_quantity: 2,
    manufacturer: manufacturers[2]
  },
  {
    product: {
      id: 5,
      name: 'PROLINK WIRELESS KEYBOARD AND MOUSE',
      dealers_price: 650,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[3].id,
      manufacturer_id: manufacturers[0].id
    },
    category: categories[3],
    listings: [],
    no_sn_quantity: 9,
    manufacturer: manufacturers[0]
  },
  {
    product: {
      id: 6,
      name: 'Mouse Pad (Generic)',
      dealers_price: 15,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[4].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[4],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 15
  },
  {
    product: {
      id: 7,
      name: 'COOLER MASTER MOUSE PAD',
      dealers_price: 0,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[4].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[4],
    manufacturer: manufacturers[2],
    listings: [],
    no_sn_quantity: 2
  },
  {
    product: {
      id: 8,
      name: 'VGA Cable (Generic)',
      dealers_price: 0,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[5].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[5],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 9
  },
  {
    product: {
      id: 9,
      name: 'Enclosure (Generic)',
      dealers_price: 155,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[6].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[6],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 1
  },
  {
    product: {
      id: 10,
      name: 'SATA Cable (Generic)',
      dealers_price: 17,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[7].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[7],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 39
  },
  {
    product: {
      id: 11,
      name: 'GALAX THERMAL GREASE',
      dealers_price: 0,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[8].id,
      manufacturer_id: manufacturers[4].id
    },
    category: categories[8],
    manufacturer: manufacturers[4],
    listings: [],
    no_sn_quantity: 2
  },
  {
    product: {
      id: 12,
      name: 'PROLINK CAT6 PATCH CORD 3M CABLE',
      dealers_price: 98,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[9].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[9],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 2
  },
  {
    product: {
      id: 13,
      name: 'PROLINK CAT6 PATCH CORD 5M CABLE',
      dealers_price: 154,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[9].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[9],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 2
  },
  {
    product: {
      id: 14,
      name: 'PROLINK PRO 1250 SFC 1250VA UPS',
      dealers_price: 3500,
      retail_price: 0,
      warranty_duration: 186,
      category_id: categories[10].id,
      manufacturer_id: manufacturers[0].id
    },
    category: categories[10],
    manufacturer: manufacturers[0],
    listings: [
      '557501212500305',
      '557501212500302',
      '557501212500304',
      '557501212500303'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 15,
      name: 'HDMI Cable (GENERIC)',
      dealers_price: 40,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[11].id,
      manufacturer_id: manufacturers[3].id
    },
    category: categories[11],
    manufacturer: manufacturers[3],
    listings: [],
    no_sn_quantity: 17
  },
  {
    product: {
      id: 16,
      name: 'HYPER X RGB 16GB 3200MHZ',
      dealers_price: 4630,
      retail_price: 0,
      warranty_duration: 186,
      category_id: categories[12].id,
      manufacturer_id: manufacturers[5].id
    },
    category: categories[12],
    manufacturer: manufacturers[5],
    listings: [],
    no_sn_quantity: 1
  },
  {
    product: {
      id: 17,
      name: 'KINGSTON FURY 8GB 3200MHZ',
      dealers_price: 1910,
      retail_price: 0,
      warranty_duration: 186,
      category_id: categories[12].id,
      manufacturer_id: manufacturers[5].id
    },
    category: categories[12],
    manufacturer: manufacturers[5],
    listings: [
      'K000346',
      'K000334',
      'K000690',
      'K000402',
      'K000823',
      'K000317',
      'K000833',
      'K000551',
      'K000554',
      'K000555',
      'K000582',
      'K000581',
      'K000575',
      'K000574',
      'K000493',
      'K000586',
      'K000487',
      'K000481',
      'K000482'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 18,
      name: 'KINGSTON FURY 8GB 3600MHZ RGB',
      dealers_price: 2170,
      retail_price: 0,
      warranty_duration: 186,
      category_id: categories[12].id,
      manufacturer_id: manufacturers[5].id
    },
    category: categories[12],
    manufacturer: manufacturers[5],
    listings: ['K000202', 'K000216', 'K000021'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 19,
      name: 'KINGSTON FURY 2X8GB 3200MHZ RGB',
      dealers_price: 4200,
      retail_price: 0,
      warranty_duration: 186,
      category_id: categories[12].id,
      manufacturer_id: manufacturers[5].id
    },
    category: categories[12],
    manufacturer: manufacturers[5],
    listings: [],
    no_sn_quantity: 11
  },
  {
    product: {
      id: 20,
      name: 'ADATA 120GB',
      dealers_price: 950,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[6].id
    },
    category: categories[13],
    manufacturer: manufacturers[6],
    listings: ['7K3324KGQYR'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 21,
      name: 'RAMSTA M.2 128GB',
      dealers_price: 900,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[7].id
    },
    category: categories[13],
    manufacturer: manufacturers[7],
    listings: ['RLR133001SA00478'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 22,
      name: 'ADATA XPG 128GB NVME',
      dealers_price: 1350,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[6].id
    },
    category: categories[13],
    manufacturer: manufacturers[6],
    listings: ['2L222LAC1DUR'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 23,
      name: 'CRUCIAL 1TB NVME',
      dealers_price: 4490,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[8].id
    },
    category: categories[13],
    manufacturer: manufacturers[8],
    listings: ['2114E593A4FE', '2114E593A448', '2114E593C3D9'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 24,
      name: 'WD BLUE 250GB NVME',
      dealers_price: 2050,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[9].id
    },
    category: categories[13],
    manufacturer: manufacturers[9],
    listings: [
      '212488466704',
      '21258J440109',
      '21258J461107',
      '21258J466814',
      '21258K488605'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 25,
      name: 'GIGABYTE 256GB NVME',
      dealers_price: 1725,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[13],
    manufacturer: manufacturers[10],
    listings: ['SN212908907991'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 26,
      name: 'CRUCIAL 500GB NVME',
      dealers_price: 2520,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[8].id
    },
    category: categories[13],
    manufacturer: manufacturers[8],
    listings: [
      '2114E593BDF0',
      '2114E59392B9',
      '2113E592C6AF',
      '2114E593BBA7',
      '2114E593BE21',
      '2113E592C6B7',
      '2114E593BE60',
      '2113E592C6A7'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 27,
      name: 'CRUCIAL 250GB NVME',
      dealers_price: 1600,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[13].id,
      manufacturer_id: manufacturers[8].id
    },
    category: categories[13],
    manufacturer: manufacturers[8],
    listings: [
      '2136E5CECF26',
      '2136E5CEC39F',
      '2136E5CEC4F7',
      '2136E5CEC383',
      '2136E5CEC38D',
      '2136E5CEC391',
      '2136E5CECF3F',
      '2136E5CECF1F',
      '2136E5CEC397',
      '2136E5CEC37D',
      '2136E5CEC53A',
      '2136E5CECDEA',
      '2136E5CECDD7',
      '2136E5CEC392',
      '2136E5CECF4C',
      '2136E5CECF43',
      '2136E5CECF3E',
      '2136E5CECF45',
      '2136E5CECF3D',
      '2136E5CEC27A'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 28,
      name: 'SEAGATE BARRACUDA 3.5 1TB',
      dealers_price: 1970,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[14].id,
      manufacturer_id: manufacturers[11].id
    },
    category: categories[14],
    manufacturer: manufacturers[11],
    listings: [
      'ZN1RRRLQ',
      'ZN1RRVQ3',
      'ZN1RFKPL',
      'ZN1RFKK2',
      'ZN1RRX2F',
      'ZN1RRWW2',
      'ZN1RFKPP',
      'ZN1RRX1H',
      'ZN1RF91G',
      'ZN1RRWJA',
      'ZN1RRXVE',
      'ZN1RRWP7',
      'ZN1RRWZ4',
      'ZN1RS82Y',
      'ZN1RFKGW',
      'ZN1RRW1F',
      'ZN1RF9HN',
      'ZN1RRWK1',
      'ZN1RF8X2',
      'ZN1RRWTE',
      'ZN1RRVK2'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 29,
      name: 'ATHLON 3000G',
      dealers_price: 5000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[12].id
    },
    category: categories[15],
    manufacturer: manufacturers[12],
    listings: ['Y935107Q10508', 'Y935107Q10143'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 30,
      name: 'INTEL I3 10105',
      dealers_price: 6450,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[13].id
    },
    category: categories[15],
    manufacturer: manufacturers[13],
    listings: ['U1487AQ104519'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 31,
      name: 'INTEL I3 10105F',
      dealers_price: 5500,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[13].id
    },
    category: categories[15],
    manufacturer: manufacturers[13],
    listings: ['UIDT561705277'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 32,
      name: 'A8 7680',
      dealers_price: 2250,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[12].id
    },
    category: categories[15],
    manufacturer: manufacturers[12],
    listings: [
      '9HK9735X80114',
      '9HK9735X80130',
      '9HK9735X80016',
      '9HK9735X80125',
      '9HK9735X80129'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 33,
      name: 'RYZEN 3 3300X',
      dealers_price: 7000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[12].id
    },
    category: categories[15],
    manufacturer: manufacturers[12],
    listings: ['9KE8821R10177', '9KE8821R10422'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 34,
      name: 'RYZEN 5 5600X',
      dealers_price: 15000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[12].id
    },
    category: categories[15],
    manufacturer: manufacturers[12],
    listings: [
      '9JL9576Q10267',
      '9JM3447Q10552',
      '9JM3447Q10583',
      '9JN8204Q10234',
      '9JN8218Q10254',
      '9JL0756Q10277',
      '9JL7950Q10162',
      '9JN8218Q10161',
      '9JN8218Q10166',
      '9JN8218Q10275'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 35,
      name: 'RYZEN 5 5600G',
      dealers_price: 15000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[12].id
    },
    category: categories[15],
    manufacturer: manufacturers[12],
    listings: [
      '9JP0505T10198',
      '9JP0505T10106',
      '9JP0505T10110',
      '9J05152T10256',
      '9J07205T10223',
      '9J07205T10525',
      '9JP4587T10024'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 36,
      name: 'RYZEN 7 5800X',
      dealers_price: 23000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[12].id
    },
    category: categories[15],
    manufacturer: manufacturers[12],
    listings: ['9JK6397010100', '9JK6397010102'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 37,
      name: 'INTEL I5 11400',
      dealers_price: 9150,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[15].id,
      manufacturer_id: manufacturers[13].id
    },
    category: categories[15],
    manufacturer: manufacturers[13],
    listings: ['03218', '03461', '90000', '5552'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 38,
      name: 'ASROCK A520M/AC',
      dealers_price: 3870,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: ['D9M0XB315601', 'D9M0XB315604'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 39,
      name: 'MSI MAG A520M VECTOR WIFI',
      dealers_price: 4300,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[16],
    manufacturer: manufacturers[15],
    listings: ['6017D14070B2012000949'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 40,
      name: 'GIGABYTE B450M D5SH',
      dealers_price: 3700,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[16],
    manufacturer: manufacturers[10],
    listings: ['SN21313A004134', 'SN21313A004135', 'SN21313A004142'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 41,
      name: 'ASUS ROG STRIX B560 A GAMING WIFI',
      dealers_price: 8970,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[16].id
    },
    category: categories[16],
    manufacturer: manufacturers[16],
    listings: ['M7M0CS05R6223VN'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 42,
      name: 'MSI H510M A PRO',
      dealers_price: 3750,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[16],
    manufacturer: manufacturers[15],
    listings: ['240B2107017792', '240B2107016703'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 43,
      name: 'GIGABYTE B560M AORUS ELITE',
      dealers_price: 6650,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[16],
    manufacturer: manufacturers[10],
    listings: ['SN21314A006929'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 44,
      name: 'ASUS ROG STRIX B560 A GAMING WIFI',
      dealers_price: 8970,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[16].id
    },
    category: categories[16],
    manufacturer: manufacturers[16],
    listings: ['M7M0CS05R616X8G'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 45,
      name: 'ASROCK B450M STEEL LEGEND',
      dealers_price: 4450,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: ['E4M0XB137115', 'E4M0XB136645'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 46,
      name: 'COLORFUL A320M K PRO',
      dealers_price: 2250,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: [
      'MRC3VK900392',
      'MRC3VK900391',
      'MRC3VK900393',
      'MRC3VK900394',
      'MRC3VK900395'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 47,
      name: 'GIGABYTE B560M AORUS PRO AX',
      dealers_price: 7650,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[16],
    manufacturer: manufacturers[10],
    listings: ['SN210550102930', 'SN210550102931'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 48,
      name: 'GIGABYTE X570 AORUS ELITE WIFI',
      dealers_price: 10250,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[16],
    manufacturer: manufacturers[10],
    listings: ['SN210300030790', 'SN210400058357', 'SN210400058361'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 49,
      name: 'ASROCK B550 PRO 4',
      dealers_price: 5200,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: ['E1M0XB455550'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 50,
      name: 'ASROCK B450M STEEL LEGEND',
      dealers_price: 4450,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: [
      'E4M0XB137110',
      'E4M0XB137109',
      'E4M0XB137208',
      'E4M0XB137209',
      'E4M0XB136736',
      'E4M0XB136737',
      'E4M0XB137022'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 51,
      name: 'ASROCK B550M STEEL LEGEND',
      dealers_price: 6850,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: ['E6M0XB225313'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 52,
      name: 'GIGABYTE B550M DS3H AC',
      dealers_price: 5400,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[16],
    manufacturer: manufacturers[10],
    listings: [
      'SN21349A005718',
      'SN21349A005719',
      'SN21349A005720',
      'SN21349A005721'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 53,
      name: 'MSI MPG Z490 GAMING PLUS',
      dealers_price: 7900,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[16],
    manufacturer: manufacturers[15],
    listings: ['6017C75030B2008003972'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 54,
      name: 'MSI MPG Z490 GAMING EDGE WIFI',
      dealers_price: 9900,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[16],
    manufacturer: manufacturers[15],
    listings: ['6017C79010B2012010831', '6017C79010B2012010832'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 55,
      name: 'ASROCK B550M PRO4',
      dealers_price: 5250,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: ['E4M0XBB7602', 'E4M0XB137601', 'E4M0XB137604'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 56,
      name: 'MSI MAG Z490 TOMAHAWK',
      dealers_price: 9100,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[16].id,
      manufacturer_id: manufacturers[14].id
    },
    category: categories[16],
    manufacturer: manufacturers[14],
    listings: ['6017C80020B2007004125'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 57,
      name: 'COOLER MASTER SFX 750W GOLD',
      dealers_price: 6400,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[17],
    manufacturer: manufacturers[2],
    listings: ['SNMPY7501SFHAGVUS1211100097'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 58,
      name: 'COOLER MASTER 550W 80+ BRONZE',
      dealers_price: 2600,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[17],
    manufacturer: manufacturers[2],
    listings: ['MPE5501ACAABU51210100162'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 59,
      name: 'CORSAIR CV750 80+ BRONZE',
      dealers_price: 3490,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[17].id
    },
    category: categories[17],
    manufacturer: manufacturers[17],
    listings: ['7641211009806', '7642211009806'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 60,
      name: 'COOLER MASTER 550W 80+ BRONZE',
      dealers_price: 2600,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[17],
    manufacturer: manufacturers[2],
    listings: ['MPE5501ACAABUS1210100143', 'MPE5501ACAABUS1210100144'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 61,
      name: 'GIGABYTE P550 80+ BRONZE',
      dealers_price: 2395,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[17],
    manufacturer: manufacturers[10],
    listings: ['SN21113G030123'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 62,
      name: 'GIGABYTE P650B 80+ BRONZE',
      dealers_price: 2625,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[17],
    manufacturer: manufacturers[10],
    listings: [
      'SN21133G026686',
      'SN21133G026689',
      'SN21133G026690',
      'SN21133G026696',
      'SN21133G026697',
      'SN21133G026698',
      'SN21133G026699',
      'SN21133G026700'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 63,
      name: 'GIGABYTE P550B 80+ BRONZE',
      dealers_price: 0,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[17],
    manufacturer: manufacturers[10],
    listings: ['SN21113G030704'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 64,
      name: 'SEASONIC 650W 80+ BRONZE',
      dealers_price: 3000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[18].id
    },
    category: categories[17],
    manufacturer: manufacturers[18],
    listings: [
      'R2009RA189130421',
      'R2009RA189130422',
      'R2009RA189130423',
      'R2010RA189760836',
      'R2010RA189760837',
      'R2010RA189760838',
      'R2010RA189760839',
      'R2010RA189760840'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 65,
      name: 'SEASONIC 550W 80+ BRONZE',
      dealers_price: 2750,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[18].id
    },
    category: categories[17],
    manufacturer: manufacturers[18],
    listings: [
      'R2009RA189170511',
      'R2009RA189170512',
      'R2009RA189170513',
      'R2009RA169170506',
      'R2009RA189170507',
      'R2009RA189170508',
      'R2009RA189170509',
      'R2009RA189170510'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 66,
      name: 'SEASONIC FOCUS 750W 80+ GOLD',
      dealers_price: 5850,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[18].id
    },
    category: categories[17],
    manufacturer: manufacturers[18],
    listings: [
      'R2101AA2A6360021',
      'R2101AA2A6360022',
      'R2101AA2A6360023',
      'R2101AA2A6360024',
      'R2101AA2A6360025'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 67,
      name: 'SEASONIC 500W 80+ BRONZE',
      dealers_price: 2550,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[18].id
    },
    category: categories[17],
    manufacturer: manufacturers[18],
    listings: [
      'R2009RA189300516',
      'R2009RA189300517',
      'R2009RA189300518',
      'R2009RA189300519',
      'R2009RA189300520'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 68,
      name: 'SEASONIC FOCUS 850W 80+ GOLD',
      dealers_price: 6900,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[18].id
    },
    category: categories[17],
    manufacturer: manufacturers[18],
    listings: [
      'R2104AA234560066',
      'R2104AA234560067',
      'R2104AA234560068',
      'R2104AA234560069',
      'R2104AA234560070'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 69,
      name: 'MSI MAG A550BN 550W 80+ BRONZE',
      dealers_price: 0,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[17].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[17],
    manufacturer: manufacturers[15],
    listings: ['3067ZP2A12CEO10128000049'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 70,
      name: 'INPLAY BLUE LED FAN',
      dealers_price: 65,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[19].id
    },
    category: categories[18],
    manufacturer: manufacturers[19],
    listings: [],
    no_sn_quantity: 1
  },
  {
    product: {
      id: 71,
      name: 'INPLAY ICE ONE',
      dealers_price: 114,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[19].id
    },
    category: categories[18],
    manufacturer: manufacturers[19],
    listings: [],
    no_sn_quantity: 31
  },
  {
    product: {
      id: 72,
      name: 'INPLAY ICE TOWER V2 3 IN 1 FAN KIT',
      dealers_price: 525,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[19].id
    },
    category: categories[18],
    manufacturer: manufacturers[19],
    listings: [],
    no_sn_quantity: 19
  },
  {
    product: {
      id: 73,
      name: 'PC COOLER HALO 3 IN 1 FRGB CASE FAN KIT',
      dealers_price: 950,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[20].id
    },
    category: categories[18],
    manufacturer: manufacturers[20],
    listings: [],
    no_sn_quantity: 5
  },
  {
    product: {
      id: 74,
      name: 'AEROCOOL MIRAGE 5 ARGB',
      dealers_price: 1600,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[21].id
    },
    category: categories[18],
    manufacturer: manufacturers[21],
    listings: [],
    no_sn_quantity: 3
  },
  {
    product: {
      id: 76,
      name: 'COOLER MASTER SICKLEFLOW 120 ARGB 3 IN 1 WHT',
      dealers_price: 2100,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[18],
    manufacturer: manufacturers[2],
    listings: ['MFXB2DW183PAR11212500165', 'MFXB2DW183PAR11212500168'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 77,
      name: 'COOLER MASTER SICKLEFLOW 120 ARGB 3 IN 1',
      dealers_price: 2050,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[18],
    manufacturer: manufacturers[2],
    listings: ['MFXB20N183PAR11211604326', 'MFXB2DN183PAR11211604323'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 78,
      name: 'COOLER MASTER SICKLEFLOW 120 ARGB WHT',
      dealers_price: 600,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[18],
    manufacturer: manufacturers[2],
    listings: [
      'MFXB2DW18PAR11212700031',
      'MFXB2DW18NPAR11212700038',
      'MFXB20W18NPAR11212700032',
      'MFXB2DW18NPAR11212700044',
      'MFXB2DW18NPAR11212700053'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 79,
      name: 'COOLER MASTER SICKLEFLOW 120 ARGB',
      dealers_price: 550,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[18],
    manufacturer: manufacturers[2],
    listings: [
      'MFXB2DN18NPCR11211608956',
      'MFXB2DN18NPCR11211608944',
      'MFXB2DN18NPCR11211608942',
      'MFXB2DN18NPCR11211608955',
      'MFXB2DN18NPCR11211608960'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 80,
      name: 'LIAN LI 120MM UNI FAN WHITE SET OF 3 FANS',
      dealers_price: 3200,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[22].id
    },
    category: categories[18],
    manufacturer: manufacturers[22],
    listings: [],
    no_sn_quantity: 2
  },
  {
    product: {
      id: 81,
      name: 'LIAN LI 120MM UNI FAN WHITE',
      dealers_price: 850,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[18].id,
      manufacturer_id: manufacturers[22].id
    },
    category: categories[18],
    manufacturer: manufacturers[22],
    listings: [],
    no_sn_quantity: 6
  },
  {
    product: {
      id: 82,
      name: 'INPLAY METEOR 05 BLACK',
      dealers_price: 940,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[19].id
    },
    category: categories[19],
    manufacturer: manufacturers[19],
    listings: [],
    no_sn_quantity: 1
  },
  {
    product: {
      id: 83,
      name: 'INPLAY LITE X3 W/ 200W PSU',
      dealers_price: 740,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[19].id
    },
    category: categories[19],
    manufacturer: manufacturers[19],
    listings: [],
    no_sn_quantity: 9
  },
  {
    product: {
      id: 84,
      name: 'LIAN LI DYNAMIC MINI WHITE',
      dealers_price: 5780,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[22].id
    },
    category: categories[19],
    manufacturer: manufacturers[22],
    listings: [],
    no_sn_quantity: 1
  },
  {
    product: {
      id: 85,
      name: 'XIGMATEK GAMING X ARTIC WHITE ATX',
      dealers_price: 2850,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[23].id
    },
    category: categories[19],
    manufacturer: manufacturers[23],
    listings: [
      'XEN4673721020220447',
      'XEN4673721020220137',
      'XEN46737210202220148',
      'XEN467372102022146',
      'XEN4673721020220454',
      'XEN4673721020220152',
      'XEN4673721020220444',
      'XEN4673721020220138',
      'XEN4673721020220475',
      'XEN4673721020220136'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 86,
      name: 'MSI MAG VAMPIRIC 300R',
      dealers_price: 4000,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[19],
    manufacturer: manufacturers[15],
    listings: [
      '3067G19R2180910125000074',
      '3067G19R2180910125000073',
      '3067G19R2180910125000077'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 87,
      name: 'NZXT H510 ELITE WHITE',
      dealers_price: 7350,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[24].id
    },
    category: categories[19],
    manufacturer: manufacturers[24],
    listings: ['01216C03200461'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 88,
      name: 'PC COOLER GAME 5 GRAY ATX GAMING CASE',
      dealers_price: 1444,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[20].id
    },
    category: categories[19],
    manufacturer: manufacturers[20],
    listings: [],
    no_sn_quantity: 6
  },
  {
    product: {
      id: 89,
      name: 'PC COOLER GAME 5 WHITE ATX GAMING CASE',
      dealers_price: 1444,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[20].id
    },
    category: categories[19],
    manufacturer: manufacturers[20],
    listings: [],
    no_sn_quantity: 4
  },
  {
    product: {
      id: 90,
      name: 'DEEPCOOL ATX MATREXX55 MESH',
      dealers_price: 2100,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[25].id
    },
    category: categories[19],
    manufacturer: manufacturers[25],
    listings: [
      'DC2020073000235',
      'DC2020073000239',
      'DC2020072000720',
      'DC2020072000742'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 91,
      name: 'DEEPCOOL MATX MATREXX40 3FS',
      dealers_price: 2000,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[25].id
    },
    category: categories[19],
    manufacturer: manufacturers[25],
    listings: [
      '281250006EAK212900666',
      '281250006EAK212800131',
      '281250006EAK212900661',
      '281250006EAK212800139',
      '281250006EAK212800295'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 94,
      name: 'DEEPCOOL MATX MATREXX+N2940 3FS',
      dealers_price: 2000,
      retail_price: 0,
      warranty_duration: 0,
      category_id: categories[19].id,
      manufacturer_id: manufacturers[25].id
    },
    category: categories[19],
    manufacturer: manufacturers[25],
    listings: [
      '281250006EAK212800306',
      '281250006EAK212800297',
      '281250006EAK212800316',
      '281250006EAK212800345',
      '281250006EAK212900372'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 95,
      name: 'MSI RTX 3060TI GAMING X LHR',
      dealers_price: 36500,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[20],
    manufacturer: manufacturers[15],
    listings: ['602V397179SB2107007835'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 96,
      name: 'MSI RTX 3060 GAMING X',
      dealers_price: 29900,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[20],
    manufacturer: manufacturers[15],
    listings: [
      '602V397123SB2106003451',
      '602V397123SB106003452',
      '602V397123SB2106003459',
      '602V397123SB2106003458',
      '602V397123SB2106003460'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 97,
      name: 'MSI RTX 3070TI VENTUS 3X OC',
      dealers_price: 45500,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[20],
    manufacturer: manufacturers[15],
    listings: ['602V50504SB2106002566'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 98,
      name: 'COLORFUL IGAME RTX 3070 ULTRA OC LHR',
      dealers_price: 37000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[26].id
    },
    category: categories[20],
    manufacturer: manufacturers[26],
    listings: ['02005002PMY0053', '02005002PMY003U', '02005002PMY003V'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 99,
      name: 'COLORFUL IGAME RTX 3060 ULTRA OC LHR',
      dealers_price: 28500,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[26].id
    },
    category: categories[20],
    manufacturer: manufacturers[26],
    listings: ['GGJ6KL701950'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 100,
      name: 'PALIT RTX 3060TI LHR',
      dealers_price: 33200,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[27].id
    },
    category: categories[20],
    manufacturer: manufacturers[27],
    listings: ['B9210026007'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 101,
      name: 'GIGABYTE RTX 3080 GAMING OC LHR',
      dealers_price: 58800,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[20],
    manufacturer: manufacturers[10],
    listings: ['SN213541060897'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 102,
      name: 'ZOTAC GTX 1050 MINI 2GB',
      dealers_price: 6750,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[20].id,
      manufacturer_id: manufacturers[28].id
    },
    category: categories[20],
    manufacturer: manufacturers[28],
    listings: [
      'N212100068960',
      'N212100068959',
      'N212100068958',
      'N212100068957',
      'N212100068956',
      'N212100068955',
      'N212100068954',
      'N212100068953',
      'N212100068952',
      'N212100068951',
      'N212100068939',
      'N212100068938',
      'N212100068949',
      'N212100068940',
      'N212100068937',
      'N212100068936',
      'N212100068933',
      'N212100068931',
      'N212100068935',
      'N212100068934'
    ],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 103,
      name: 'LIQUID COOLING	AORUS WATERFORCE X240',
      dealers_price: 8850,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[21].id,
      manufacturer_id: manufacturers[10].id
    },
    category: categories[21],
    manufacturer: manufacturers[10],
    listings: ['SN212135002319'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 104,
      name: 'AEROCOOL MIRAGE L240 WHITE',
      dealers_price: 3400,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[21].id,
      manufacturer_id: manufacturers[21].id
    },
    category: categories[21],
    manufacturer: manufacturers[21],
    listings: ['JP21030301076'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 105,
      name: 'COOLER MASTER ML240L ARGB V2',
      dealers_price: 4000,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[21].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[21],
    manufacturer: manufacturers[2],
    listings: ['MLWD24MA18PAR21212701019', 'MLWD24MA18PAR21212701020'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 106,
      name: 'COOLER MASTER ML240L ARGB WHT',
      dealers_price: 4100,
      retail_price: 0,
      warranty_duration: 365,
      category_id: categories[21].id,
      manufacturer_id: manufacturers[2].id
    },
    category: categories[21],
    manufacturer: manufacturers[2],
    listings: ['MLWD24MA18PWRW1212600158', 'MLWD24MA18PWRW1212600157'],
    no_sn_quantity: 0
  },
  {
    product: {
      id: 107,
      name: 'LIAN LI STRIMER',
      dealers_price: 1700,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[22].id,
      manufacturer_id: manufacturers[22].id
    },
    category: categories[22],
    manufacturer: manufacturers[22],
    listings: [],
    no_sn_quantity: 1
  },
  {
    product: {
      id: 108,
      name: 'MSI PHANTEK',
      dealers_price: 1700,
      retail_price: 0,
      warranty_duration: 7,
      category_id: categories[23].id,
      manufacturer_id: manufacturers[15].id
    },
    category: categories[23],
    manufacturer: manufacturers[15],
    listings: [],
    no_sn_quantity: 9
  }
];

export const inventoryColumnsLocal: InventoryColumnForArrTable[] = [
  {
    name: 'ID',
    value: 'id',
    hideFilter: false
  },
  {
    name: 'Qty',
    value: 'quantity',
    hideFilter: false
  },
  {
    name: 'Product Name',
    value: 'product_name',
    hideFilter: false
  },
  {
    name: 'SKU',
    value: 'sku',
    hideFilter: false
  },
  {
    name: 'DR No.',
    value: 'dr_no',
    hideFilter: false
  },
  {
    name: 'Serial No',
    value: 'serial_no',
    hideFilter: false
  },
  {
    name: 'Status',
    value: 'status',
    hideFilter: false
  },
  {
    name: 'Dealers Price',
    value: 'dealers_price',
    hideFilter: true
  },
  {
    name: 'Retail Price',
    value: 'retail_price',
    hideFilter: true
  },
  {
    name: 'Manufacturer Name',
    value: 'manufacturer_id',
    hideFilter: false
  },
  {
    name: 'Supplier Name',
    value: 'supplier_name',
    hideFilter: false
  },
  {
    name: 'Branch Name',
    value: 'branch_name',
    hideFilter: false
  },
  {
    name: 'Encoded By',
    value: 'encoded_by',
    hideFilter: false
  },
  {
    name: 'Released By',
    value: 'released_by',
    hideFilter: false
  },
  {
    name: 'Transaction No',
    value: 'transaction_no',
    hideFilter: false
  },
  {
    name: 'Customer Name',
    value: 'customer_name',
    hideFilter: false
  },
  {
    name: 'Date Sold (Customer)',
    value: 'date_sold',
    hideFilter: false
  },
  {
    name: 'Date Purchased (Supplier)',
    value: 'date_purchased',
    hideFilter: false
  },
  {
    name: 'Date Encoded',
    value: 'date_encoded',
    hideFilter: false
  }
];
