import React, { FC, memo, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  PDFViewer,
  Document,
  StyleSheet,
  Image,
  Text,
  Page,
  View
} from '@react-pdf/renderer';
import { TransactionWithBal } from 'src/types';
import { slices, useAppSelector } from 'src/redux';
import { RenderPDFCurrency } from 'src/components/RenderPDFCurrency';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  textHeader: {
    padding: 3,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold'
  },
  logo: {
    height: 'auto',
    width: 150
  },
  parentView: {
    flexDirection: 'column',
    padding: 5,
    margin: 5,
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 5,
    marginBottom: 15
  },
  tableRow: { flexDirection: 'row' },
  tableCell: { padding: 5, margin: 'auto' },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '11.11%'
  },
  textBold: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    overflow: 'hidden'
  },
  titleAndDate: {
    marginTop: 5,
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  renderPageNum: {
    marginTop: 'auto',
    flexDirection: 'row',
    width: '100%',
    border: 1,
    padding: 5,
    marginBottom: 15
  }
});

interface Props {
  isOpen?: boolean;
  handleClose?: () => void;
}

interface HeaderRowProps {
  value: string;
}

interface DataRowProps {
  value?: TransactionWithBal;
}

const { selectors: transactionsSelectors } = slices.transaction;

const component: FC<Props> = ({ isOpen = false, handleClose }) => {
  const pageSize = 21; // Optimal number of items to display per page for a balanced layout

  const transactionsWithBal: TransactionWithBal[] =
    useAppSelector(transactionsSelectors.selectTransactionsWithBal) || [];

  const ColsHeader = [
    'Transaction No.',
    'Branch',
    'Total Amount',
    'Amount Paid',
    'Balance Remaining'
  ];

  const date = useMemo(() => (isOpen ? new Date() : null), [isOpen]);

  const transactionsPerPage = useMemo(() => {
    if (!transactionsWithBal?.length) {
      return [];
    }

    let dataPerPage: TransactionWithBal[] = [];
    let allData: TransactionWithBal[][] = [];
    const listingsLength = transactionsWithBal.length;

    transactionsWithBal.forEach((transaction, i) => {
      dataPerPage.push(transaction);

      if (dataPerPage.length === pageSize || i === listingsLength - 1) {
        allData.push(dataPerPage);
        dataPerPage = [];
      }
    });

    return allData;
  }, [transactionsWithBal]);

  const HeaderRow: FC<HeaderRowProps> = ({ value }) => {
    const header = useMemo(() => {
      const commonStyles = [styles.tableCol];
      const columnWidths: { [key: string]: string } = {
        'Transaction No.': '25%',
        Branch: '25%',
        'Total Amount': '16.6%',
        'Amount Paid': '16.6%',
        'Balance Remaining': '16.78%'
      };

      return {
        styles: columnWidths[value]
          ? [...commonStyles, { width: columnWidths[value] }]
          : commonStyles,
        text: value
      };
    }, [value]);

    return (
      <View style={header.styles}>
        <Text style={[styles.tableCell, styles.textBold]}>{header.text}</Text>
      </View>
    );
  };

  const responsiveStyles = useCallback((val: string) => {
    switch (val) {
      case 'transaction_no':
        return [styles.tableCol, { width: '25%' }];
      case 'branch':
        return [styles.tableCol, { width: '25%' }];
      case 'total_amount':
        return [styles.tableCol, { width: '16.6%' }];
      case 'total_payment':
        return [styles.tableCol, { width: '16.6%' }];
      case 'balance':
        return [styles.tableCol, { width: '16.78%' }];
      default:
        return [styles.tableCol];
    }
  }, []);

  const DataRow: FC<DataRowProps> = useCallback(
    ({ value }) => {
      // specific arrangement for ui display
      const arrangedEntries = {
        transaction_no: value?.transaction_no,
        branch: value?.branch,
        total_amount: value?.total_amount,
        total_payment: value?.total_payment,
        balance: value?.balance
      };

      const rowData = Object.entries(arrangedEntries || {});

      // any intended as ts error bandaid
      const renderData = (val: any[]) => {
        const [key, value] = val;
        //RenderCurrency
        switch (key) {
          case 'branch':
            return value.join(', ');
          case 'total_amount':
          case 'total_payment':
          case 'balance':
            return <RenderPDFCurrency amount={value || 0} />;
          default:
            return value;
        }
      };

      return rowData[0] ? (
        <View style={styles.tableRow}>
          {/* Any value intended */}
          {rowData.map((i: any[]) => (
            <View key={i[0]} style={responsiveStyles(i[0])}>
              <Text style={[styles.tableCell, styles.textBold]}>
                {renderData(i)}
              </Text>
            </View>
          ))}
        </View>
      ) : null;
    },
    [responsiveStyles]
  );

  return (
    <Dialog maxWidth={'md'} fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h4">Transactions with Balance</Typography>
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          color="secondary"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PDFViewer
          width="100%"
          height="100%"
          style={{ height: '80vh', width: '100%' }}
        >
          <Document title={'Transactions With Balance'}>
            {transactionsPerPage[0]
              ? transactionsPerPage.map((transactions, ind) => (
                  <Page key={ind} size={[842, 595]} style={styles.page}>
                    <View style={styles.parentView}>
                      <View style={styles.header}>
                        <Image
                          style={styles.logo}
                          src="/static/new_drj_logo.png"
                        />
                      </View>
                      <View style={styles.titleAndDate}>
                        <Text style={styles.textHeader}>
                          Transactions with Balance
                        </Text>
                        <Text
                          style={styles.textHeader}
                        >{`${date?.toLocaleDateString()} ${date?.toLocaleTimeString(
                          [],
                          {
                            hour: '2-digit',
                            minute: '2-digit'
                          }
                        )}`}</Text>
                      </View>
                      <View style={styles.table} wrap>
                        <View style={styles.tableRow}>
                          {ColsHeader.map((i, ind) => (
                            <HeaderRow key={ind} value={i} />
                          ))}
                        </View>
                        {transactions.map((i) => (
                          <View key={i.transaction_no} style={styles.tableRow}>
                            <DataRow value={i} />
                          </View>
                        ))}
                      </View>
                      <View style={styles.renderPageNum} break>
                        <Text style={styles.textBold}>{`Page ${ind + 1} of ${
                          transactionsPerPage.length
                        } `}</Text>
                      </View>
                    </View>
                  </Page>
                ))
              : null}
          </Document>
        </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export const PrintTransactionsWithBalanceModal = memo(component);
