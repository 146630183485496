import React, { useMemo, useState } from 'react';
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useUserInfo from 'src/hooks/user/use-user-info';
import { useStockTransfer } from 'src/hooks/stock-transfer';
import { TransferStockDialog } from './TransferStockDialog';
import { ErrorDialog } from 'src/components/error/ErrorDialog';

const useStyle = makeStyles(() => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

interface Props {
  originId?: number;
  receivingId?: number;
  isTransferTo?: boolean;
  isTransferBackTo?: boolean;
  onHandleTransferToReceivingBranch: () => void;
  onHandleTransferToOriginBranch: () => void;
  onHandleOpenNonSnDialog: () => void;
  onRemoveItem?: () => void;
}

const Component = ({
  originId,
  receivingId,
  isTransferTo,
  onHandleTransferToReceivingBranch,
  onHandleTransferToOriginBranch,
  onHandleOpenNonSnDialog
}: Props) => {
  const classes = useStyle();
  const { userBranchOptions } = useUserInfo();
  const {
    isTransferStockLoading,
    selectedBranch,
    transferStockErrors,
    setTransferStockErrors
  } = useStockTransfer();
  const [isTranserTo, setIsTranserTo] = useState<boolean>(false);
  const [isTranserBack, setIsTranserBack] = useState<boolean>(false);

  const originBranchName = useMemo(() => {
    const branchName = userBranchOptions.find(
      (item) => item.value === originId
    );
    return branchName?.name.toUpperCase();
  }, [originId, userBranchOptions]);

  const receivingBranchName = useMemo(() => {
    const branchName = userBranchOptions.find(
      (item) => item.value === receivingId
    );
    return branchName?.name.toUpperCase();
  }, [receivingId, userBranchOptions]);

  return (
    <>
      <Box className={classes.flexCenter} sx={{ flexDirection: 'column' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '15px' }}
          fullWidth
          endIcon={<ChevronRightIcon />}
          onClick={onHandleOpenNonSnDialog}
          disabled={!selectedBranch?.branch_id}
        >
          {'Transfer non sn'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '15px' }}
          fullWidth
          endIcon={<ChevronRightIcon />}
          disabled={!isTransferTo}
          onClick={() => setIsTranserTo(!isTranserTo)}
        >
          {isTransferStockLoading ? <CircularProgress /> : ` Transfer To`}
        </Button>
        {/* TODO: this button is currently depracated due to backend restrictions on product status */}
        {/* <Button
          variant="outlined"
          color="primary"
          style={{ marginTop: '15px' }}
          fullWidth
          startIcon={<ChevronLeftIcon />}
          disabled={!isTransferBackTo}
          onClick={() => setIsTranserBack(!isTranserBack)}
        >
          {isTransferStockLoading ? <CircularProgress /> : ` Transfer Back To`}
        </Button> */}
      </Box>

      <TransferStockDialog
        isOpen={isTranserTo}
        okayButtonName={'Confirm and Transfer'}
        title={'Transfer Stock Item'}
        originBranchName={originBranchName}
        receivingBranchName={receivingBranchName}
        onHandleConfirmAction={onHandleTransferToReceivingBranch}
        onHandleClose={() => setIsTranserTo(false)}
      />
      <TransferStockDialog
        isOpen={isTranserBack}
        okayButtonName={'Confirm and Transfer'}
        title={'Transfer Stock Item'}
        originBranchName={receivingBranchName} //intended as we switch branch
        receivingBranchName={originBranchName} //intended as we switch branch
        onHandleConfirmAction={onHandleTransferToOriginBranch}
        onHandleClose={() => setIsTranserBack(false)}
      />

      <ErrorDialog
        open={transferStockErrors.isError}
        errs={transferStockErrors?.errorMsg || []}
        closeDialog={() => setTransferStockErrors(false, [])}
      />
    </>
  );
};

export const TransferButtonControls = React.memo(Component);
