import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';

import { TextBoldPlayFair, TextBoldWarranty } from './TextWarranty';
import { ViewWarrantyRow } from './ViewWarranty';
import { colors } from 'src/constants';

interface Props {
  orderNo?: string;
  transactionNo?: string;
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  qrContainer: {
    alignItems: 'center'
  },
  numbersContainer: {
    marginLeft: 2
  },
  image: {
    height: 30,
    width: 30
  },
  transactionNoText: {
    letterSpacing: 0.5,
    color: colors.red.A700,
    fontSize: 8
  },
  orderNoText: {
    letterSpacing: 0.5,
    color: '#ff0000',
    fontSize: 8
  },
  o: {
    marginTop: -1
  },
  orderNum: {
    marginLeft: 4,
    fontSize: 8,
    marginTop: -1
  },
  slipTxt: {
    fontSize: 10,
    letterSpacing: 1
  },
  emptyQr: {
    height: 30
  }
});

const component = ({ transactionNo = '', orderNo = '' }: Props) => {
  const [uriQR, setUriQR] = useState<string>('');

  const getTransactionNoUrl = () => {
    QRCode.toDataURL(transactionNo, { errorCorrectionLevel: 'H' })
      .then((url) => setUriQR(url))
      .catch((err) => console.error(err));
  };

  useEffect(getTransactionNoUrl, []);

  return (
    <ViewWarrantyRow style={styles.root}>
      <View>
        <TextBoldWarranty style={styles.slipTxt}>
          WARRANTY SLIP
        </TextBoldWarranty>
      </View>
      <ViewWarrantyRow style={styles.qrContainer}>
        {uriQR ? <Image style={styles.image} src={uriQR} /> : <View style={styles.emptyQr} />}
        <View style={styles.numbersContainer}>
          {orderNo ? (
            <ViewWarrantyRow>
              <TextBoldPlayFair style={styles.orderNoText}>
                {`N`}
              </TextBoldPlayFair>
              <TextBoldPlayFair style={[styles.orderNoText, styles.o]}>
                {'ọ'}
              </TextBoldPlayFair>
              <TextBoldPlayFair style={[styles.orderNoText, styles.orderNum]}>
                {`${orderNo}`}
              </TextBoldPlayFair>
            </ViewWarrantyRow>
          ) : null}
          <TextBoldPlayFair style={styles.transactionNoText}>
            {transactionNo}
          </TextBoldPlayFair>
        </View>
      </ViewWarrantyRow>
    </ViewWarrantyRow>
  );
};

export const RenderWarrantySlipTitle = React.memo(component);
